import { Button, Card, Chip, Dialog } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./JobExtraDetails.css";
import TrackingDialogContent from "./JobTrackingDialogContent";

function JobExtraDetails() {

  // hooks
  const { currentJob } = useParams();

  // state variables
  const [state, setState] = React.useState({
    comments: '',
    urgency: 'Medium', 
    open: false,
  });

  // useEffect to fetch data
  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(db, "job_requests", currentJob);
      const fetchDoc = await getDoc(docRef);
      const { tradesman_comments, job_urgency } = fetchDoc.data();

      if (fetchDoc.exists) {
        setState((state) => ({
          ...state, 
          comments: tradesman_comments ? tradesman_comments : '',
          urgency: job_urgency ? job_urgency : '',
        }))
      } else {
        return;
      }
    }

    handleFetch();
  }, [currentJob]);



  function handleJobUrgency () {

    if (state.urgency.toLocaleLowerCase() === 'high') {
      return('error')

    } else if (state.urgency.toLocaleLowerCase() === 'medium') {
      return('warning')
    } else {

      return('success')
    }

  }


  function DialogWindow () {
    return(
      <Dialog fullWidth maxWidth='sm' open={state.open} onClose={((e) => {setState((state) => ({...state, open: false,}))})}>
        <TrackingDialogContent />
      </Dialog>
    )
  }



  return (
    <Card className="JobExtraDetails">
      <DialogWindow />
      <div className="title">Details</div>

      <div className="urgency">
        <Chip label={state.urgency} color={handleJobUrgency()} />
      </div>

      <div className="message">
      <div dangerouslySetInnerHTML={{ __html: state.comments }} />

        <div className="messageTitle">
          Tradesman Comments
        </div>
        <Button onClick={((e) => {setState((state) => ({...state, open: true}))})} className="button" style={{ fontWeight: "bold", color: "rgb(18, 100, 100)" }}>
          Track
        </Button>
      </div>
    </Card>
  );
}

export default JobExtraDetails;
