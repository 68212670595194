import { Alert, Button, Card, CircularProgress, Snackbar, TextField } from "@mui/material";
import React from "react";
import tenacklogo from "../Images/tenacklogo.png";
import { useNavigate } from "react-router-dom";
import "./SignUp.css"
import { registerWithEmailAndPassword } from "../../firebase";

function SignUp() {

  // hooks 
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    errorOpen: false,
    errorMessage: "",
    successOpen: false,
    successMessage: "",
    email: "",
    password: "",
    posistion: false,
    ConfirmPassword: '', 
    firstName: '', 
    lastName: '',
  });

  // handle button running away 
  function handleRunAway() {
    if (state.password === "" || state.email === "") {
      setState((state) => ({
        ...state,
        posistion: state.posistion === true ? false : true,
      }));
    } else {
      return;
    }
  }


  // handle creating user using firebase Auth

  async function register () {
    if (state.password !== state.ConfirmPassword) {
      setState((state) => ({
        ...state, 
        errorOpen: true, 
        errorMessage: `Oops... Looks like your passwords don't match!`
      })) 

    } else { 
      await setState((state) => ({
        ...state, 
        loading: true,
      }))
      await registerWithEmailAndPassword(`${state.firstName} ${state.lastName}`, state.email, state.password).then((res) => {
        if (res.success) {
          setState((state) => ({
            ...state, 
            loading: false, 
            successOpen: true, 
            successMessage: res.message
          }))
          console.log(res.user)

          setTimeout(() => {
            navigate('/dashboard')
          }, 1000);
        } else {
          setState((state) => ({
            ...state, 
            loading: false, 
            errorOpen: true, 
            errorMessage: res.message
          }))
        }
      })
    }
  }


    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successOpen: false,
        successMessage: "",
        errorMessage: "",
        errorOpen: false,
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal] = React.useState("right");








  return (
    <div className="SignUp">
      <Card>
        <>
       {state.loading && <div className="loading">
          <CircularProgress style={{color:'rgb(18, 100, 100)'}}/> 
        </div>}

        <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>



          <div className="logo">
            <img src={tenacklogo} alt="Tenack Logo" className="image" />
          </div>
          <div className="title">Create Investor Account.</div>

          <div className="fields">
            <div className="field">
              <TextField
                value={state.email}
                label="Email Address"
                placeholder="support@tenack.com"
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    email: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>
            <div className="field">
              <TextField
                value={state.password}
                label="Password"
                type="password"
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    password: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>
            <div className="field">
              <TextField
                value={state.ConfirmPassword}
                label="Confirm Password"
                type="password"
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    ConfirmPassword: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>

            <div className="fields-halves"> 
            <div className="half"> 
            <TextField
                value={state.firstName}
                label="First Name"
                placeholder="John"
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    firstName: e.target.value,
                  }));
                }}
                fullWidth
              />

            </div>
            <div className="half"> 
            <TextField
                value={state.lastName}
                placeholder='Smith'
                label="Last Name"
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    lastName: e.target.value,
                  }));
                }}
                fullWidth
              />

            </div>

            </div>






            <div className="buttons">
              <Button
                className={state.posistion ? "button-left" : "button-right"}
                onMouseEnter={(e) => {
                  handleRunAway();
                }}
                onClick={((e) => {
                  register()
                })}
                style={{
                  color: "white",
                  backgroundColor: "rgb(18, 100, 100)",
                  fontWeight: "bold",
                }}
              >
                Join
              </Button>
            </div>
            <div className="links">
              <div className="link-section">
                <div>Already Have An Account? </div>
                <div
                  className="link"
                  onClick={(e) => {
                    navigate("/login");
                  }}
                >
                Log In
                </div>
              </div>
           
            </div>
          </div>
        </>
      </Card>
    </div>
  );
}

export default SignUp;
