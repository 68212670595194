import { Add, MeetingRoom } from "@mui/icons-material";
import {
  Card,
  Dialog,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {collection, getDocs, query } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import SpaceAddDialogContent from "./SpaceAddDialogContent";
import "./Spaces.css";

function Spaces() {
  // hooks
  const { currentProperty } = useParams();
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    spaces: [],
    open: false,
  });

  React.useEffect(() => {
    async function handleSpaceFetch() {
      const q = query(collection(db, "properties", currentProperty, "spaces"));
      const querySnapshot = await getDocs(q);

      setState((state) => ({
        ...state,
        spaces: querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })),
      }));
    }

    handleSpaceFetch();
  }, [currentProperty]);


  


  function AddSpaceDialog() {
    return (
      <Dialog open={state.open} fullWidth maxWidth="xs" onClose={((e) => {setState((state) => ({...state, open: false}))})}>
       <SpaceAddDialogContent />
      </Dialog>
    );
  }

  return (
    <Card className="Spaces">
                
      <AddSpaceDialog />
      <div className="title">Spaces</div>
      <div className="list">
        {state.spaces.map((space) => (
          <ListItem
            key={space.id}
            button
            onClick={(e) => {
              navigate(`/${currentProperty}/spaces/${space.id}`);
            }}
          >
            <ListItemIcon style={{ color: "rgb(18, 100, 100)" }}>
              <MeetingRoom />
            </ListItemIcon>
            <ListItemText primary={space.display_name} />
          </ListItem>
        ))}

        <Fab
          onClick={(e) => {
            console.log('open')
            setState((state) => ({ ...state, open: !state.open }));
          }}
          className={"fab"}
          style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
          size={"small"}
        >
          <Add />
        </Fab>
      </div>
    </Card>
  );
}

export default Spaces;
