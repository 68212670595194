import { Card, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import './AssetDocumentsCard.css'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../../firebase'
import { useParams } from 'react-router-dom'
import { PictureAsPdf } from '@mui/icons-material'

function AssetDocumentsCard() {

    // hooks 
    const {currentProperty,
        currentAsset} = useParams();

    // state variables
    const [state, setState] = React.useState({
        files: [], 
        index: 0,
    }) 

    // useEffect Hook 
    React.useEffect(() => {
        async function handleFetch() {
          const q = query(
            collection(
              db,
              "properties",
              currentProperty,
              "assets",
              currentAsset,
              "files_pdfs"
            ),
            where("visible", "!=", false)
          );
    
          const querySnapshot = await getDocs(q);

          console.log(querySnapshot.docs)
    
    
    
          setState((state) => ({
            ...state,
            files: querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id})),
          }));
        }
    
        handleFetch();
      }, [currentProperty, currentAsset]);


  return (
    <Card className='AssetDocumentsCard'>
        <div className='title'>Documents</div>

        <div className='list'>

            {state.files.map((file) => (

                <ListItem key={file.id} button onClick={((e) => {window.open(file.url)})}> 
                    <ListItemIcon>
                        <PictureAsPdf style={{color:'rgb(18, 100, 100)'}}/>
                    </ListItemIcon>
                    <ListItemText primary={file.name}/>
                </ListItem>
            ))}


    

            


        </div>



    </Card>
  )
}

export default AssetDocumentsCard