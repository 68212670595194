import { AddAPhoto } from '@mui/icons-material'
import { Alert, CircularProgress, IconButton, Snackbar, TextField } from '@mui/material'
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React from 'react'
import { useParams } from 'react-router-dom';
import { db, storage } from '../../../firebase';
import { useAuth } from '../../Contexts/AuthContext';
import './EditAccess.css'

function EditAccess() {

      // hooks
  const auth = useAuth();
  const { currentProperty } = useParams();

    // state variables
    const [state, setState] = React.useState({
        property_notes: '',
        display_image: '', 
        loading: false, 
        errorOpen: false, 
        errorMessage: '',
        successMessage: '', 
        successOpen: false,  
    })


      React.useEffect(() => {
    console.log(currentProperty);
    async function handleFetch() {
      const docRef = doc(db, "properties", currentProperty);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setState((state) => ({
          ...state,
          display_image: docSnap.data().display_image
            ? docSnap.data().display_image
            : "",
          property_notes: docSnap.data().property_notes
            ? docSnap.data().property_notes
            : "",
        }));

        console.log(docSnap.data());
      } else {
        console.log("no file exists");
      }
    }

    handleFetch();
  }, [auth.currentUser, currentProperty]);


  // handle onBlur update 

  async function onBlur() {
    await setState((state) => ({
      ...state,
      loading: true,
    }));

    // execute API call
    const docRef = doc(db, "properties", currentProperty); // define property investor doc
    await updateDoc(docRef, {
        property_notes: state.property_notes,
    })
      .then((res) => {
        // handle success
        setState((state) => ({
          ...state,
          errorOpen: false,
          errorMessage: "",
          loading: false,
          successMessage: "Successfully saved address details...",
          successOpen: true,
        }));
      })
      .catch((err) => {
        // handle Error
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to save address details...",
          loading: false,
          successMessage: "",
          successOpen: false,
        }));
      });
  }



  // handle uploading image
  function handleInput(item) {
    // handle uploading a profile image
    if (item) {
        const e = item.target.files[0]
        console.log(e)
      // add to image folder in firebase
      const ImageRef = ref(
        storage,
        `${auth.currentUser.uid}/images/${e.name}`
      );

      const uploadTask = uploadBytesResumable(ImageRef, e);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to upload image...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        },
        async () => {
          // complete function ....

          const url = await getDownloadURL(ImageRef)
          const docRef = doc(db, "properties", currentProperty); // define property investor doc

          await updateDoc(docRef, {
         
            display_image: url,
            
          })
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successMessage: "Successfully Uploaded Image...",
              successOpen: true,
              display_image: url,
              loading: false,
            }));
        }
      );
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Please Select An Image File...",
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }


    // function handle profile photo upload 
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };



    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successOpen: false,
          successMessage: "",
          errorMessage: "",
          errorOpen: false,
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal] = React.useState("right");




  return (
    <div className='EditAccess'>
         {state.loading && (
        <div className="Loading">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
      )}

          <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

        <div className='imageSection'>
            <img className='image' src={state.display_image} alt='property access'/>
            <input
                    accept="image/*"
                    ref={hiddenFileInput}
                    id="icon-button-file"
                    type="file"
                    onChange={handleInput}
                    style={{ display: "none" }}
                  />

            <IconButton onClick={((e) => {handleClick()})} className='iconButton' style={{color:'rgb(18, 100, 100)', backgroundColor:'white'}}>
                <AddAPhoto />
            </IconButton> 
        </div>

        <div className='fullwidth'>
            <TextField 
            fullWidth
            label='Access Notes'
            multiline
            minRows={2}
            maxRows={10}
            value={state.property_notes}
            onChange={((e) => {
                setState((state) => ({
                    ...state, 
                    property_notes: e.target.value
                }))
            })}
            onBlur={((e) => {
                onBlur();
            })}

            
            /> 

        </div>

    </div>
  )
}

export default EditAccess