import { AddAPhoto, Close } from '@mui/icons-material'
import { Avatar, CircularProgress, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React from 'react'
import { auth, db, storage } from '../../../firebase';
import './Styles/Photos.css'

function Photos() {



  // state variables
  const [state, setState] = React.useState({
    files: []
  })




    // ability to add image to array

    // function handle profile photo upload 
    const hiddenFileInput = React.useRef(null);
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };


  // handle upload image
  function handleInput(item) {
    // handle uploading a profile image
    if (item) {
        const e = item.target.files[0]
        console.log(e)
      // add to image folder in firebase
      const ImageRef = ref(
        storage,
        `${auth.currentUser.uid}/files/${e.name}`
      );

      const uploadTask = uploadBytesResumable(ImageRef, e);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to upload image...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        },
        async () => {
          // complete function ....
          console.log(e)

          const url = await getDownloadURL(ImageRef)
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successMessage: "Successfully Uploaded File...",
              successOpen: true,
             files:[...state.files, {url, name: e.name, type: e.type}],
              loading: false,
            }));
            handleSave([...state.files, {url, name: e.name, type: e.type}])
        }
      );
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Please Select An Image File...",
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }

  // ability to remove image from array

  function handleImageRemove (item) {

    // find index 

    const arr = [...state.files]; 
    const ind = arr.findIndex((arrItem) => arrItem.url === item.url); 
    console.log(ind); 
    arr.splice(ind, 1); 
    setState((state) => ({
      ...state, 
      files: arr,
    }))

      handleSave(arr)
      

  }





  // function handleDefaultValues

  React.useEffect(() => {
    async function handleDefaultFetch () {
      const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

      const docRef = await getDoc(docPath); 

      if (docRef.exists) {
        const {images} = docRef.data(); 
        setState((state) => ({
          ...state, 
          files: images ? images : [], 
        }))

      } else {
        return;
      }

    }
    handleDefaultFetch()
  }, [])

  // handle save
  function handleSave (arr) {
    const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

    setDoc(docPath, {
      images: arr, 
    }, {merge: true}).catch((err) => {
      console.log(err)
    })

  }
  



  return (

    <div className='Photos'>

      {state.loading && <div className='loading'> <CircularProgress  style={{color:'rgb(18, 100, 100)'}}/></div>}



      <div className='title'>Upload Photos</div>

      <List className='List'>


{state.files.map((item) => (
   <ListItem key={Math.random()} button style={{ marker: "none" }}>
          <ListItemAvatar>
            <Avatar src={item.url}/>
           
          </ListItemAvatar> 
          <ListItemText primary={item.name}/>
          <ListItemSecondaryAction>
            <IconButton onClick={((e) => {handleImageRemove(item)})}>
              <Close />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
))}
       


      </List>
      <div className='uploadSection'>
      <input
               
               ref={hiddenFileInput}
               id="icon-button-file"
               type="file"
               onChange={handleInput}
               style={{ display: "none" }}
             />


        <Fab onClick={((e) => {handleClick()})} style={{color:'white', backgroundColor:'rgb(18, 100, 100)'}} size='small'>
          <AddAPhoto />
        </Fab>
      </div>
    </div>
  )
}

export default Photos