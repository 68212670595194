import React from "react";
import "./Styles/Start.css";
import arrow from "./arrow.svg";

function Start() {
  return (
    <div className="JobStart">
      <div className="title">
        Send A Maintenace Request To Your Preferred Tradie.
      </div>

      <div className="subtitle">
        Sending maintenance requests through Tenack is quicker and easier than
        ever simply answer the question in this form, and don't stress about
        losing your progress, it's saved as you go!
      </div>

      <div className="helperText">Ready To Get Started?</div>

      <img src={arrow} alt="" className="arrow" />
    </div>
  );
}

export default Start;
