import { ArrowBackIos, LocationOn, PersonAdd } from "@mui/icons-material";
import { Avatar, Card, Chip, Dialog, Fab, IconButton } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../Contexts/AuthContext";
import SelectPropertiesDialog from "./SelectPropertiesDialog";
import "./TradesmanProfilePage.css";

function TradesmanProfilePage() {

    // hooks 
    const { tradesmanId, propertyId} = useParams(); 
    const navigate = useNavigate(); 
  const {currentUser} = useAuth(); 
    // state variables
    const [state, setState] = React.useState({
        public_name: '', 
        logo: '', 
        bio: '', 
        chips: [], 
        address: '', 
        open: false,
        
    })

    // useEffect to fetch Tradesman Details 
    React.useEffect(() => {
        async function handleDocumentFetch() {
          const docRef = doc(db, "Tradesman", tradesmanId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setState((state) => ({
              ...state,
              logo: docSnap.data().logo ? docSnap.data().logo : '', 
              public_name: docSnap.data().public_name ? docSnap.data().public_name : '', 
              bio: docSnap.data().bio ? docSnap.data().bio : '',
              chips: docSnap.data().chips ? docSnap.data().chips : [], 
            }));

            if (docSnap.data().address_line_2 > 1) { // multiline
                setState((state) => ({
                    ...state, 
                    address: `
                    ${docSnap.data().address_line_1 ? docSnap.data().address_line_1 : ''},
                    ${docSnap.data().address_line_2 ? docSnap.data().address_line_2: ''}, 
                    ${docSnap.data().address_city ? docSnap.data().address_city : ''},
                    ${docSnap.data().address_state ? docSnap.data().address_state : ''}
                    
                    `
                }))

            } else { // single line
                setState((state) => ({
                    ...state, 
                    address: `
                    ${docSnap.data().address_line_1 ? docSnap.data().address_line_1 : ''},
                    ${docSnap.data().address_city ? docSnap.data().address_city : ''},
                    ${docSnap.data().address_state ? docSnap.data().address_state : ''}
                    
                    `
                }))

            }


            console.log(docSnap.data(), docSnap.id);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        }
        handleDocumentFetch();
      }, [tradesmanId]);



      // Dialog Window 

      function DialogWindow () {
        return (
            <Dialog fullWidth maxWidth='sm' open={state.open} onClose={((e) => {setState((state) => ({...state, open: !state.open}))})}> 
                <SelectPropertiesDialog />
            </Dialog>
        )
      }

   

  return (
    <div className="TradesmanProfilePage">
        <DialogWindow />
      <Card className="card">

        {propertyId &&  <div className="navigateback">
          <IconButton onClick={(() => {navigate(`/${currentUser.uid}/properties/${propertyId}`)})  }>
            <ArrowBackIos />
          </IconButton>
        </div>}

       


        <div className="topBar">
          <div className="subtitle">Tradesman Profile</div>
        </div>

        <div className="title">{state.public_name}</div>

        <Avatar src={state.logo} className="logo" sx={{ width: 100, height: 100 }} />

        <div className="address">
          <LocationOn
            style={{ color: "rgb(18, 100, 100)", marginRight: "10px" }}
          />
         {state.address}
        </div>
        <div className="bio" dangerouslySetInnerHTML={{__html: state.bio}}>
 
        </div>
        <div className="chips">
            {state.chips.map((chip, ind) => (
                <Chip 
                style={{color:"white", backgroundColor:'rgb(18, 100, 100)', margin: '2.5px', fontWeight:'bold'}}
                label={chip}
                key={ind}
                />
            ))}


        </div>
        <div className="chipsBottom"/>

<div className="fabButton">
     <Fab  style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }} onClick={((e) => {setState((state) => ({
        ...state, 
        open: true
     }))})}>
          <PersonAdd />
        </Fab>  
</div>
     
      </Card>
    </div>
  );
}

export default TradesmanProfilePage;
