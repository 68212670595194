import { Alert, Button, CircularProgress, InputAdornment, Snackbar, TextField } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./EditSpace.css";

function EditSpace() {

    // hooks
    const {currentProperty, currentSpace} = useParams();

// state variables
  const [state, setState] = React.useState({
    display_name: "",
    width: "",
    length: "",
    height: "",
    loading: false,
    errorOpen: false,
    successOpen: false,
    errorMessage: "",
    successMessage: "",
  });

  React.useEffect(() => {
    async function handleDataFetch() {
        const docRef = doc(db, "properties", currentProperty, 'spaces', currentSpace);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log(docSnap.data())
            setState((state) => ({
                ...state, 
                display_name: docSnap.data().display_name ? docSnap.data().display_name : "",
                width: docSnap.data().space_width ? docSnap.data().space_width : "",
                length: docSnap.data().space_length ? docSnap.data().space_length : "",
                height: docSnap.data().space_height ? docSnap.data().space_height : "",
            }))
        } else {
            return;
        }


    }
    handleDataFetch();
  }, [currentProperty, currentSpace]);

  async function handleSave () {
    setState((state) => ({
        ...state, 
        loading: true, 
    }))

    const docRef = doc(db, "properties", currentProperty, 'spaces', currentSpace);
   await updateDoc(docRef, {
    display_name: state.display_name,
    space_width: state.width,
    space_length: state.length,
    space_height: state.height,
   }).then((res) => {
    setState((state) => ({
        ...state, 
        loading: false, 
        errorOpen: false, 
        errorMessage: '', 
        successOpen: true, 
        successMessage: 'Successfully updated space details...'
    }))

   }).catch((err) => {
    setState((state) => ({
        ...state, 
        loading: false, 
        errorOpen: true, 
        errorMessage: 'Failed to update space details...', 
        successOpen: false, 
        successMessage: ''
    }))


   })


  }






  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="EditSpace">

        {state.loading && (
        <div className="loading">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
      )}

     
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

        <div className="title">
            Edit Space Details 
            </div>
      <div className="fullwidth">
        <TextField
          fullWidth
          label="Display Name"
          value={state.display_name}
          onChange={(e) => {
            setState((state) => ({ ...state, display_name: e.target.value }));
          }}
        />
      </div>
      <div className="thirds">
        <div className="third">
          <TextField
           InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  M                </InputAdornment>
              ),
            }}
            fullWidth
            label="Width"
            value={state.width}
            onChange={(e) => {
              setState((state) => ({ ...state, width: e.target.value }));
            }}
          />
        </div>
        <div className="third">
          <TextField
           InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  M                </InputAdornment>
              ),
            }}
            fullWidth
            label="Length"
            value={state.length}
            onChange={(e) => {
              setState((state) => ({ ...state, length: e.target.value }));
            }}
          />
        </div>
        <div className="third">
          <TextField
           InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  M                </InputAdornment>
              ),
            }}
            fullWidth
            label="Height"
            value={state.height}
            onChange={(e) => {
              setState((state) => ({ ...state, height: e.target.value }));
            }}
          />
        </div>
      </div>
      <div className="buttonSection">
        <Button
        onClick={((e) => {
            handleSave()
        })}
          style={{
            fontWeight: "bold",
            backgroundColor: "rgb(18, 100, 100)",
            color: "white",
          }}
        >
          Update
        </Button>
      </div>
    </div>
  );
}

export default EditSpace;
