import {
  Avatar,
  Card,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./TradesmanSearchResults.css";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Search } from "@mui/icons-material";
import {useAuth} from '../Contexts/AuthContext'

function TradesmanSearchResults() {
  // hooks
  const { query } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const {propertyId} = useParams();


  // state varaibles
  const [state, setState] = React.useState({
    query: "",
    results: [],
    loading: false,
    hidden: true, 
  });

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      query: query,
    }));
    handleResultsFetch(query);
  }, [query]);

  // handle fetching search results

  async function handleResultsFetch(PropQuery) {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    const functions = getFunctions();
    const fetchResults = httpsCallable(functions, "InvestorTradesmanSearch");
    fetchResults({ query: PropQuery })
      .then((result) => {
        const data = result.data;
        console.log(data)
        console.log(data.results);
        // Read result of the Cloud Function.
        console.log(data);
        setState((state) => ({
          ...state,
          loading: false,
          results: data.results,
        }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
        }));
        console.log("error");
      });
  }

  return (
    <div className="TradesmanSearchResults">
      {state.loading && (
        <div className="Loading">
          <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
        </div>
      )}

      <Card className="results">
        
        <div className="topBar">
          <div className="title">Results </div>
          <div className="searchButton">
            <IconButton style={{color:'rgb(18, 100, 100)'}} onClick={((e) => {

                if (state.hidden) {
                   setState((state) => ({
                    ...state, 
                    hidden: !state.hidden
                }))  
                } else {
                    handleResultsFetch(state.query)
                    setState((state) => ({
                        ...state, 
                        hidden: !state.hidden
                    }))  
                }
               
            })}>
                <Search/>
            </IconButton> 

          </div>
          <div className={state.hidden ? "searchBarHidden" : "searchBar"}>
          <TextField
          size="small"
            fullWidth
            onKeyPress={(e) =>
              e.key === "Enter" && handleResultsFetch(state.query)
            }
            label="Search"
            placeholder="Search..."
            value={state.query}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                query: e.target.value,
              }));
            }}
            
          />
        </div>
        </div>
        <div className="searchResults">
          {state.results.map((tradesman) => (
            <ListItem button dense key={tradesman.id} onClick={((e) => {navigate(`/${auth.currentUser.uid}/tradesman/search/result/${tradesman.objectID}${propertyId ? `/${propertyId}` : null}`)})}>
              <ListItemAvatar>
                <Avatar src={tradesman.logo} />
              </ListItemAvatar>
              <ListItemText
                primary={tradesman.public_name}
                secondary={tradesman.address_city}
              />
            </ListItem>
          ))}
        </div>
      </Card>
    </div>
  );
}

export default TradesmanSearchResults;
