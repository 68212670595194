import { Build, Edit } from "@mui/icons-material";
import { Dialog, Fab, IconButton } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../Contexts/AuthContext";
import AccessCard from "./Access/AccessCard";
import AddressEdit from "./Address/AddressEdit";
import FloorPlan from "./Floor Plan/FloorPlan";
import "./Properties.css";
import Spaces from "./Spaces/Spaces";
import TenantCard from "./Tenant/TenantCard";
import PropertyTradesman from "./Tradesman/PropertyTradesman";
import PropertyAssets from "./Whole Property Assets/PropertyAssets";

function Properties() {

// hooks 
const {currentProperty} = useParams();
const auth = useAuth();
const navigate = useNavigate(); 

// state variables
  const [state, setState] = React.useState({
    display_name: "Rochester Rental Property",
    address: '', 
    open: false,
  });


// 

React.useEffect(() => {
    console.log(currentProperty);
    async function handleFetch() {
      const docRef = doc(db, "properties", currentProperty);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {

        if (docSnap.data().address_line_2.length > 2) { // multiline address
            setState((state) => ({
                ...state,
               display_name: docSnap.data().display_name, 
               address: `${docSnap.data().address_line_1}, ${docSnap.data().address_line_2}, ${docSnap.data().address_city}, ${docSnap.data().address_state}, ${docSnap.data().address_postcode}, ${docSnap.data().address_country} `
              }));


        } else { // single line address
            setState((state) => ({
                ...state,
               display_name: docSnap.data().display_name, 
               address: `${docSnap.data().address_line_1}, ${docSnap.data().address_city}, ${docSnap.data().address_state}, ${docSnap.data().address_postcode}, ${docSnap.data().address_country} `
              }));

        }


        console.log(docSnap.data());
      } else {
        console.log("no file exists");
      }
    }

    handleFetch();
  }, [auth.currentUser, currentProperty]);


// Dialog Window
function handleClose () {
    setState((state) => ({
        ...state, 
        open: false,
    }))
}

function DialogWindow () {
    return (
        <Dialog open={state.open} onClose={handleClose} fullWidth maxWidth='sm'>
           <AddressEdit />
        </Dialog>
    )
}





  return (
    <div className="Properties">


      <div className="Buildfab">
        <Fab style={{color:'white', backgroundColor:'rgb(18, 100, 100)'}} onClick={((e) => {navigate('/newJob/new')})}>
          <Build />
        </Fab>
      </div>


      <div className="address-section">
        <div className="primary">{state.display_name}</div>
        <div className="secondary">
          {state.address}
        </div>
      </div>
      <div className="edit-button">
        <IconButton style={{ color: "rgb(18, 100, 100)" }} onClick={((e) => {setState((state) => ({...state, open: true}))})}>
          <Edit />
        </IconButton>
      </div>

      <div className="content">  
      <div className="column"> 
         <AccessCard />
      <FloorPlan />  
        </div>
     
     <div className="column">
        <PropertyTradesman />
      <Spaces />
      <TenantCard />
     </div>

     <div className="column">

  <PropertyAssets title={'Energy Assets'} space={'energy'}/>
      <PropertyAssets title={'Heating & Cooling Assets'} space={'heating_cooling'}/>
      <PropertyAssets title={'Utility Assets'} space={'utitities'}/>
     </div>
   

     
    
     
    
     

      </div>
      <DialogWindow />
    </div>
  );
}

export default Properties;
