import { ArrowBackIos, ArrowForwardIos, Check } from "@mui/icons-material";
import { Alert, Button, Card, CircularProgress, LinearProgress, Snackbar } from "@mui/material";
import React from "react";
import "./CreateNewJob.css";
import Start from "./Pages/Start";
import Description from "./Pages/Description";
import Type from "./Pages/Type";
import Photos from "./Pages/Photos";
import Urgency from "./Pages/Urgency";
import RelatedSpace from "./Pages/RelatedSpace";
import RelatedAsset from "./Pages/RelatedAsset";
import SelectTradesman from "./Pages/SelectTradesman";
import Title from "./Pages/Title";
import RelatedProperty from "./Pages/RelatedProperty";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";

function CreateNewJob() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    index: 0,
    limit: 9,
    errorOpen: false,
    errorMessage: "",
    successMessage: '', 
    successOpen: false,
    loading: false,
  });

  async function handleSend() {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    if (
      window.confirm(
        `Are you sure you're ready to submit this maintenace request?`
      )
    ) {



      try {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "holding",
        "newJob"
      );
      const docData = await getDoc(docRef);

      if (docData.exists) {
        const {
          asset,
          description,
          images,
          property,
          space,
          title,
          tradesman,
          type,
          urgency,
        } = docData.data();

        if (
          `${title.length}` > 3 &&
          `${description.length}` > 3 &&
          `${property.length}` > 5 &&
          `${type.length}` > 2 &&
          `${tradesman.length}` > 5 &&
          `${urgency.length}` > 2
        ) {
          console.log("valid");
          console.log(
            asset,
            description,
            images,
            property,
            space,
            title,
            tradesman,
            type,
            urgency
          );

          const collectionRef = collection(db, "job_requests");

          addDoc(collectionRef, {
            job_title: title,
            tradesman: tradesman,
            job_type: type,
            job_urgency: urgency,
            job_description: description,
            job_space: space ? space : "",
            job_asset: asset ? asset : "",
            job_images: images ? images : [],
            job_property: property,
            sender: auth.currentUser.uid,
            created_at: serverTimestamp(),
            investor: auth.currentUser.uid,
          })
            .then((res) => {
              // remove holding
              deleteDoc(docRef).catch((err) => {
                console.log(err);
              });

              setState((state) => ({
                ...state,
                successOpen: true, // success snackbar
                successMessage:
                  "Amazing! your request has been sent! you will be redirected shortly...",
                loading: false,
              }));
              // handle success page move
              setTimeout(() => {
                navigate("/dashboard");
              }, 1500);
            })
            .catch((err) => {
              console.log(err);
              setState((state) => ({
                ...state,
                errorMessage:
                  "Oh No... Something went wrong! please refresh and try again...",
                loading: false,
                errorOpen: true,
              }));
            });
        } else {
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Oops... Look like you forgot some details...",
            loading: false,
          }));
        }
      } else {
        return;
      }


    } catch {
      setState((state) => ({
        ...state, 
        loading: false, 
        errorOpen: true, 
        errorMessage: 'Oops... Looks like something went wrong, check your answers and try again...'
      }))
    }
    } else {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    }
  }


    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successOpen: false,
        successMessage: "",
        errorMessage: "",
        errorOpen: false,
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal] = React.useState("right");


  const progress = (100 / state.limit) * state.index;

  return (
    <div className="CreateNewJob">
       {state.loading && (
        <div className="loading">
          {" "}
          <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />{" "}
        </div>
      )}

            <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <Card className="content">
        <div style={{ color: "rgb(18, 100, 100)" }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            color="inherit"
          />
        </div>
        <div className="slide">
          {state.index === 0 && <Start />}
          {state.index === 1 && <Description />}
          {state.index === 2 && <Type />}
          {state.index === 3 && <Photos />}
          {state.index === 4 && <Urgency />}
          {state.index === 5 && <RelatedProperty />}
          {state.index === 6 && <RelatedSpace />}
          {state.index === 7 && <RelatedAsset />}
          {state.index === 8 && <SelectTradesman />}
          {state.index === 9 && <Title />}
        </div>
        <div className="buttons">
          <Button
            onClick={(e) => {
              setState((state) => ({
                ...state,
                index: state.index - 1,
              }));
            }}
            disabled={state.index === 0}
            style={
              state.index === 0
                ? {
                    color: "white",
                    backgroundColor: "lightgray",
                    fontWeight: "bold",
                  }
                : { color: "rgb(18, 100, 100)", fontWeight: "bold" }
            }
          >
            <ArrowBackIos />
            Previous
          </Button>

          <Button
            onClick={(e) => {
              if (state.index === state.limit) {
                handleSend();
              } else {
                setState((state) => ({
                  ...state,
                  index: state.index + 1,
                }));
              }
            }}
            style={{
              color: "white",
              backgroundColor: "rgb(18, 100, 100)",
              fontWeight: "bold",
            }}
          >
            {state.index === state.limit ? (
              <>
                Submit <Check />
              </>
            ) : (
              <>
                Next <ArrowForwardIos />
              </>
            )}
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default CreateNewJob;
