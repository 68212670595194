import { Edit } from "@mui/icons-material";
import { Card, Dialog, Fab } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./SpaceNotes.css";
import SpaceNotesEdit from "./SpaceNotesEdit";

function SpaceNotes() {
  // hooks
  const { currentProperty, currentSpace } = useParams();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    open: false,
    notes: "",
  });

  React.useEffect(() => {
    console.log(currentProperty);
    async function handleFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "spaces",
        currentSpace
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setState((state) => ({
          ...state,
          notes: docSnap.data().notes ? docSnap.data().notes : "",
        }));
      } else {
        console.log("no file exists");
      }
    }

    handleFetch();
  }, [currentProperty, currentSpace]);


  function DialogWindow () {
    return(
      <Dialog fullWidth maxWidth='sm' open={state.open} onClose={((e) => {setState((state) => ({...state, open: false}))})}>
        <SpaceNotesEdit />
      </Dialog>
    )

  }

  return (
    <Card className="SpaceNotes">
      <DialogWindow />
      <div className="title">Space Notes</div>
      <div className="notes">
        {state.notes}

        <Fab
        onClick={((e) => {
          setState((state) => ({
            ...state, 
            open: true, 
          }))
        })}
          size="small"
          className="fab"
          style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
        >
          <Edit />
        </Fab>
      </div>
    </Card>
  );
}

export default SpaceNotes;
