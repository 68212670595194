import { Alert, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import React from 'react'
import { auth, db } from '../../../firebase';
import './Styles/Urgency.css'

function RelatedProperty() {

  const [state, setState] = React.useState({
    properties: [], 
    property: '', 
    errorMessage: '', 
    errorOpen: '', 
  })


  React.useEffect(() => {
    async function handleFetch  () {
      const docPath = query(collection(db, 'properties'), where('property_owner', '==', `${auth.currentUser.uid}`)); 

      const docRef = await getDocs(docPath); 

      if (docRef.empty) {
        // open alert window
        setState((state) => ({
          ...state, 
          errorOpen: true, 
          errorMessage: `Oops... looks like you haven't added a property to tenack yet!`,  
        }))
      } else {

        setState((state) => ({
          ...state, 
          properties: docRef.docs.map((doc) => ({...doc.data(), id: doc.id}), )
        }))

      }

    }

    handleFetch()
  }, [])

  React.useEffect(() => {
    async function handleFetch  () {
      const docPath = doc(db, 'Property_Investor', auth.currentUser.uid, 'holding', 'newJob');
      const docRef = await getDoc(docPath); 

      if (!docRef.exists) {
        return;
      } else {
        const property = docRef.data().property; 
        setState((state) => ({
          ...state, 
          property: property ? property : '',
        }))

      }

    }

    handleFetch()
  }, [])
  



  function handleSave () {
    const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

    setDoc(docPath, {
      property: state.property, 
    }, {merge: true}).catch((err) => {
      console.log(err)
    })
  }



    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successOpen: false,
        successMessage: "",
        errorMessage: "",
        errorOpen: false,
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal] = React.useState("right");


  return (
    <div className='Type'>
       <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>


        <div className='caption'>
         What Property is this work at?
        </div>


        <div className='textField'>
        <FormControl fullWidth>
            <InputLabel>Property</InputLabel>
            <Select
            onBlur={((e) => {
              handleSave(); 
            })}
            
              value={state.property}
              label="Event Type"
               onChange={(e) => {
                setState((state) => ({
                  ...state,
                  property: e.target.value,
                }));
              }} 
            >

              {state.properties.map((property) => (
                  <MenuItem key={property.id} value={property.id}>{property.display_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        

    </div>
  )
}

export default RelatedProperty