import { ArrowForwardIos } from '@mui/icons-material'
import { Card } from '@mui/material'
import React from 'react'
import './AssetPhotosCard.css'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../../firebase'
import { useParams } from 'react-router-dom'

function AssetPhotosCard() {

    // hooks 
    const {currentProperty,
        currentAsset, currentSpace} = useParams();

    // state variables
    const [state, setState] = React.useState({
        images: [], 
        index: 0,
    }) 

    // useEffect Hook 
    React.useEffect(() => {
        async function handleFetch() {
          const q = query(
            collection(
              db,
              "properties",
              currentProperty,
              "assets",
              currentAsset,
              "files_images"
            ),
            where("visible", "!=", false)
          );
    
          const querySnapshot = await getDocs(q);

          console.log(querySnapshot.docs)
    
    
    
          setState((state) => ({
            ...state,
            images: querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id})),
          }));
        }
    
        handleFetch();
      }, [currentProperty, currentAsset]);

    // handle changing image index 
    function handleIndexChange (direction) {
        if (direction) { // left
            if (state.index === 0) { // already at the first image
                return;
            } else {
                setState((state) => ({
                    ...state, 
                    index: state.index - 1, 
                }))
            }
        } else { // right
            if (state.index === state.images.length - 1) { // already at the last image
                return;
            } else {
                setState((state) => ({
                    ...state, 
                    index: state.index + 1, 
                }))
            }

        }
    }


  return (
    <Card className='AssetPhotosCard'>
        <div className='title'>Photos</div>

        <div className='Content'>
            <div className='navBar'> 
            <ArrowForwardIos color='inherit' onClick={((e) => {handleIndexChange (true)})}/>
                </div>

                <div className='imageCentre'>
                    <img  className='image' onClick={((e) => {


                    window.open(`/${currentProperty}/spaces/${currentSpace}/assets/${currentAsset}/${ state.images[state.index].event_id}`)





                    })} src={state.images[state.index] && state.images[state.index].url} alt=''/>

                    </div>


                <div className='navBar'> 
            <ArrowForwardIos color='inherit' onClick={((e) => {handleIndexChange (false)})}/>
                </div>

        </div>



    </Card>
  )
}

export default AssetPhotosCard