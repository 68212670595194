import { Alert, Button, CircularProgress, Snackbar, TextField } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./SpaceNotesEdit.css";

function SpaceNotesEdit() {
  // hooks
  const { currentProperty, currentSpace } = useParams();

  // state variables
  const [state, setState] = React.useState({
    notes: "",
    loading: false,
    errorOpen: false,
    errorMessage: "",
    successOpen: false,
    successMessage: "",
  });

  // set default value
  React.useEffect(() => {
    console.log(currentProperty);
    async function handleFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "spaces",
        currentSpace
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setState((state) => ({
          ...state,
          notes: docSnap.data().notes ? docSnap.data().notes : "",
        }));
      } else {
        console.log("no file exists");
      }
    }

    handleFetch();
  }, [currentProperty, currentSpace]);

  // handle saving to the database
  async function handleSave() {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    const docRef = doc(
      db,
      "properties",
      currentProperty,
      "spaces",
      currentSpace
    );

    await updateDoc(docRef, {
      notes: state.notes,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          loading: false,
          errorOpen: false,
          errorMessage: "",
          successOpen: true,
          successMessage: "Successfully updated space notes...",
        }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          errorOpen: true,
          errorMessage:
            "Failed to save space notes please refresh and try again...",
          successOpen: false,
          successMessage: "",
        }));
      });
  }

    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successOpen: false,
          successMessage: "",
          errorMessage: "",
          errorOpen: false,
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal] = React.useState("right");

  return (
    <div className="SpaceNotesEdit">
        {state.loading && (
        <div className="loading">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
      )}

     
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="title">Edit Space Notes</div>

      <div className="fullwidth">
        <TextField
          fullWidth
          label="Space Notes"
          placeholder="Main Bedroom, located in the north east corner of the house..."
          value={state.notes}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              notes: e.target.value,
            }));
          }}
          multiline
          minRows={3}
          maxRows={15}
        />
      </div>
      <div className="buttonSection">
        <Button
          style={{
            color: "white",
            backgroundColor: "rgb(18, 100, 100)",
            fontWeight: "bold",
          }}
          onClick={(e) => {
            handleSave();
          }}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
}

export default SpaceNotesEdit;
