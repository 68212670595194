import React from 'react'
import AssetHistory from './Asset History/AssetHistory'
import './AssetPage.css'
import AssetDetails from './Details/AssetDetails'
import AssetDocumentsCard from './Documents/AssetDocumentsCard'
import AssetPhotosCard from './Photos/AssetPhotosCard'
import AssetQuickDetails from './Quick Details/AssetQuickDetails'

function AssetPage() {
  return (
    <div className='assetPage'>
 <AssetQuickDetails />
        <div className='content'>
         <AssetDetails />
            <AssetPhotosCard />
            <AssetHistory />
            <AssetDocumentsCard />

        </div>

    </div>
  )
}

export default AssetPage