import { AddAPhoto, ArrowForwardIos } from "@mui/icons-material";
import { Card, Dialog, IconButton } from "@mui/material";
import React from "react";
import "./SpaceImages.css";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import SpaceImagesEdit from "./SpaceImagesEdit";

function SpaceImages() {
  // hooks
  const { currentProperty, currentSpace } = useParams();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    images: [],
    index: 0,
  });

  // use Effect
  React.useEffect(() => {
    async function handleDataFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "spaces",
        currentSpace
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        setState((state) => ({
          ...state,
          images: docSnap.data().space_images
            ? docSnap.data().space_images
            : "",
        }));
      } else {
        return;
      }
    }
    handleDataFetch();
  }, [currentProperty, currentSpace]);


  function DialogWindow () {
    return (<Dialog fullWidth maxWidth='sm' open={state.open} onClose={((e) => {setState((state) => ({...state, open: false}))})}>
        <SpaceImagesEdit />
    </Dialog>)
  }

  return (
    <Card className="SpaceImages">
        <DialogWindow />
      <div className="title">Images</div>
      <div className="Content">
        <div
          className="navigation"
          onClick={(e) => {
            if (state.index !== 0) {
              setState((state) => ({ ...state, index: state.index - 1 }));
            } else {
              return;
            }
          }}
        >
          <div>
            <ArrowForwardIos
              style={{ transform: "rotate(180deg)", color: "inherit" }}
            />
          </div>
        </div>

        <img
          src={
            state.images &&
            state.images[state.index] &&
            state.images[state.index].url &&
            state.images[state.index].url
          }
          alt=""
          className="image"
        />

        <div
          className="navigation"
          onClick={(e) => {
            if (state.index !== state.images.length - 1) {
              setState((state) => ({ ...state, index: state.index + 1 }));
            } else {
              return;
            }
          }}
        >
          <div>
            <ArrowForwardIos style={{ color: "inherit" }} />
          </div>
        </div>

        <div className="addButton">
          <IconButton style={{ color: "rgb(18, 100, 100)", backgroundColor:'white' }} onClick={((e) => {setState((state) => ({...state, open: true}))})}>
            <AddAPhoto />
          </IconButton>
        </div>
      </div>
    </Card>
  );
}

export default SpaceImages;
