import { PictureAsPdf, Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Avatar, Card, Checkbox, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Snackbar } from "@mui/material";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import "./AssetHistoryAttachmentsCard.css";

function AssetHistoryAttachmentsCard() {
  // hooks
  const { currentProperty, currentAsset, historyEventId } =
    useParams();

  // state varaibles
  const [state, setState] = React.useState({
    loading: true,
    files: [],
  });

  // Fetch useEffect

  React.useEffect(() => {
    async function handleFetch() {
      const q = query(
        collection(
          db,
          "properties",
          currentProperty,
          "assets",
          currentAsset,
          "files_pdfs"
        ),
        where("event_id", "==", historyEventId)
      );

      const querySnapshot = await getDocs(q);



      const qImages = query(
        collection(
          db,
          "properties",
          currentProperty,
          "assets",
          currentAsset,
          "files_images"
        ),
        where("event_id", "==", historyEventId)
      );
      const querySnapshotImages = await getDocs(qImages);

      const newFiles = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      const newFilesImages = querySnapshotImages.docs.map((doc) => ({...doc.data(), id: doc.id}));

    

      setState((state) => ({
        ...state,
        files: newFiles.concat(newFilesImages).sort((a, b) => a.title - b.title),
        loading: false, 
      }));
    }

    handleFetch();
  }, [currentProperty, currentAsset, historyEventId, ]);





  async function updateVisibility(docId, type, field) {

    const typeTitle =  (type.includes('image') ?  'files_images' : 'files_pdfs')
    const docRef = doc(
      db,
      "properties",
      currentProperty,
      "assets",
      currentAsset,
     typeTitle,
      docId, 
    );

    const snapshot = await getDoc(docRef)
    const docRefData = snapshot.data(); 
    console.log(docRefData, typeTitle)


    const arr = [...state.files];
    const index = arr.findIndex((item) => item.id === docId)

    updateDoc(docRef, {
       [field]: typeof arr[index][field] === 'undefined' ?  true : !arr[index][field], 
    }).then((res) => {


        setState((state) => ({
            ...state, 
            loading: false, 
            errorOpen: false, 
            errorMessage: '',
            successOpen: true, 
            successMessage: 'Successfully updated expense visibility status...', 
            files: state.files.map(el => (el.id === docId ? Object.assign({...el, [field]: typeof el[field] === 'undefined' ? true : !el[field]}) : el))
        }))

    }).catch((err) => {
        setState((state) => ({
            ...state, 
            loading: false, 
            errorOpen: true, 
            errorMessage: 'Failed to update expense visibility status...',
            successOpen: false, 
            successMessage: ''
        }))

    })
  }


      /* Handle Error and Success SnackBar */
      const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successOpen: false,
          successMessage: "",
          errorMessage: "",
          errorOpen: false,
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal] = React.useState("right");



  return (
    <Card className="AssetHistoryAttachmentsCard">
                    <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <div className="title"> {`Photos & Attachments`} <div className="listTitles"><div>Warranty</div><div>Manual</div><div>Visibility</div> </div></div>


      <div className="list">
        {state.files.map((file) => (
      <ListItem button onClick={((e) => {window.open(`${file.url}`)})} key={file.id} style={{borderBottom:'solid 1px lightgray'}}>
      <ListItemAvatar>

        {file.type.includes('image') ? <Avatar src={file.url}/> : <PictureAsPdf style={{color:'rgb(18, 100, 100)', margin:'10px'}} />}


    


      </ListItemAvatar>
      <ListItemText primary={file.name} />
      <ListItemSecondaryAction>
        <div className="secondaryActions">
          <Checkbox
          checked={file.warranty}
onClick={((e) => {
  updateVisibility(file.id, file.type, 'warranty')
})}
                      style={{ marginRight: "10px" }}
            sx={{
              color: "rgb(18, 100, 100)",
              "&.Mui-checked": {
                color: "rgb(18, 100, 100)",
              },
            }}
          />
          <Checkbox
          checked={file.manual}
onClick={((e) => {
    updateVisibility(file.id, file.type, 'manual')
})}
          
            style={{ marginRight: "10px" }}
            sx={{
              color: "rgb(18, 100, 100)",
              "&.Mui-checked": {
                color: "rgb(18, 100, 100)",
              },
            }}
          />
          <IconButton
          onClick={((e) => {
            updateVisibility(file.id, file.type, 'visible')
          })}
          >
            {file.visible ? <Visibility style={{color:'rgb(18, 100, 100)'}} /> : <VisibilityOff style={{color:'rgb(18, 100, 100)'}} />}
          </IconButton>
        </div>
      </ListItemSecondaryAction>
    </ListItem> 
        ))}


      </div>
    </Card>
  );
}

export default AssetHistoryAttachmentsCard;
