import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Card, IconButton, InputAdornment, Snackbar, TextField } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import "./AssetHistoryDetailsCard.css";

function AssetHistoryDetailsCard() {
  // hooks
  const { currentProperty, currentAsset, historyEventId } = useParams();

  // state variables
  const [state, setState] = React.useState({
    loading: true,
    desc: "",
    tradesman: "",
    expense: "",
    expense_visibility: true,
    type: "",
  });

  // useEffect
  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "assets",
        currentAsset,
        "asset_events",
        historyEventId
      );

      const snapshot = await getDoc(docRef);

      const { type, desc, expense_visibility, tradesman } = snapshot.data();

      setState((state) => ({
        ...state,
        type: type ? type : "",
        desc: desc ? desc : true,
        expense_visibility: expense_visibility ? expense_visibility : "",
        tradesman: tradesman ? tradesman : false,
        loading: false,
      }));

      const expenseRef = doc(
        db,
        "properties",
        currentProperty,
        "assets",
        currentAsset,
        "asset_events",
        historyEventId,
        "expense",
        "expense_doc"
      );
      const expenseSnap = await getDoc(expenseRef);

      if (expenseSnap.exists) {
        setState((state) => ({
          ...state,
          expense: expenseSnap.data().expense,
          loading: false,
        }));
      } else {
        return;
      }
    }

    handleFetch();
  }, [currentProperty, currentAsset, historyEventId]);

  async function updateVisibility() {
    const docRef = doc(
      db,
      "properties",
      currentProperty,
      "assets",
      currentAsset,
      "asset_events",
      historyEventId
    );

    const docRefData = (await getDoc(docRef)).data(); 

    updateDoc(docRef, {
        expense_visibility: !docRefData.expense_visibility
    }).then((res) => {
        setState((state) => ({
            ...state, 
            loading: false, 
            errorOpen: false, 
            errorMessage: '',
            successOpen: true, 
            successMessage: 'Successfully updated expense visibility status...'
        }))

    }).catch((err) => {
        setState((state) => ({
            ...state, 
            loading: false, 
            errorOpen: true, 
            errorMessage: 'Failed to update expense visibility status...',
            successOpen: false, 
            successMessage: ''
        }))

    })
  }


      /* Handle Error and Success SnackBar */
      const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successOpen: false,
          successMessage: "",
          errorMessage: "",
          errorOpen: false,
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal] = React.useState("right");

      
  return (
    <Card className="AssetHistoryDetailsCard">
                    <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <div className="title">Details</div>

      <div className="fields">
        <div className="fullwidth">
          <TextField
            fullWidth
            multiline
            label="description"
            minRows={4}
            maxRows={11}
            disabled={true}
            value={state.desc}
          />
        </div>
        <div className="fullwidth">
          <TextField
            fullWidth
            label="Tradesman"
            disabled={true}
            value={state.tradesman}
          />
        </div>

        <div className="halves">
          <div className="half">
            <TextField
              fullWidth
              multiline
              label="Expense"
              disabled={true}
              value={state.expense}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => {
                        setState((state) => ({
                          ...state,

                          expense_visibility: !state.expense_visibility,
                        }));
                        updateVisibility();
                      }}
                    >
                      {state.expense_visibility ? (
                        <Visibility style={{ color: "rgb(18, 100, 100)" }} />
                      ) : (
                        <VisibilityOff style={{ color: "rgb(18, 100, 100)" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="half">
            <TextField
              fullWidth
              multiline
              label="Event Type"
              disabled={true}
              value={state.type}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default AssetHistoryDetailsCard;
