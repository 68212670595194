import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Alert, Button, CircularProgress, LinearProgress, Snackbar } from "@mui/material";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../firebase";
import "./ProcessTenantRequest.css";
import RequestDescriptionPage from "./Slides/RequestDescriptionPage";
import RequestPhotosPage from "./Slides/RequestPhotosPage";
import RequestRelatedAsset from "./Slides/RequestRelatedAsset";
import RequestRelatedSpace from "./Slides/RequestRelatedSpace";
import RequestSelectTradesman from "./Slides/RequestSelectTradesman";
import RequestStartPage from "./Slides/RequestStartPage";
import RequestTitlePage from "./Slides/RequestTitlePage";
import RequestTypePage from "./Slides/RequestTypePage";
import RequestUrgencyPage from "./Slides/RequestUrgencyPage";

function ProcessTenantRequest() {
  // hooks
  const { request_id } = useParams();
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    index: 0,
    limit: 8,
    successOpen: false,
    successMessage: "",
    errorMessage: "",
    errorOpen: false,
  });

  // fetch the updated details and save to extra state variable
  const [progressObj, setProgressObj] = React.useState({});
  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
        "updates",
        request_id
      );
      const data = await getDoc(docRef);

      console.log(['HERE', data.data()])

      setProgressObj(data.data());
    }

    handleFetch();
  }, [request_id]);

  const [orignalJob, setOriginalJob] = React.useState({});

  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id
      );
      const data = await getDoc(docRef);

      setOriginalJob(data.data());
    }

    handleFetch();
  }, [request_id]);

  async function handleSubmit() {
    if (
      window.confirm(
        `By continuing you are confirming all details are accurate and the request has been handled in accordance with local law and regulation.`
      )
    ) {
      // successfully run function


      setState((state) => ({
        ...state, 
        loading: true, 
      }))
      



      console.log(progressObj)
      if (
        // check all the fields are good to go
       `${ progressObj.investor_message && progressObj.investor_message.length && progressObj.investor_message.length}` > 2 && // message
       `${ progressObj.investor_title && progressObj.investor_title.length && progressObj.investor_title.length}` > 2 && // title
       `${ progressObj.investor_tradesman && progressObj.investor_tradesman.length && progressObj.investor_tradesman.length}` > 2 && // tradesman
       `${ progressObj.investor_urgency && progressObj.investor_urgency.length && progressObj.investor_urgency.length}` > 2  // urgency
      ) {


        // property object

        try {
        const propertyPath = doc(
          db,
          "properties",
          orignalJob.property_id
        );
        const propertyData = (await getDoc(propertyPath)).data();

        const jobRef = doc(db, "job_requests", request_id);

        setDoc(jobRef, {
          job_title: progressObj.investor_title,
          tradesman: progressObj.investor_tradesman,
          job_type: 'repair',
          job_urgency: progressObj.investor_urgency,
          job_description: progressObj.investor_message,
          job_space: progressObj.investor_space
            ? progressObj.investor_space
            : "",
          job_asset: progressObj.selected_asset
            ? progressObj.selected_asset
            : "",
          job_images: orignalJob.images ? orignalJob.images : [],
          job_property: orignalJob.property_id,
          sender: auth.currentUser.uid,
          job_id: request_id,
          created_at: serverTimestamp(),
          investor: auth.currentUser.uid,
          job_status: "Not Yet Started",
          property_address: propertyData.address_line_1,
          read: false,
          tenant_request: true,
          tenant: orignalJob.tenant,
        }).then((r) => {
          navigate("/home");
        });
    } catch (e) {
      console.log(e)
        setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage:
              "Oops... looks like an error occured please try again later...",
              successMessage: '', 
              successOpen: false,
              loading: false, 
          }));

    }






      } else {
        // else set error
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage:
            "Oops... looks like you missed a few details please make sure all fields are answered in detail...",
            loading: false, 
        }));
      
    
    
    
    
    
    
    }
    } else {
      // set error to submit cancelled
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Submit Cancelled...",
        loading: false, 
      }));
    }
  }

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  const value = (100 / state.limit) * (state.index - 1);
  return (
    <div className="ProcessTenantRequest">

      {state.loading && <div className="loading"> <CircularProgress style={{color:'rgb(18, 100, 100)'}}/></div>}


      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="Content">
        <div className="progress">
          <LinearProgress color="inherit" variant="determinate" value={value} />
        </div>

        <div className="slide">
          {state.index === 0 && <RequestStartPage />}
          {state.index === 1 && <RequestDescriptionPage />}
          {state.index === 2 && <RequestTypePage />}
          {state.index === 3 && <RequestPhotosPage />}
          {state.index === 4 && <RequestUrgencyPage />}
          {state.index === 5 && <RequestTitlePage />}
          {state.index === 6 && <RequestSelectTradesman />}
          {state.index === 7 && <RequestRelatedSpace />}
          {state.index === 8 && <RequestRelatedAsset />}
        </div>

        <div className="navigation">
          <Button
            disabled={state.index === 0}
            style={{ color: "rgb(18, 100, 100)" }}
            onClick={(e) => {
              if (state.index === 0) {
                return;
              } else {
                setState((state) => ({ ...state, index: state.index - 1 }));
              }
            }}
          >
            <ArrowBackIos />
            Previous
          </Button>

          <Button
            style={{ color: "rgb(18, 100, 100)" }}
            onClick={(e) => {
              if (state.index === state.limit) {
                handleSubmit();
              } else {
                setState((state) => ({ ...state, index: state.index + 1 }));
              }
            }}
          >

            {state.index === state.limit ? 
            'Submit' :  <>Next <ArrowForwardIos/> </>}


           
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ProcessTenantRequest;
