import { Edit } from "@mui/icons-material";
import { Card, Dialog, IconButton } from "@mui/material";
import React from "react";
import "./FloorPlan.css";
import FloorPlanEdit from "./FloorPlanEdit";
import { useAuth } from "../../Contexts/AuthContext";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

function FloorPlan() {
  // hooks
  const auth = useAuth();
  const { currentProperty } = useParams();

  // state variables
  const [state, setState] = React.useState({
    open: false,
    url: "",
  });

  React.useEffect(() => {
    console.log(currentProperty);
    async function handleFetch() {
      const docRef = doc(db, "properties", currentProperty);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setState((state) => ({
          ...state,
          url: docSnap.data().floor_plan ? docSnap.data().floor_plan : "",
        }));

        console.log(docSnap.data());
      } else {
        console.log("no file exists");
      }
    }

    handleFetch();
  }, [auth.currentUser, currentProperty]);

  // Dialog Window

  function DialogWindow() {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.open}
        onClose={(e) => {
          handleClose();
        }}
      >
        <FloorPlanEdit />
      </Dialog>
    );
  }

  function handleClose() {
    setState((state) => ({
      ...state,
      open: false,
    }));
  }


  return (
    <Card className="FloorPlan">
      <DialogWindow />
      <div className="title"> Floor Plan</div>
      <div className="parent"> 
     
      <div className="floorPlan">

        <img className="image" alt='' src={state.url}/>


      

       
      </div>
      </div> 
      <div className="fab">
      <IconButton
          onClick={(e) => {
            setState((state) => ({
              ...state,
              open: true,
            }));
          }}
          className="button"
          style={{ color: "rgb(18, 100, 100)", backgroundColor: "white" }}
        >
          <Edit />
        </IconButton></div>
    </Card>
  );
}

export default FloorPlan;
