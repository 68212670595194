import { Add } from "@mui/icons-material";
import {
  Avatar,
  Card,
  Fab,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import { useAuth } from "../../Contexts/AuthContext";
import "./PropertyTradesman.css";

function PropertyTradesman() {
  // hooks
  const navigate = useNavigate();
  const auth = useAuth();
  const { currentProperty } = useParams();

  // state variables
  const [state, setState] = React.useState({
    tradesman: [],
  });

  // UseEffect Fetch Function
  React.useEffect(() => {
    async function handleDocumentFetch() {
      const docRef = doc(db, "properties", currentProperty);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (typeof docSnap.data().tradesman !== "undefined") {
          const arr = [...docSnap.data().tradesman];
          const dataArr = []

           const results = arr.map(async (tradesman) => {
            const tradieRef = doc(db, "Tradesman", tradesman);
            const tradieSnap = await getDoc(tradieRef);
            if (tradieSnap.exists()) {
              dataArr.push({...tradieSnap.data(), id: tradieSnap.id})
             return(tradieSnap.data());
            } else {
              return;
            }
          })

          await Promise.all(results)

          console.log(dataArr)

          setState((state) => ({
            ...state,
            tradesman: dataArr,
          }));
        } else {
          console.log("error");
          return;
        }
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    handleDocumentFetch();
  }, [currentProperty]);

  React.useEffect(() => {
    console.log(state.tradesman);
  }, [state.tradesman]);

  return (
    <Card className="PropertyTradesman">
      <div className="title"> Tradesman </div>


      <div className="list-section">
        {state.tradesman &&
          state.tradesman.map((tradesman) => (
            <ListItem dense button key={tradesman.id} onClick={((e) => {navigate(`/${auth.currentUser.uid}/tradesman/search/result/${tradesman.id}`)})}>
              <ListItemAvatar>
                <Avatar src={tradesman.logo} />
              </ListItemAvatar>
              <ListItemText
                primary={tradesman.public_name}
                secondary={tradesman.address_city}
              />
            </ListItem>
          ))}

        <Fab
          onClick={(e) => {
            navigate(`/${auth.currentUser.uid}/tradesman/search/${currentProperty}`);
          }}
          className="fabButton"
          size="small"
          style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
        >
          <Add />
        </Fab>
      </div>
    </Card>
  );
}

export default PropertyTradesman;
