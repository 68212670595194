
 import {
   Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import "./TenantAddDialogContent.css";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

function TenantAddDialogContent() {

const {currentProperty} = useParams() ;


  /* state variables */
  const [state, setState] = React.useState({
    errorMessage: "",
    succcessMessage: "",
    errorOpen: false,
    successOpen: false,
    email: "",
    first_name: "",
    last_name: "",
    message: "",
    loading: false,
  });

  /* function to handle when text input changes */
  function textChange(field, e) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  /* handle database API call to add document */
  async function handleTenantInvite() {
    if (
      state.email === "" ||
      state.first_name === "" ||
      state.last_name === "" ||
      state.message === ""
    ) {
      /* handle missed field */
      setState((state) => ({
        ...state,
        errorMessage: "Oops... looks like you missed a field...",
        errorOpen: true,
        successOpen: false,
        succcessMessage: "",
      }));
    } else {
      /* run through add function */
      if (
        window.confirm(
          "Are you sure you want to send this invite? if there is currently a tenant assigned they will be replaced upon the invitation acceptance."
        )
      ) {
        /* handle confirmed process */
        await setState((state) => ({
            ...state, 
            loading: true,
        }))

        const docRef = collection(db, "properties", currentProperty, 'tenant_requests'); // define doc path

        await addDoc(docRef, 
          {
            first_name: state.first_name, 
            last_name: state.last_name, 
            email: state.email, 
            message: state.message,
            created_at: serverTimestamp()
        }
          ).then((r) => {
            console.log('aftr')
            /* set success and refresh page */
            setState((state) => ({
                ...state,
                errorMessage: "",
                errorOpen: false,
                successOpen: true,
                succcessMessage: "Successfully Sent Invitation...",
                loading: false,
              }));
              setTimeout(() => {
               window.location.reload(false)
              }, 1500);

        }).catch((r) => {
            /* set error */
            setState((state) => ({
                ...state,
                errorMessage: "Oh No... Something went wrong... please try again...",
                errorOpen: true,
                successOpen: false,
                succcessMessage: "",
                loading: false,
              }));
        })



      } else {
        setState((state) => ({
          ...state,
          errorMessage: "Tenant Invite Cancelled.",
          errorOpen: true,
          successOpen: false,
          succcessMessage: "",
        }));
      }
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorMessage: "",
      errorOpen: false,
      successOpen: false,
      succcessMessage: "",
    }));
  };

  const [vertical] = React.useState("bottom");

  const [horizontal] = React.useState("right");

  return (
    <>

{ state.loading &&   <div className="loading">
      <CircularProgress style={{color:'rgb(18, 100, 100)'}}/> 
      </div>}
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.succcessMessage}
        </Alert>
      </Snackbar>


        <div className="TenantAddDialog">
          <div className="title">
          Update Tenant
          </div>

          <div className="fields">
            <div className="fullwidth">
              <TextField
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                value={state.email}
                onChange={(e) => {
                  textChange("email", e);
                }}
                disabled={state.loading}
              />
            </div>
            <div className="halves">
              <div className="half">
                <TextField
                  label="First Name"
                  fullWidth
                  variant="outlined"
                  value={state.first_name}
                  onChange={(e) => {
                    textChange("first_name", e);
                  }}
                  disabled={state.loading}
                />
              </div>
              <div className="half">
                <TextField
                  label="Last Name"
                  fullWidth
                  variant="outlined"
                  value={state.last_name}
                  onChange={(e) => {
                    textChange("last_name", e);
                  }}
                  disabled={state.loading}
                />
              </div>
            </div>
            <div className="fullwidth">
              <TextField
                label="Message"
                fullWidth
                variant="outlined"
                value={state.message}
                onChange={(e) => {
                  textChange("message", e);
                }}
                multiline
                rows={5}
                disabled={state.loading}
              />
            </div>
          </div>
          <div className="buttonSection">
            <Button
              className="button"
              style={{ backgroundColor: "rgb(18, 100, 100)", color: "white", fontWeight:'bold' }}
              onClick={((e) => {
                  handleTenantInvite()
              })}
              disabled={state.loading}
            >
              Replace Tenant
            </Button>
          </div>
        </div>
    </>
  );
}

export default TenantAddDialogContent;
