import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import {
  getFirestore,
} from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyDJ4Y241KaY_gPr4Q_bLUzTxmRUJP7Y4Sk",
    authDomain: "tenack-9d967.firebaseapp.com",
    projectId: "tenack-9d967",
    storageBucket: "tenack-9d967.appspot.com",
    messagingSenderId: "634002280728",
    appId: "1:634002280728:web:a360e4536e16445864ce6d",
    measurementId: "G-PQLP9HHT3L"
  };
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);




const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return({success: true, message: 'successfully logged user in, you will be redirected shortly...'})
  } catch (err) {
    return({success: false, message: 'Failed to log user in please check credentials and try again...', err})
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
   const res = await createUserWithEmailAndPassword(auth, email, password)
  const user = res.user;
  return({success: true, message: 'Successfully created user...', user})
  } catch (err) {
    return({success: false, message: 'Failed to create user...'})
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return({success: true, message: 'Successfully sent password reset link to provided email address...'})
  } catch (err) {
    return({success: false, message: 'Failed to send password reset link please check email address...'})
  }
};

const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  storage,
  functions,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
