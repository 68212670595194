import { doc, getDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import AssetHistoryAttachmentsCard from './AssetHistoryAttachmentsCard'
import AssetHistoryDetailsCard from './AssetHistoryDetailsCard'
import './AssetHistoryPage.css'


function AssetHistoryPage() {


    // hooks
    const { currentProperty, currentAsset, historyEventId } =
    useParams();


    // state variables

    const [state, setState] = React.useState({
        title: '', 
        date: '', 
    })


    React.useEffect(() => {
        async function handleFetch() {
          const docRef = 
            doc(
              db,
              "properties",
              currentProperty,
              "assets",
              currentAsset,
              "asset_events",
              historyEventId
            )
    
          const snapshot = await getDoc(docRef);
    
          const {title, date} = snapshot.data();
    
          setState((state) => ({
            ...state,
            title: title ? title : '',
            date: date ? date : true, 
          }));

       
        }
    
        handleFetch();
      }, [currentProperty, currentAsset, historyEventId, ]);


  return (
    <>
    <div style={{height:'50px'}}/>

    <div className='AssetHistoryPage'>

        <div className='cornerDetails'> 
          <div className='title'>{state.title}</div>
          <div className='date'>{state.date}</div>
        </div>

        <div className='content'>
            <AssetHistoryDetailsCard />
            <AssetHistoryAttachmentsCard /> 

        </div>



    </div>
    </>
  )
}

export default AssetHistoryPage