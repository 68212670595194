import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React from 'react'
import { auth, db } from '../../../firebase';
import './Styles/Type.css'

function Type() {

  const [state, setState] = React.useState({
    type: '', 
  })

  React.useEffect(() => {
    async function handleDefaultFetch () {
      const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

      const docRef = await getDoc(docPath); 

      if (docRef.exists) {
        const {type} = docRef.data(); 
        setState((state) => ({
          ...state, 
          type: type ? type : '', 
        }))

      } else {
        return;
      }

    }
    handleDefaultFetch()
  }, [])


  function handleSave () {
    const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

    setDoc(docPath, {
      type: state.type, 
    }, {merge: true}).catch((err) => {
      console.log(err)
    })

  }


  return (
    <div className='Type'>

        <div className='caption'>
            What type of work is required?
        </div>


        <div className='textField'>
        <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
            
              value={state.type}
              label="Event Type"
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  type: e.target.value,
                }));
              }} 
              onBlur={((e) => {handleSave()})}
            >
              <MenuItem value={"Repair"}>Repair</MenuItem>
              <MenuItem value={"Service"}>Service</MenuItem>
              <MenuItem value={"Maintenace"}>Maintenace</MenuItem>
              <MenuItem value={"Installation"}>Installation</MenuItem>
              <MenuItem value={"Other..."}>Other...</MenuItem>
            </Select>
          </FormControl>
        </div>
        

    </div>
  )
}

export default Type