import { Add, Build } from '@mui/icons-material'
import { Avatar, Card, Fab, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { collection, getDocs, query, where } from 'firebase/firestore'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, db } from '../../../../firebase'
import './PropertyJobs.css'

function PropertyJobs() {

    // hooks 
    const {currentUser} = auth; 
    const navigate = useNavigate();

    // state variables
    const [state, setState] = React.useState({
        open: false, 
        jobs: [],

    })



    React.useEffect(() => {
        async function handleDocumentFetch() {
          const q = query(
            collection(db, "job_requests"),
            where("investor", "==", currentUser.uid)
          );
    
          const querySnapshot = await getDocs(q);
    
          setState((state) => ({
            ...state,
            jobs: querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            })),
          }));
        }
        handleDocumentFetch();
      }, [currentUser]);



  return (
    <Card className='PropertyJobs'>
        <div className='title'>Tradesman Jobs</div>
        <div className='list'>
            {state.jobs.map((job) => (
                <ListItem button dense onClick={((e) => {navigate(`/jobs/${job.id}`)})} >
                    <ListItemAvatar>

                        {job.job_images[0] ? 
                        
                        <Avatar alt={job.job_title} src={job.job_images[0] && job.job_images[0].url }/>
                        : 
                        <Build style={{color:'rgb(18, 100, 100)', margin:'5px'}}/>
                    }


                    
                    </ListItemAvatar>
                    <ListItemText primary={job.job_title}/>
                </ListItem>
            ))}

            <Fab onClick={((e) => {navigate(`/newJob/new`)})} size='small' style={{color:'white', backgroundColor:'rgb(18, 100, 100)'}} className='iconButton'>
                <Add />
            </Fab>


        </div>



    </Card>
  )
}

export default PropertyJobs