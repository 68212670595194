import { CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./Styles/TrackingDialogContent.css";
import paid from './paid.svg'

function TrackingDialogContent() {
  // hooks

  const { currentJob } = useParams();
  const jobId = currentJob;

  // state
  const [state, setState] = React.useState({
    loading: false,
    array: [],
    data: {},
    invoice_paid: false,
    invoice_url: "",
  });

  React.useEffect(() => {
    async function handleInvoiceFetch() {
      const docPath = doc(db, "invoices", jobId);
      const docData = await getDoc(docPath);
      if (docData.exists) {
        if (docData.data().url) {
          setState((state) => ({
            ...state,
            invoice_paid: docData.data().paid,
            invoice_url: docData.data().url,
          }));
        }
      }
    }
    handleInvoiceFetch();
  }, [jobId]);

  // fetch job data

  // arr of potential statuses

  React.useEffect(() => {
    const progress = [
      { title: "Job Created", date: "", booking_time: "" },
      { title: "Job Assigned To Tradesman", date: "", booking_time: "" },
      { title: "Job Scheduled", date: "", booking_time: "" },
      { title: "Work Complete", date: "", booking_time: "" },
      { title: "Invoice Sent", date: "", booking_time: "" },
      { title: "Invoice Paid", date: "", booking_time: "" },
    ];

    async function fetchData() {
      const docRef = doc(db, "job_requests", currentJob);

      await getDoc(docRef)
        .then((r) => {
          console.log(jobId);
          const arr = [...progress];
          arr.splice(0, 1, {
            ...progress[0],
            date: r.data().created_at ? r.data().created_at : "",
          });
          arr.splice(1, 1, {
            ...progress[1],
            date: r.data().approved_at ? r.data().approved_at : "",
          });
          arr.splice(2, 1, {
            ...progress[2],
            date: r.data().assigned_date ? r.data().assigned_date : "",
            booking_time: r.data().booking_time ? r.data().booking_time : "",
          });
          arr.splice(3, 1, {
            ...progress[3],
            date: r.data().completed_at ? r.data().completed_at : "",
          });
          arr.splice(4, 1, {
            ...progress[4],
            date: r.data().invoiced_at ? r.data().invoiced_at : "",
          });
          arr.splice(5, 1, {
            ...progress[5],
            date: r.data().paid_at ? r.data().paid_at : "",
          });

          setState((state) => ({
            ...state,
            array: arr,
            data: r.data(),
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetchData();
  }, [currentJob, jobId]);

  function TimelineItem({ data }) {
    if (data.date) {
      console.log();
      const server = data.date.toDate();
      const local = new Date(server).toLocaleDateString();
      const time = new Date(server).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      if (data.booking_time === "") {
        return (
          <div className="TimelineItem_item">
            <div className="TimelineItem_item_content">
              <div className="TimelineItem_time">{`${local} @ ${time}`}</div>
              <div className="TimelineItem_title">{data.title}</div>

              {data.title === 'Invoice Sent' &&
              state.invoice_paid &&
              <img alt={'paid badge'} className="TimelineItem_paidLogo" src={paid}/>}


              {data.title === "Invoice Sent" && (
                <>
                  <Button
                    disabled={state.invoice_paid}
                    onClick={(e) => {
                      window.open(state.invoice_url);
                    }}
                    size={"small"}
                    style={
                      state.invoice_paid
                        ? {
                            backgroundColor: "lightgray",
                            color: "white",
                            fontWeight: "bold",
                          }
                        : {
                            backgroundColor: "rgb(18, 100, 100)",
                            color: "white",
                            fontWeight: "bold",
                          }
                    }
                  >
                    Pay Now
                  </Button>
                </>
              )}
              {<CheckCircle color="inherit" className="circle" />}
            </div>
          </div>
        );
      } else {
        const Bookingserver = data.booking_time;
        const Bookinglocal = new Date(Bookingserver).toLocaleDateString();
        return (
          <div className="TimelineItem_item">
            <div className="TimelineItem_item_content">
              <div className="TimelineItem_time">{`${local} @ ${time}`}</div>
              <div className="TimelineItem_title">{data.title}</div>

              <div className="TimelineItem_subtitle">
                {data.booking_time ? Bookinglocal : ""}
              </div>
              {<CheckCircle color="inherit" className="circle" />}
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="TimelineItem_item-incomplete">
          <div className="TimelineItem_item_content-incomplete">
            <div className="TimelineItem_title-incomplete">{data.title}</div>

            {<CheckCircle color="inherit" className="circle" />}
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <div className="RequestTrackingPage_dialog">
        <div className="RequestTrackingPage_title"> Track Progress</div>
        <div className="Timeline_list">
          {state.array &&
            state.array.map((data, idx) => (
              <TimelineItem data={data} key={idx} />
            ))}
        </div>
        <div className="RequestTrackingPage_button_div">
          <Button
            href="mailto:support@tenack.com?subject=Job Tracking Issue &body=Hi, Tenack Team! I'm having an issue while tracking my job"
            color="inherit"
          >
            Report Issue
          </Button>
        </div>
      </div>
    </>
  );
}

export default TrackingDialogContent;
