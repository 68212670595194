import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Alert, IconButton, InputAdornment, Snackbar, TextField } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { handleAssetEventSave, handleDefaultValueFetch } from './HandleSaveFunction';

function Expense() {

  const { currentProperty, currentAsset } = useParams();

    const [state, setState] = React.useState({
        expesne: '',
        expense_visibility: true, 
    })


    React.useEffect(() => {
      handleDefaultValueFetch(['expense', 'expense_visibility'], currentProperty, currentAsset).then((res) => {
       setState(res)
      })
    }, [currentProperty, currentAsset])


    async function onBlur() {
      setState((state) => ({
        ...state,
        loading: true,
      }));
      handleAssetEventSave(
        { expense: state.expense, expense_visibility: state.expense_visibility},
        currentProperty,
        currentAsset
      ).then((res) => {
        const newObj = Object.assign(state, res);
        setState(newObj);
      });
    }
  
    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successOpen: false,
        successMessage: "",
        errorMessage: "",
        errorOpen: false,
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal] = React.useState("right");


  return (
    <div className="Expense">
        <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
    <div className="title">How much did this event cost?</div>
    <div className="subtitle">
      this value can be hidden from tradesman and other users with the
      ability to access this property using the eye icon.
    </div>
    <div className="fullwidth">
      <TextField
       onBlur={(e) => {
        onBlur();
      }}

        label="Expense"
        fullWidth
        value={state.expense}
        onChange={((e) => {setState((state) => ({
          ...state, 
          expense: e.target.value, 
        }))})}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">$</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton  onBlur={(e) => {
        onBlur();
      }}  onClick={((e) => {
                setState((state) => ({
                  ...state, 
                  expense_visibility: !state.expense_visibility
                }))
              })}>
              {state.expense_visibility ?  <Visibility style={{ color: "rgb(18, 100, 100)" }} /> : <VisibilityOff style={{ color: "rgb(18, 100, 100)" }} />} 
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  </div>
  )
}

export default Expense