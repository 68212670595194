import { Add, History } from "@mui/icons-material";
import {
  Card,
  Dialog,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { collection, getDocs, query } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import AddAsset from "./Add Asset/AddAsset";
import "./AssetHistory.css";

function AssetHistory() {
  // hooks

  const { currentProperty, currentAsset, currentSpace } = useParams();
  const navigate = useNavigate();
  // state variables
  const [state, setState] = React.useState({
    open: false,
    events: [],
  });

  React.useEffect(() => {
    async function handleFetch() {
      const q = query(
        collection(
          db,
          "properties",
          currentProperty,
          "assets",
          currentAsset,
          "asset_events"
        )
      );
      const querySnapshot = await getDocs(q);

      setState((state) => ({
        ...state,
        events: querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })),
      }));
    }

    handleFetch();
  }, [currentProperty, currentAsset]);

  function DialogWindow() {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.open}
        onClose={(e) => {
          setState((state) => ({ ...state, open: false }));
        }}
      >
        <AddAsset />
      </Dialog>
    );
  }

  return (
    <Card className="AssetHistory">
      <DialogWindow />
      <div className="title">Asset History</div>
      <div className="list">
        {state.events.map((event) => (
          <ListItem dense button key={event.id} onClick={((e) => {navigate(`/${currentProperty}/spaces/${currentSpace}/assets/${currentAsset}/${event.id}`)})}>
            <ListItemAvatar>
              <History style={{ color: "rgb(18, 100, 100)" }} />
            </ListItemAvatar>
            <ListItemText primary={event.title} secondary={event.date} />
          </ListItem>
        ))}
      </div>
      <IconButton
        onClick={(e) => {
          setState((state) => ({ ...state, open: true }));
        }}
        className="IconButton"
        style={{ color: "rgb(18, 100, 100)" }}
      >
        <Add  />
      </IconButton>
    </Card>
  );
}

export default AssetHistory;
