import React from "react";
import {
  Avatar,
  Dialog,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import UserDetailsContent from "./UserDetailsContent";
import { useAuth } from "../../Contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

function UserDetails() {

   // hooks
   const auth = useAuth();



  // state variables
  const [state, setState] = React.useState({
    open: false,
    profile_photo: '', 
    firstName: '', 
    lastName: '', 
    contactEmail: ''
  });


 // fetch intial data
 React.useEffect(() => {
  async function handleDocumentFetch() {
    const docRef = doc(db, "Property_Investor", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setState((state) => ({
        ...state,
        firstName: docSnap.data().first_name ? docSnap.data().first_name : "",
        lastName: docSnap.data().last_name ? docSnap.data().last_name : "",
        contactEmail: docSnap.data().contact_email
          ? docSnap.data().contact_email
          : "",
        profile_photo: docSnap.data().profile_photo ? docSnap.data().profile_photo : "",
        
      }));
      console.log(docSnap.data(), docSnap.id);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }
  handleDocumentFetch();
}, [auth.currentUser]);
  

  function DialogWindow() {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.open}
        onClose={(e) => {
          setState((state) => ({ ...state, open: !state.open }));
        }}
      >
        <UserDetailsContent />
      </Dialog>
    );
  }

  return (
    <>
      <DialogWindow />
      <ListItem
        button
        style={{ borderRadius: "5px" }}
        dense
        onClick={(e) => {
          setState((state) => ({ ...state, open: !state.open }));
        }}
      >
        <ListItemAvatar>
          <Avatar src={state.profile_photo}/>
        </ListItemAvatar>
        <ListItemText primary={`${state.firstName} ${state.lastName}`} secondary={state.contactEmail} />
      </ListItem>
    </>
  );
}

export default UserDetails;
