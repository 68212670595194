import { FormControl, InputLabel, Select } from "@mui/material";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "../../../firebase";
import "./Styles/RequestRelatedSpace.css";

function RequestRelatedSpace() {
  // hooks
  const { request_id } = useParams();
  // state
  const [state, setState] = React.useState({
    tenant_space: "",
    investor_space: "",
    property_spaces: [],
  });

  // update call
  function handleDocUpdate(value) {
    const docRef = doc(
      db,
      "Property_Investor",
      auth.currentUser.uid,
      "tenant_maintenace_requests",
      request_id,
      "updates",
      request_id
    );
    setDoc(docRef, {
      investor_space: state.investor_space,
    }, {merge: true});
  }

    // fetch call
    React.useEffect(() => {
      async function handleDocFetch() {
        const docRef = doc(
          db,
          "Property_Investor",
          auth.currentUser.uid,
          "tenant_maintenace_requests",
          request_id,
          "updates",
          request_id
        );
  
        const { investor_space } = await (await getDoc(docRef)).data();
  
        if (investor_space) {
          setState((state) => ({
            ...state,
            investor_space: investor_space,
          }));
        } else {
          return;
        }
      }
  
      handleDocFetch();
    }, [ request_id]);


    React.useEffect(() => {
      async function handleDocFetch() {
        const docRef = doc(
          db,
          "Property_Investor",
          auth.currentUser.uid,
          "tenant_maintenace_requests",
          request_id,
        );
  
        const { property_id, selected_space } = await (await getDoc(docRef)).data();

        const propertyRef = collection(db, 'properties', property_id, 'spaces'); 
        const {docs} = await getDocs(propertyRef); 

        if (property_id) {
          setState((state) => ({
            ...state, 
            property_spaces: docs.map((doc) => ({...doc.data(), id: doc.id})), 
            tenant_space: selected_space, 
          }))

        } else {
          return;
        }
      }
  
      handleDocFetch();
    }, [ request_id]);





  const tenantName = () => {
    const spaces = [...state.property_spaces];
    const tenantId = state.tenant_space;
    const investorSpaceObj = spaces.find((item) => item.id === tenantId)
    return (investorSpaceObj && investorSpaceObj.display_name ? investorSpaceObj.display_name : '')

  }


  return (
    <>
      <div className="RequestRelatedSpace_content">
        <div className="RequestRelatedSpace_title">
          What space is the request in?
        </div>
        <div className="RequestRelatedSpace_message_title">Tenant Response</div>
        <div className="RequestRelatedSpace_message">
          {`${tenantName()}`}
        </div>
        <FormControl
            variant="outlined"
            fullWidth
            
            
          >
            <InputLabel>Request Space</InputLabel>
            <Select
              label='Request Space'
    fullWidth
    variant='outlined'
    native
              value={state.investor_space}
              onChange={((r) => {
                setState((state) => ({
                  ...state, 
                  investor_space: r.target.value
                }))
              })}
              onBlur={((r) => {
                handleDocUpdate();
              })}
            >
              {/* map through all of this properties tradesmen */}
              <option value={''}>{''}</option>
              {state.property_spaces &&
                state.property_spaces.map((item) => (
                  <option key={item.id} value={item.id}>{item.display_name}</option>
                ))}
            </Select>
          </FormControl>

      </div>
    </>
  );
}

export default RequestRelatedSpace;
