import { Card, Dialog, IconButton } from "@mui/material";
import React from "react";
import "./AccessCard.css";
  import { useAuth } from "../../Contexts/AuthContext";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Edit } from "@mui/icons-material";
import EditAccess from "./EditAccess";

function AccessCard() {
  // hooks
  const auth = useAuth();
  const { currentProperty } = useParams();

  // state variables
  const [state, setState] = React.useState({
    display_image: "",
    property_notes: "",
    open: false,
  });

  React.useEffect(() => {
    console.log(currentProperty);
    async function handleFetch() {
      const docRef = doc(db, "properties", currentProperty);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setState((state) => ({
          ...state,
          display_image: docSnap.data().display_image
            ? docSnap.data().display_image
            : "",
          property_notes: docSnap.data().property_notes
            ? docSnap.data().property_notes
            : "",
        }));

        console.log(docSnap.data());
      } else {
        console.log("no file exists");
      }
    }

    handleFetch();
  }, [auth.currentUser, currentProperty]);



  // dialog window
  function handleClose () {
    setState((state) => ({
        ...state, 
        open: false, 
    }))
  }

  function DialogWindow () {
    return (
        <Dialog fullWidth maxWidth='sm' open={state.open} onClose={handleClose}>
            <EditAccess /> 
        </Dialog>
    )
  }

  return (
    <Card className="AccessCard">
        <DialogWindow />
      <div className="title">Property Access</div>

      <div className="image-section">
        <img alt="property access" src={state.display_image} className="img" />
      </div>

      <div className="access-notes">
       {state.property_notes} 
        <div className="editButton"> 
               <IconButton onClick={((e) => {
                setState((state) => ({
                    ...state, 
                    open: true, 
                }))
               })} size="small" style={{color:'rgb(18, 100, 100)'}}>
            <Edit />
        </IconButton>   
        </div>
  
      
      </div>
    </Card>
  );
}

export default AccessCard;
