import { Edit } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import Assets from "./Assets/Assets";
import EditSpace from "./Edit Space/EditSpace";
import SpaceImages from "./Space Images/SpaceImages";
import SpaceNotes from "./Space Notes/SpaceNotes";
import "./SpacesPage.css";

function SpacesPage() {
  // hooks
  const { currentProperty, currentSpace } = useParams();

  // state

  const [state, setState] = React.useState({
    title: "",
    width: "",
    length: "",
    height: "",
    open: false,
  });

  function DialogWindow() {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.open}
        onClose={(e) => {
          setState((state) => ({ ...state, open: false }));
        }}
      >
        <EditSpace />
      </Dialog>
    );
  }

  React.useEffect(() => {
    async function handleDataFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "spaces",
        currentSpace
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        setState((state) => ({
          ...state,
          title: docSnap.data().display_name ? docSnap.data().display_name : "",
          width: docSnap.data().space_width ? docSnap.data().space_width : "",
          length: docSnap.data().space_length
            ? docSnap.data().space_length
            : "",
          height: docSnap.data().space_height
            ? docSnap.data().space_height
            : "",
        }));
      } else {
        return;
      }
    }
    handleDataFetch();
  }, [currentProperty, currentSpace]);

  return (
    <div className="SpacesPage">
      <DialogWindow />

      <div className="details">
        <div className="right">
          <div className="title">{state.title}</div>
          <div className="dimensions">{`${state.width} x ${state.length} x ${state.height}M`}</div>
        </div>
        <div className="left">
          <IconButton
            style={{ color: "rgb(18, 100, 100)" }}
            onClick={(e) => {
              setState((state) => ({ ...state, open: true }));
            }}
          >
            <Edit />
          </IconButton>
        </div>
      </div>

      <div className="pageContent">
        <SpaceImages /> 
        <Assets title="Assets" space={currentSpace} />
        <SpaceNotes />
     
      </div>
    </div>
  );
}

export default SpacesPage;
