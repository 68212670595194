import { Alert, Button, InputAdornment, Snackbar, TextField } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import './Spaces.css'

function SpaceAddDialogContent() {

    const {currentProperty} = useParams()

    const [state, setState] = React.useState({
        spaceName: '', 
        length: '', 
        width: '', 
        height: '', 
        type: '', 
        errorMessage: '', 
        errorOpen: false, 
        successMessage: '', 
        successOpen: false,
    })




  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("center");




    async function handleSpaceAdd () {

        const docPath = collection(db, 'properties', currentProperty, 'spaces'); 
    
    
        if (
            state.spaceName === '' ||
    state.type === '' ||
    state.height === '' ||
    state.length === '' ||
    state.width === '' 
        ) {
            setState((state) => ({
                ...state, 
                successMessage: '', 
                errorOpen: true, 
                successOpen: false, 
                errorMessage: 'Oops looks like you missed some details...', 
            }))
    
    
        } else {
    
        await addDoc(docPath, 
            {
                display_name: state.spaceName, 
                measurement_type: 'Metres', 
                room_type: state.type, 
                space_height: state.height, 
                space_length: state.length, 
                space_width: state.width, 
            }).then((res) => {
                setState((state) => ({
                    ...state, 
                    open: false, 
                    errorMessage: '', 
                    errorOpen: false, 
                    successOpen: true, 
                    successMessage: 'Successfully Added New Space!', 
                }))


                setTimeout(() => {
                    window.location.reload(); 
                    
                }, 1000);
    
            }).catch((err) => {
                console.log(err)
                setState((state) => ({
                    ...state, 
                    open: false, 
                    successMessage: '', 
                    errorOpen: true, 
                    successOpen: false, 
                    errorMessage: 'Failed to add new space...', 
                }))
            })
    
    
    
        }
      }


  return (
    <> 

<Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
    
    <div className="AddSpaceDialog">

    <div className="fullwidth">
      <TextField
        fullWidth
        placeholder="Master Bedroom..."
        label="Space Name" value={state.spaceName} onChange={((e) => {setState((state) => ({...state, spaceName: e.target.value}))})}
      />
    </div>
    <div className="thirds">
      <div className="third">
        <TextField
          fullWidth
          placeholder="3m"
          label="Length" value={state.length} onChange={((e) => {setState((state) => ({...state, length: e.target.value}))})}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">m</InputAdornment>
            ),
          }}
        />
      </div>
      <div className="third">
        <TextField
          fullWidth
          placeholder="3m"
          label="Width" value={state.width} onChange={((e) => {setState((state) => ({...state, width: e.target.value}))})}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">m</InputAdornment>
            ),
          }}
        />
      </div>
      <div className="third">
        <TextField
          fullWidth
          placeholder="2.5m"
          label="Height" value={state.height} onChange={((e) => {setState((state) => ({...state, height: e.target.value}))})}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">m</InputAdornment>
            ),
          }}
        />
      </div>
    </div>
    <div className="fullwidth">
      <TextField fullWidth placeholder="Bedroom..." label="Space Type" value={state.type} onChange={((e) => {setState((state) => ({...state, type: e.target.value}))})} />
    </div>
    <div className="buttonDiv">
      <Button
      onClick={((e) => {handleSpaceAdd()})}
        style={{
          backgroundColor: "rgb(18, 100, 100)",
          color: "white",
          fontWeight: "bold",
        }}
      >
        ADD SPace
      </Button>
    </div>
  </div></>
  )
}

export default SpaceAddDialogContent