import { Add, Label } from "@mui/icons-material";
import { Card, Dialog, Fab, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import AddAssetDialogContent from "../../Properties Page/AddAssetDialogContent";
import "./Assets.css";

function Assets(props) {
  // hooks
  const {currentProperty, currentSpace} = useParams();
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    assets: [],
    open: false,
  });

  React.useEffect(() => {
    async function handleFetch() {
      const q = query(collection(db, "properties", currentProperty, "spaces", currentSpace, 'assets'));
      const q2 = query(collection(db, "properties", currentProperty, 'assets'), where('space', '==', currentSpace ));

      const querySnapshot = await getDocs(q);
      const querySnapshot2 = await getDocs(q2); 

      const qData = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id})); 
      const qData2 = querySnapshot2.docs.map((doc) => ({...doc.data(), id: doc.id}))
      const arr = [...qData, ...qData2]; 


      

        const unique = arr.filter(
          (obj, index) =>
            arr.findIndex((item) => item.id === obj.id) === index
        );
    

 const newq = arr.filter((item, index) => arr.indexOf(item.id) === index);

  console.log(newq, unique)

      setState((state) => ({
        ...state, 
        assets: unique
      }))
    }
    handleFetch();
  }, [ currentProperty, currentSpace]);


  function DialogWindow () {
    return(
      <Dialog open={state.open} onClose={((e) => {setState((state) => ({...state, open: false,}))})} fullWidth maxWidth='xs'>
        <AddAssetDialogContent space={props.space}/>
      </Dialog>
    )
  }

  return (
    <Card className="Assets">
      <DialogWindow />
      <div className="title">{'Assets'}</div>
      <div className="listSection">
        {state.assets.map((asset) => (
            <ListItem button index={asset.id} onClick={((e) => {
              navigate(`/${currentProperty}/spaces/${currentSpace}/assets/${asset.id}`)
            })}>
                <ListItemIcon style={{color:'rgb(18, 100, 100)'}}>
                    <Label />
                </ListItemIcon>
                <ListItemText primary={asset.display_name}/>
            </ListItem>
        ))}


        <Fab
          className="fab"
          onClick={((e) => {setState((state) => ({...state, open: !state.open}))})}
          style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
          size="small"
        >
          <Add />
        </Fab>
      </div>
    </Card>
  );
}

export default Assets;
