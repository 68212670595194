import "./App.css";
import Login from "./Components/Login/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SignUp from "./Components/Sign Up/SignUp";
import ResetPassword from "./Components/Reset/ResetPassword";
import { AuthProvider, useAuth } from "./Components/Contexts/AuthContext";
import Header from "./Components/Header/Header";
import Properties from "./Components/Properties Page/Properties";
import TradesmanSearch from "./Components/Add Tradesman/TradesmanSearch";
import TradesmanSearchResults from "./Components/Add Tradesman/TradesmanSearchResults";
import TradesmanProfilePage from "./Components/Add Tradesman/TradesmanProfilePage";
import SpacesPage from "./Components/Spaces Page/SpacesPage";
import AssetPage from "./Components/Asset Page/AssetPage";
import AssetHistoryPage from "./Components/Asset History Page /AssetHistoryPage";
import JobPage from "./Components/Job Page/JobPage";
import ProcessTenantRequest from "./Components/Process Maintenace Request/ProcessTenantRequest";
import AddProperty from "./Components/Add Property/AddProperty";
import CreateNewJob from "./Components/Create New Job/CreateNewJob";

function App() {
  function PrivateRoute({ children }) {
    const auth = useAuth();

    if (auth.currentUser) {
      return (
        <>
          <Header />
          {children}
        </>
      );
    } else {
      return <Navigate to="/login" />;
    }
  }

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
             <Route
              path="/add_property"
              element={
                <PrivateRoute>
                  <AddProperty />
                </PrivateRoute>
              }
            />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/:currentUser/properties/:currentProperty"
              element={
                <PrivateRoute>
                  <Properties />
                </PrivateRoute>
              }
            />

<Route
              path="/newJob/:step"
              element={
                <PrivateRoute>
                  <CreateNewJob />
                </PrivateRoute>
              }
            />


            <Route
              path="/:currentUser/tradesman/search/:propertyId"
              element={
                <PrivateRoute>
                  <TradesmanSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="/:currentUser/tradesman/search/results/:query/:propertyId"
              element={
                <PrivateRoute>
                  <TradesmanSearchResults />
                </PrivateRoute>
              }
            />
             <Route
              path="/:currentUser/tradesman/search/result/:tradesmanId/:propertyId"
              element={
                <PrivateRoute>
                  <TradesmanProfilePage />
                </PrivateRoute>
              }
            />

<Route
              path="/:currentUser/tradesman/search"
              element={
                <PrivateRoute>
                  <TradesmanSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="/:currentUser/tradesman/search/results/:query"
              element={
                <PrivateRoute>
                  <TradesmanSearchResults />
                </PrivateRoute>
              }
            />
             <Route
              path="/:currentUser/tradesman/search/result/:tradesmanId"
              element={
                <PrivateRoute>
                  <TradesmanProfilePage />
                </PrivateRoute>
              }
            />

<Route
              path="/:currentProperty/spaces/:currentSpace"
              element={
                <PrivateRoute>
                  <SpacesPage />
                </PrivateRoute>
              }
            />

<Route
              path="/:currentProperty/assets/:currentAsset"
              element={
                <PrivateRoute>
                  <AssetPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/:currentProperty/spaces/:currentSpace/assets/:currentAsset"
              element={
                <PrivateRoute>
                  <AssetPage />
                </PrivateRoute>
              }
            />

<Route
              path="/:currentProperty/spaces/:currentSpace/assets/:currentAsset/:historyEventId"
              element={
                <PrivateRoute>
                  <AssetHistoryPage />
                </PrivateRoute>
              }
            />

<Route
              path="/jobs/:currentJob"
              element={
                <PrivateRoute>
                  <JobPage />
                </PrivateRoute>
              }
            />


<Route
              path="/tenant_requests/:request_id"
              element={
                <PrivateRoute>
                  <ProcessTenantRequest />
                </PrivateRoute>
              }
            />



            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
