import { FormControl, InputLabel, Select } from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import "./Styles/RequestTypePage.css"

function RequestTypePage() {
  // hooks
  const { request_id } = useParams();


  // state
  const [state, setState] = React.useState({
    job_type: "Repair",
  });




  // update call

  function handleDocUpdate(value) {
    const docRef = doc(
      db,
      "Property_Investor",
      auth.currentUser.uid,
      "tenant_maintenace_requests",
      request_id,
      "updates",
      request_id
    );
    setDoc(docRef, {
      job_type: state.job_type,
    }, {merge: true});
  }


  // fetch call

  React.useEffect(() => {
    async function handleDocFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
        "updates",
        request_id
      );

      const { job_type } = await (await getDoc(docRef)).data();

      if (job_type) {
        setState((state) => ({
          ...state,
          job_type: job_type,
        }));
      } else {
        return;
      }
    }

    handleDocFetch();
  }, [request_id]);


  const options = [
    'Repair', 
    'Service', 
    'Maintenace', 
    'Installation', 
    'Other'
  ]

  return (<>
  <div className='RequestTypePage_content'>
  <div className='RequestTypePage_title'> 
    What Type Of Work Is Required?
  </div>
  <div className='RequestTypePage_message_title'> 
    Tenant Response
  </div>
  <div className='RequestTypePage_message'> 
    Repair
  </div>
  <FormControl
                variant="outlined"
                fullWidth
  
         
         >
                <InputLabel>Request Type</InputLabel>
                <Select
                onBlur={((e) => {
                  handleDocUpdate ()
                })}
                  label="Request Type"
                  value={state.job_type}
                  onChange={((e) => {
                    setState((state) => ({
                      ...state, 
                      job_type: e.target.value
                    }))
                  })}
                >
                  {options.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                </Select>
              </FormControl>

  </div>
  </>
  )
}

export default RequestTypePage