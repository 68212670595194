import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React from 'react'
import { auth, db } from '../../../firebase';
import './Styles/Urgency.css'

function SelectTradesman() {


  const [state, setState] = React.useState({
    errorOpen: false, 
    errorMessage: '', 
    property: '', 
    tradesman: '', 
    tradies: [], 
  })


  React.useEffect(() => {
    async function handleDefault () {
      const docPath = doc(db, 'Property_Investor', auth.currentUser.uid, 'holding', 'newJob'); 
      const docRef = await getDoc(docPath);
      
      if (docRef.exists) {
        const {property, tradesman} = docRef.data(); 
        setState((state) => ({
          ...state, 
          property: property ? property : '', 
          tradesman: tradesman ? tradesman : '',
        }))
      } else {
        return; 
      }
    }
    handleDefault()
  }, []); 


  React.useEffect(() => {
    async function handleDocumentFetch() {

      if (state.property === '') {
        return;
      } else {
 const docRef = doc(db, "properties", state.property);
      const docSnap = await getDoc(docRef);
 if (docSnap.exists()) {
        if (typeof docSnap.data().tradesman !== "undefined") {
          const arr = [...docSnap.data().tradesman];
          const dataArr = []

           const results = arr.map(async (tradesman) => {
            const tradieRef = doc(db, "Tradesman", tradesman);
            const tradieSnap = await getDoc(tradieRef);
            if (tradieSnap.exists()) {
              dataArr.push({...tradieSnap.data(), id: tradieSnap.id})
             return(tradieSnap.data());
            } else {
              return;
            }
          })

          await Promise.all(results)

          console.log(dataArr)

          setState((state) => ({
            ...state,
            tradies: dataArr,
          }));
        } else {
          console.log("error");
          return;
        }
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }

      }


     
     
    }
    handleDocumentFetch();
  }, [state.property]);



  function handleSave () {
    const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 
    setDoc(docPath, {
      tradesman: state.tradesman, 
    }, {merge: true}).catch((err) => {
      console.log(err)
    })
  }
  
  



  return (
    <div className='Type'>

        <div className='caption'>
          Who do you want to do this work?
        </div>


        <div className='textField'>
        <FormControl fullWidth>
            <InputLabel>Tradesman</InputLabel>
            <Select
            onBlur={((e) => {
              handleSave();
            })}
            
             value={state.tradesman}
              label="Event Type"
               onChange={(e) => {
                setState((state) => ({
                  ...state,
                  tradesman: e.target.value,
                }));
              }} 
            >

              {state.tradies.map((tradie) => (
                  <MenuItem  key={tradie.id} value={tradie.id}>{tradie.public_name}</MenuItem>
              ))}
                        </Select>
          </FormControl>
        </div>
        

    </div>
  )
}

export default SelectTradesman