import { Add, Label } from "@mui/icons-material";
import { Card, Dialog, Fab, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import AddAssetDialogContent from "../AddAssetDialogContent";
import "./PropertyAssets.css";

function PropertyAssets(props) {
  // hooks
  const { title, space } = props;
  const {currentProperty} = useParams();
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    assets: [],
    open: false, 
  });

  React.useEffect(() => {
    async function handleFetch() {
      const q = query(collection(db, "properties", currentProperty, "assets"), where("space", "==", space));
      const querySnapshot = await getDocs(q);

      setState((state) => ({
        ...state, 
        assets: querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
      }))
    }
    handleFetch();
  }, [space, currentProperty]);

  function HandleAssetAddDialog () {
    return(
      <Dialog open={state.open} onClose={((e) => {setState((state) => ({...state, open: false}))})} fullWidth maxWidth='sm'>
        <AddAssetDialogContent space={space} />
      </Dialog>
    )
  }


  return (
    <Card className="PropertyAssets">
      <HandleAssetAddDialog />
      <div className="title">{title}</div>
      <div className="listSection">
        {state.assets.map((asset) => (
            <ListItem button index={asset.id} onClick={((e) => {
              navigate(`/${currentProperty}/assets/${asset.id}`)
            })}>
                <ListItemIcon style={{color:'rgb(18, 100, 100)'}}>
                    <Label />
                </ListItemIcon>
                <ListItemText primary={asset.display_name}/>
            </ListItem>
        ))}


      
      </div>  <Fab
        onClick={((e) => {
          setState((state) => ({
            ...state, 
            open: true
          }))
        })}
          className="fab"
          style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
          size='small'
        >
          <Add />
        </Fab>
    </Card>
  );
}

export default PropertyAssets;
