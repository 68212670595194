import { Alert, Snackbar, TextField } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./EditBasicDetails.css";

function EditBasicDetails() {
  // hooks
  const { currentProperty, currentAsset } = useParams();

  // state variables
  const [state, setState] = React.useState({
    title: "",
    warranty_amount: "",
    warranty_measure: "",
    installed: '',
  });

  // useEffect to fetch default values

  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "assets",
        currentAsset
      );

      const docData = await getDoc(docRef);

      const {
        display_name,
        warranty_amount,
        warranty_measure,
        installed,
      } = docData.data();

      if (docData.exists) {
        setState((state) => ({
          ...state,
          title: display_name ? display_name : "",
          warranty_amount: warranty_amount ? warranty_amount : "",
          warranty_measure: warranty_measure ? warranty_measure : "",
          installed: installed ? installed : "",
          loading: false,
        }));
      } else {
        return;
      }
    }

    handleFetch();
  }, [currentAsset, currentProperty]);

  // function to handle updating database

  function handleDocumentUpdate () {
    const docRef = doc(
        db,
        "properties",
        currentProperty,
        "assets",
        currentAsset
      );

    updateDoc(docRef, {
       display_name: state.title,
       warranty_amount: state.warranty_amount,
       warranty_measure: state.warranty_measure, 
       installed: state.installed, 

    }).then((res) => {
        setState((state) => ({
            ...state,
            loading: false, 
            errorOpen: false, 
            errorMessage: '',
            successOpen: true, 
            successMessage: 'Successfully updated asset details...'
        }))


    }).catch((err) => {
        console.log(err)
        setState((state) => ({
            ...state,
            loading: false, 
            errorOpen: true, 
            errorMessage: 'failed to save asset details...',
            successOpen: false, 
            successMessage: ''
        }))
    })

  }




  // handle opening and closing snackbars
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="EditBasicDetails">
                       <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <div className="fullwidth">

        <TextField
        onBlur={((e) => {
            handleDocumentUpdate()
        })}
          fullWidth
          label="Asset Title"
          value={state.title}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              title: e.target.value,
            }));
          }}
        />
      </div>
      <div className="sections">
        <div className="smaller">
          <TextField
          onBlur={((e) => {
            handleDocumentUpdate()
          })}
            fullWidth
            label="Warranty Amount"
            value={state.warranty_amount}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                warranty_amount: e.target.value,
              }));
            }}
          />
        </div>
        <div className="bigger">
          <TextField
          onBlur={((e) => {
            handleDocumentUpdate()
          })}
            fullWidth
            label="Warranty Measure"
            value={state.warranty_measure}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                warranty_measure: e.target.value,
              }));
            }}
          />
        </div>
      </div>
      <div className="fullwidth">
        <TextField
        onBlur={((e) => {
            handleDocumentUpdate()
        })}
          fullWidth
          label="Installation Date"
          type="date"
          datatype="dd/mm/yyyy"
          InputLabelProps={{
            shrink: true,
          }}
          value={state.installed}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              installed: e.target.value,
            }));
          }}
        />
      </div>
    </div>
  );
}

export default EditBasicDetails;
