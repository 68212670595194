import { TextField } from '@mui/material'
import React from 'react'
import './Styles/Description.css'
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from '../../../firebase';


function Description() {


  const [state, setState] = React.useState({
    description: '', 
  })

  React.useEffect(() => {
    async function handleDefaultFetch () {
      const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

      const docRef = await getDoc(docPath); 

      if (docRef.exists) {
        const {description} = docRef.data(); 
        setState((state) => ({
          ...state, 
          description: description ? description : '', 
        }))

      } else {
        return;
      }

    }
    handleDefaultFetch()
  }, [])


  function handleSave () {
    const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

    setDoc(docPath, {
      description: state.description, 
    }, {merge: true}).catch((err) => {
      console.log(err)
    })

  }
  


  return (
    <div className='Description'>

        <div className='caption'>
            In as much detail as posible describe what needs attention...
        </div>

        <div className='textField'>
            <TextField value={state.description} onChange={((e) => {setState((state) => ({...state, description: e.target.value}))})} onBlur={((e) => {handleSave()})} label='Description' placeholder='Leaking tap...' fullWidth multiline minRows={1} maxRows={10}/>
        </div>
        

    </div>
  )
}

export default Description