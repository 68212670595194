import { Add } from "@mui/icons-material";
import {
  Avatar,
  Card,
  Fab,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useAuth } from "../../../Contexts/AuthContext";
import "./PropertiesCard.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";
import { useNavigate } from "react-router-dom";

function PropertiesCard() {
  // hooks
  const auth = useAuth();
  const navigate = useNavigate();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    open: false,
    properties: [],
  });

  // UseEffect fetch call
  React.useEffect(() => {
    async function fetchCall() {
      const q = query(
        collection(db, "properties"),
        where("property_owner", "==", auth.currentUser.uid)
      );

      const querySnapshot = await getDocs(q);

      setState((state) => ({
        ...state,
        properties: querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })),
      }));
    }

    fetchCall();
  }, [auth.currentUser]);

  React.useEffect(() => {
    console.log(state.properties);
  }, [state.properties]);

  function addressFormat(property) {
    if (property.address_line_2.length > 1) {
      // return two lines
    } else {
      // return single line
      return `${property.address_line_1} ${property.address_city}, ${property.address_state}, ${property.address_postcode}, ${property.address_country}`;
    }
  }

  // Dialog Window
  return (
      <Card className="PropertiesCard">
        <div className="title">Your Properties</div>

        <div className="list-section">
          {state.properties &&
            state.properties.map((property) => (
              <ListItem dense button key={property.id} onClick={((e) => {
                navigate(`/${auth.currentUser.uid}/properties/${property.id}`)
              })}>
                <ListItemAvatar>
                  <Avatar src={property.display_image} />
                </ListItemAvatar>
                <ListItemText
                  primary={property.display_name}
                  secondary={addressFormat(property)}
                />
              </ListItem>
            ))}

          <Fab
          onClick={((e) => {navigate('/add_property')})}
            size="small"
            style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
            className="add-property-button"
          >
            <Add />
          </Fab>
        </div>
      </Card>
  );
}

export default PropertiesCard;
