import { Avatar, Card, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import './TenantMaintenaceRequests.css'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { auth, db } from '../../../../firebase'
import { useNavigate } from 'react-router-dom'



function TenantMaintenaceRequests() {



//hooks
const navigate = useNavigate();


// state variables
  const [state, setState] = React.useState({
    requests: [],
  })

// useEffect fetch call
  React.useEffect(() => {

   async function handleFetch () {
    const q =  query(collection(db, 'Property_Investor', auth.currentUser.uid, 'tenant_maintenace_requests'), where('complete', '!=', true)) ; 

    const {docs} = await getDocs(q); 

    setState((state) => ({
      ...state, 
      requests: docs.map((doc) => ({...doc.data(), id: doc.id})), 
    }))

   } 

   handleFetch()
}, [])






// handle formating the date
function handleDateFormat (propDate) {
  const serverDate = propDate && propDate;
  const newDate = new Date(serverDate.toDate())
  const date = new Date(newDate).toLocaleDateString()
  const time = new Date(newDate).toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true })
  return(`${date} @ ${time}`)
}

  return (
    <Card className='TenantMaintenaceRequests'>
      <div className='title'>Tenant Requests</div>
{state.requests.length === 0 && <div className='placeholderMessage'> 
        No New Maintenace Requests!
        </div>}
      <div className='list'> 

      


      {state.requests.map((item) => (
          <ListItem button dense key={item.id} onClick={((r) => {
            navigate(`/tenant_requests/${item.id}`)
        })}>
       <ListItemAvatar>
           <Avatar src={item.images && item.images[0] && item.images[0].url} alt='Request item' />
       </ListItemAvatar>
       <ListItemText primary={item.title} secondary={handleDateFormat(item.created_at)} />
   </ListItem>
      ))}

      </div>


    </Card>
  )
}

export default TenantMaintenaceRequests