import { FormControl, InputLabel, Select } from '@mui/material'
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import "./Styles/RequestRelatedAsset.css"

function RequestRelatedAsset() {
    // hooks
    const { request_id } = useParams();
    // state
    const [state, setState] = React.useState({
      selected_asset: "",
      space_assets: [],

    });
  
    // update call

    function handleDocUpdate(value) {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
        "updates",
        request_id
      );
      setDoc(docRef, {
        selected_asset: state.selected_asset,
      },{ merge: true});
    }


  
    // fetch auto saved data 

    React.useEffect(() => {
      async function handleDocFetch() {
        const docRef = doc(
          db,
          "Property_Investor",
          auth.currentUser.uid,
          "tenant_maintenace_requests",
          request_id,
          "updates",
          request_id
        );
  
        const { selected_asset } = await (await getDoc(docRef)).data();
  
        if (selected_asset) {
          setState((state) => ({
            ...state,
            selected_asset: selected_asset,
          }));
        } else {
          return;
        }
      }
  
      handleDocFetch();
    }, [ request_id]);



    // fetch space assets for select

    React.useEffect(() => {
    async function handleFetch () {
      const docRef = doc(db, 'Property_Investor', auth.currentUser.uid, 'tenant_maintenace_requests', request_id);
      const { property_id } = (await getDoc(docRef)).data(); 

      const propertyRef = collection(db, 'properties', property_id, 'assets'); 
      const {docs} = await getDocs(propertyRef); 

      if (docs) {
        setState((state) => ({
          ...state, 
          space_assets: docs.map((doc) => ({...doc.data(), id: doc.id}))
        }))

      } else {
        return;
      }


    } 

    handleFetch();
    }, [request_id])
    

    

  return (
      <>
    <div className='RequestRelatedAsset_content'>
    <div className='RequestRelatedAsset_title'> 
     Does this request relate to an Asset?
    </div>
    <FormControl
            variant="outlined"
            fullWidth
          >
            <InputLabel>Related Asset</InputLabel>
            <Select
              label='Related Asset'
    fullWidth
    variant='outlined'
    native
              value={state.selected_asset}
              onChange={((r) => {
                setState((state) => ({
                  ...state, 
                  selected_asset: r.target.value
                }))
              })}
              onBlur={((r) => {
                handleDocUpdate();
              })}
            >
              {/* map through all of this properties tradesmen */}
              <option value={''}>{''}</option>
              {state.space_assets &&
                state.space_assets.map((item) => (
                  <option value={item.id}>{item.display_name}</option>
                ))}
            </Select>
          </FormControl>

    </div>
    </>
  )
}

export default RequestRelatedAsset