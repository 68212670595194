import { ArrowBackIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import "./Styles/RequestPhotosPage.css"

function RequestPhotosPage() {
   // hooks
   const { request_id } = useParams();
   // state
   const [state, setState] = React.useState({
     images: [],
     index: 0
   });

 
   // fetch call

   React.useEffect(() => {
    async function handleDocFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
      );

      const { images } = await (await getDoc(docRef)).data();

      if (images) {
        setState((state) => ({
          ...state,
          images: images,
        }));
      } else {
        return;
      }
    }

    handleDocFetch();
  }, [request_id]);



   function handleIndexChange (dirc) {
    if (dirc === 'b') { // move index backwards
      if (state.index === 0) {
        return;
      } else {
           setState((state) => ({
        ...state, 
        index: state.index - 1
      }))
      }
    } else { // move index forwards 
      if (state.index === (state.images.length - 1)) {
        return;
      } else {
        setState((state) => ({
          ...state, 
          index: state.index + 1
        }))
      }

    }
   }
 
 
  return (
    <>
    <div className='RequestPhotosPage'>

    <div className='RequestPhotosPage_title'> 
      Request Images
    </div>
    <div className='RequestPhotosPage_img_section'> 
    <div className='RequestPhotosPage_button_colomn'> 
      <div>
        <IconButton disabled={state.index === 0} onClick={((e) => {handleIndexChange('b')})}>
          <ArrowBackIos />
        </IconButton>
      </div>
    </div>
    <div className='RequestPhotosPage_img_div_master'> 
      <div className='RequestPhotosPage_img_div_child'> 
<div>
  <img className='image'  alt='' src={state.images && state.images && state.images[state.index] && state.images[state.index].url}/>

</div>
      </div>
    </div>
    


    <div className='RequestPhotosPage_button_colomn'> 
      <div className='RequestPhotosPage_button_right_icon'>
        <IconButton disabled={state.index === (state.images.length - 1)} onClick={((e) => {handleIndexChange('f')})}>
          <ArrowBackIos />
        </IconButton>
      </div>
    </div>

    </div>
    </div>

    </>
  )
}

export default RequestPhotosPage