import { Add, PictureAsPdf } from "@mui/icons-material";
import { Alert, Avatar, Card, CircularProgress, Fab, ListItem, ListItemAvatar, ListItemText, Snackbar } from "@mui/material";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React from "react";
import { useParams } from "react-router-dom";
import { auth, db, storage } from "../../../firebase";
import "./JobAttachments.css";

function JobAttachments() {

  // hooks 
  const {currentJob} = useParams(); 


  // state variables
  const [state, setState] = React.useState({
    attachments: [],
    open: false, 
  })


// useEffect fetch call
  // useEffect to fetch data
  React.useEffect(() => {
    async function handleFetch() {
      const q = collection(db, "job_requests", currentJob, 'job_request_documents');
      const fetchDoc = await getDocs(q);
      const { docs } = fetchDoc; 

      if (docs) {
        setState((state) => ({
          ...state, 
          attachments: docs.map((doc) => ({...doc.data(), id: doc.id})),
        }))
      } else {
        return;
      }
    }

    handleFetch();
  }, [currentJob]);

  React.useEffect(() => {
    async function handleFetch() {
      const q = collection(db, "job_requests", currentJob, 'job_request_documents');
      const fetchDoc = await getDocs(q);
      const { docs } = fetchDoc; 

      if (docs) {
        setState((state) => ({
          ...state, 
          attachments: docs.map((doc) => ({...doc.data(), id: doc.id})),
        }))
      } else {
        return;
      }
    }

    handleFetch();
  }, [currentJob]);





  const hiddenFileInput = React.useRef(null);

  function handleInput(item) {
    // handle uploading a profile image
    if (item) {
        const e = item.target.files[0]
        console.log(e)
      // add to image folder in firebase
      const ImageRef = ref(
        storage,
        `${auth.currentUser.uid}/files/${e.name}`
      );

      const uploadTask = uploadBytesResumable(ImageRef, e);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to upload image...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        },
        async () => {
          // complete function ....
          console.log(e)

          const url = await getDownloadURL(ImageRef)
 const q = collection(db, "job_requests", currentJob, 'job_request_documents');
            addDoc(q, {url, name: e.name, type: e.type}).then((res) => {
     setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successMessage: "Successfully Uploaded File...",
              successOpen: true,
             attachments:[...state.attachments, {url, name: e.name, type: e.type}],
              loading: false,
            }));

            }).catch((err) => {
              setState((state) => ({
                ...state,
                errorOpen: true,
                errorMessage: "Oops an error occured...",
                successMessage: "",
                successOpen: false,
                loading: false,
              }));


            })

       

           




        }
      );
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Please Select a image or Pdf File...",
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }




      /* Handle Error and Success SnackBar */
      const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successOpen: false,
          successMessage: "",
          errorMessage: "",
          errorOpen: false,
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal] = React.useState("right");





  return (
    <Card className={"JobAttachments"}>
                          <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="title"> Attachments</div>

      <div className={state.attachments.length === 0 ? "list none": "list"}>


        {state.loading && <div className="loading"><CircularProgress style={{color:'rgb(18, 100, 100)'}}/></div>}

        {state.attachments.length === 0 && 'No Job Attachments.'}



        {state.attachments.map((attachment) => (

           <ListItem dense button onClick={((e) => {window.open(attachment.url)})}> 
          <ListItemAvatar>

            {attachment.type && attachment.type === 'application/pdf' ? 
            <PictureAsPdf style={{color:'rgb(18, 100, 100)', padding: '5px'}}/> : 
             <Avatar src={attachment.url}/>
            }
           
          </ListItemAvatar>
          <ListItemText primary={attachment.name}/>
        </ListItem>
        ))}


<input
                    ref={hiddenFileInput}
                    id="icon-button-file"
                    type="file"
                    multiple
                    className={"dialog_box_select_file"}
                   onChange={handleInput}
                    style={{ display: "none" }}
                  />



  

        <Fab onClick={((e) => {hiddenFileInput.current.click();})} className="fab"  size='small' style={{color:'white', backgroundColor:'rgb(18, 100, 100)' }}>
          <Add />
        
        </Fab> 

        </div>
    </Card>
  );
}

export default JobAttachments;
