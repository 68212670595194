import { doc, getDoc, setDoc} from "firebase/firestore";
import { db } from "../../../../../firebase";

export const handleAssetEventSave = async (obj, currentProperty, currentAsset) => {


    // define docRef 
    const pathRef =  doc(db, "properties", currentProperty, 'assets', currentAsset, 'new_event', 'new_asset_event');

    // handle updating / setting doc values 


    return setDoc(pathRef, obj, {merge: true}).then((res) => {
        return({
            errorOpen: false, 
            errorMessage: '', 
            successOpen: true, 
            successMessage: 'Successfully saved event add progress...', 
            loading: false, 
        })
    }).catch((err) => {
        return({
            errorOpen: true, 
            errorMessage: 'Failed to save event add progress...', 
            successOpen: false, 
            successMessage: '', 
            loading: false, 
        })

    })

    // handle error 

    // handle success 

}

export const handleDefaultValueFetch = async (arr, currentProperty, currentAsset) => {


    // define docRef 
    const pathRef =  doc(db, "properties", currentProperty, 'assets', currentAsset, 'new_event', 'new_asset_event');

    // fetch document
    return await getDoc(pathRef).then((res) => {

        console.log(res.data())
        const array = [...arr];
        const obj = {} 
        array.forEach((field, ind) => {
            obj[field] = res.data()[field]; 
        })


        return(obj)
    }).catch((err) => {
        return({
            errorOpen: true, 
            errorMessage: 'Failed to saved event data...', 
            successOpen: false, 
            successMessage: '', 
            loading: false, 
        })

    })

    // handle error 

    // handle success 

}

