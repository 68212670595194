import { Alert, Snackbar, TextField } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./EditAssetDetails.css";

function EditAssetDetails() {
  // hooks
  const { currentProperty, currentAsset } = useParams();

  // state variables
  const [state, setState] = React.useState({
    product_code: "",
    manufacturer: "",
    serial_number: "",
    asset_type: "",
    successOpen: false, 
    errorMessage: '', 
    successMessage: '', 
    errorOpen: false,
  });

  // fetch default values using UseEffect
  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "assets",
        currentAsset
      );

      const docData = getDoc(docRef);
      const { product_code, manufacturer, asset_type, serial_number } = (
        await docData
      ).data();

      setState((state) => ({
        ...state,
        loading: false,
        product_code: product_code ? product_code : "",
        manufacturer: manufacturer ? manufacturer : "",
        asset_type: asset_type ? asset_type : "",
        serial_number: serial_number ? serial_number : "",
      }));
    }

    handleFetch();
  }, [currentProperty, currentAsset]);

  function handleAssetDetailsSave() {
    const docRef = doc(
      db,
      "properties",
      currentProperty,
      "assets",
      currentAsset
    );

    updateDoc(docRef, {
      product_code: state.product_code,
      manufacturer: state.manufacturer,
      asset_type: state.asset_type,
      serial_number: state.serial_number,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          errorOpen: false,
          errorMessage: "",
          successMessage: "successfully updated asset details...",
          successOpen: true,
        }));
      })
      .catch((err) => {
        console.log(err);
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to save product details...",
          successMessage: "",
          successOpen: false,
        }));
      });
  }

  // handle snack bar jazz
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="EditAssetDetails">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="fullwidth">
        <TextField
          label="Product Code"
          fullWidth
          value={state.product_code}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              product_code: e.target.value,
            }));
          }}
          onBlur={(e) => {
            handleAssetDetailsSave();
          }}
        />
      </div>
      <div className="fullwidth">
        <TextField
          label="Manufactuer"
          fullWidth
          value={state.manufacturer}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              manufacturer: e.target.value,
            }));
          }}
          onBlur={(e) => {
            handleAssetDetailsSave();
          }}
        />
      </div>
      <div className="fullwidth">
        <TextField
          label="Serial Number"
          fullWidth
          value={state.serial_number}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              serial_number: e.target.value,
            }));
          }}
          onBlur={(e) => {
            handleAssetDetailsSave();
          }}
        />
      </div>
      <div className="fullwidth">
        <TextField
          label="Asset Type"
          fullWidth
          value={state.asset_type}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              asset_type: e.target.value,
            }));
          }}
          onBlur={(e) => {
            handleAssetDetailsSave();
          }}
        />
      </div>
    </div>
  );
}

export default EditAssetDetails;
