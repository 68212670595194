import { Alert, FormControl, InputLabel, MenuItem, Select, Snackbar } from "@mui/material";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import React from "react";
import { auth, db } from "../../../firebase";
import "./Styles/Urgency.css";

function RelatedAsset() {
  const [state, setState] = React.useState({
    errorOpen: false,
    errorMessage: "",
    spaces: [],
    assets: [],
    space: "",
    property: "",
    asset: '',
  });

  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "holding",
        "newJob"
      );

      const docData = await getDoc(docRef);

      const { space, property, asset } = docData.data();

      if (docData.exists) {
        console.log(property)
        setState((state) => ({
          ...state,
          space: space ? space : "",
          property: property ? property : "",
          asset: asset ? asset : "",
        }));
      } else {
        return;
      }
    }
    handleFetch();
  }, []);



  React.useEffect(() => {
    async function handleFetch() {
      if (state.space === "" || state.property === '') {
        console.log('notthing')
        return;
      } else {
        const q = query(collection(db, "properties", state.property, "assets"), where('space', '==', state.space));
        const qs = await getDocs(q);

        if (qs.empty) {
          return;
        } else {
          setState((state) => ({
            ...state,
            assets: qs.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
          }));
        }
      }
    }
    handleFetch();
  }, [state.space, state.property]);


  function handleSave () {
    const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 
    setDoc(docPath, {
      asset: state.asset, 
    }, {merge: true}).catch((err) => {
      console.log(err)
    })
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

   


  return (
    <div className="Type">
         <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <div className="caption">How about an asset?</div>

      <div className="textField">
        <FormControl fullWidth>
          <InputLabel>Asset</InputLabel>
          <Select
            value={state.asset}
            label="Asset"
            onBlur={((e) => { 
              handleSave(); 
            })}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                asset: e.target.value,
              }));
            }}
          >
            {state.assets.map((asset) => (
              <MenuItem key={asset.id} value={asset.id}>
                {asset.display_name}
              </MenuItem>
            ))}
             <MenuItem  value={''}>
                Nope, no related asset 
              </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export default RelatedAsset;
