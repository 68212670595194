import { Edit } from "@mui/icons-material";
import { Card, Dialog, IconButton } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./AssetDetails.css";
import EditAssetDetails from "./EditAssetDetails";

function AssetDetails() {
  // hooks
  const { currentProperty, currentAsset } = useParams();

  // state varaibles
  const [state, setState] = React.useState({
    product_code: "",
    manufactuer: "",
    serial_number: "",
    asset_type: "",
    open: false,
  });

  // useEffect fetch values
  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "assets",
        currentAsset
      );

      const docData = getDoc(docRef);
      const { product_code, manufacturer, asset_type, serial_number } = (
        await docData
      ).data();

      setState((state) => ({
        ...state,
        loading: false,
        product_code: product_code ? product_code : "",
        manufacturer: manufacturer ? manufacturer : "",
        asset_type: asset_type ? asset_type : "",
        serial_number: serial_number ? serial_number : "",
      }));
    }

    handleFetch();
  }, [currentProperty, currentAsset]);



// Dialog window 

function DialogWindow () {
    return(
        <Dialog open={state.open} fullWidth maxWidth='xs' onClose={((e) => {setState((state) => ({...state, open: false}))})}>
<EditAssetDetails />

        </Dialog>
    )

}

  return (
    <Card className="AssetDetails">
        <DialogWindow />
      <div className="title">Product Details</div>

      <div className="section">
        <div className="label">Product Code |</div> {state.product_code}
      </div>
      <div className="section">
        <div className="label">Manufactuer |</div> {state.manufacturer}
      </div>
      <div className="section">
        <div className="label">Serial Number |</div> {state.serial_number}
      </div>
      <div className="section">
        <div className="label">Asset Type |</div> {state.asset_type}
      </div>

      <IconButton onClick={((e) => {setState((state) => ({...state, open: true}))})} style={{ color: "rgb(18, 100, 100)" }} className="IconButton">
        <Edit />
      </IconButton>
    </Card>
  );
}

export default AssetDetails;
