import {
  Alert,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import "../AddAsset.css";
import { handleAssetEventSave, handleDefaultValueFetch} from "./HandleSaveFunction";

function EventDetails() {
  const { currentProperty, currentAsset } = useParams();
  const [state, setState] = React.useState({
    tradesman: "",
    type: "",
    date: null,
  });

  React.useEffect(() => {
    handleDefaultValueFetch(['tradesman', 'type', 'date'], currentProperty, currentAsset).then((res) => {
     setState(res)
    })
  }, [currentProperty, currentAsset])

  async function onBlur() {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    handleAssetEventSave(
      { tradesman: state.tradesman, type: state.type, date: state.date },
      currentProperty,
      currentAsset
    ).then((res) => {
      const newObj = Object.assign(state, res);
      setState(newObj);
    });
  }

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="EventDetails">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="fullwidth">
        <TextField
          onBlur={(e) => {
            onBlur();
          }}
          label="Related Tradesman"
          fullWidth
          value={state.tradesman}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              tradesman: e.target.value,
            }));
          }}
        />
      </div>

      <div className="sixtyForty">
        <div className="left">
          <FormControl fullWidth>
            <InputLabel>Event Type</InputLabel>
            <Select
              onBlur={(e) => {
                onBlur();
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state.type}
              label="Event Type"
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  type: e.target.value,
                }));
              }}
            >
              <MenuItem value={"Repair"}>Repair</MenuItem>
              <MenuItem value={"Service"}>Service</MenuItem>
              <MenuItem value={"Maintenace"}>Maintenace</MenuItem>
              <MenuItem value={"Installation"}>Installation</MenuItem>
              <MenuItem value={"Other..."}>Other...</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="right">
          <TextField
            onBlur={(e) => {
              onBlur();
            }}
            id="date"
            label="Date"
            type="date"
            datatype="dd/mm/yyyy"
            value={state.date}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                date: e.target.value,
              }));
            }}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EventDetails;
