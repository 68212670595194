import { Box, Slider } from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React from 'react'
import { auth, db } from '../../../firebase';
import './Styles/Urgency.css'

function Urgency() {




const marks = [
  {
    value: 0,
    label: 'Low',
  },
  {
    value: 50,
    label: 'Medium',
  },
  {
    value: 100,
    label: 'High',
  },
];


  const [state, setState] = React.useState({
    urgency: 0, 
  })

  React.useEffect(() => {
    async function handleDefaultFetch () {

      const LoopMarks = [
        {
          value: 0,
          label: 'Low',
        },
        {
          value: 50,
          label: 'Medium',
        },
        {
          value: 100,
          label: 'High',
        },
      ];


      const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

      const docRef = await getDoc(docPath); 

      if (docRef.exists) {  
        const {urgency} = docRef.data(); 
        const ind = LoopMarks.findIndex((mark) => mark.label === urgency); 
        const numberValue = LoopMarks[ind].value;
      
        setState((state) => ({
          ...state, 
          urgency: numberValue ? numberValue : 50, 
        }))

      } else {
        setDoc(docPath, {
          urgency: 'Medium', 
        }, {merge: true}).catch((err) => {
          console.log(err)
        })
      }

    }
    handleDefaultFetch()
  }, [])


  function handleSave () {
    const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 

    const ind = marks.findIndex((mark) => mark.value === state.urgency); 
    const textValue = marks[ind].label;


    setDoc(docPath, {
      urgency: textValue, 
    }, {merge: true}).catch((err) => {
      console.log(err)
    })
  }





  return (
    <div className='Type'>

        <div className='caption'>
           How urgent is this work?
        </div>


        <div className='slider'>
        <Box sx={{ width: '100%' }}>
      <Slider
      onBlur={((e) => {handleSave()})}
      sx={{color:'rgb(18, 100, 100)'}}
        aria-label="Custom marks"
        defaultValue={50}
        step={50}
        marks={marks}
        value={state.urgency}
        onChange={((e) => {setState((state) => ({...state, urgency: e.target.value}))})}
      />
    </Box>
        </div>
        

    </div>
  )
}

export default Urgency