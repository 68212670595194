import { Card } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import './InvestorMessage.css'

function InvestorMessage() {

  // hooks
  const { currentJob } = useParams();

  // state variables
  const [state, setState] = React.useState({
    message: '',
  });

  // useEffect to fetch data
  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(db, "job_requests", currentJob);
      const fetchDoc = await getDoc(docRef);
      const { job_description } = fetchDoc.data();

      if (fetchDoc.exists) {
        setState((state) => ({
          ...state, 
          message: job_description ? job_description : '',
        }))
      } else {
        return;
      }
    }

    handleFetch();
  }, [currentJob]);


  return (
    <Card className='InvestorMessage'>
      <div className='title'>Work Description</div>

      <div className='message'>
        <div className='messageTitle'>
{state.message}

          </div>  
      </div>


    </Card>
  )
}

export default InvestorMessage