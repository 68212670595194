import { FormControl, InputLabel, Select } from '@mui/material'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import "./Styles/RequestUrgencyPage.css"

function RequestUrgencyPage() {

   // hooks
   const { request_id } = useParams();

   // state
   const [state, setState] = React.useState({
     tenant_urgency: "",
     investor_urgency: "",
   });

   // update call


   function handleDocUpdate(value) {
    const docRef = doc(
      db,
      "Property_Investor",
      auth.currentUser.uid,
      "tenant_maintenace_requests",
      request_id,
      "updates",
      request_id
    );
    setDoc(docRef, {
      investor_urgency: state.investor_urgency,
    }, {merge: true});
  }


 
   // fetch call

   React.useEffect(() => {
    async function handleDocFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
        "updates",
        request_id
      );

      const { investor_urgency } = await (await getDoc(docRef)).data();

      if (investor_urgency) {
        setState((state) => ({
          ...state,
          investor_urgency: investor_urgency,
        }));
      } else {
        return;
      }
    }

    handleDocFetch();
  }, [ request_id]);


  React.useEffect(() => {
    async function handleDocFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
      );

      const { urgency } = await (await getDoc(docRef)).data();

      if (urgency) {
        setState((state) => ({
          ...state,
          tenant_urgency: urgency,
        }));
      } else {
        return;
      }
    }

    handleDocFetch();
  }, [ request_id]);
 
  const options = [
    'High', 
    'Medium', 
    'Low',
  ]

  return (<>
  <div className='RequestUrgencyPage_content'> 
    <div className='RequestUrgencyPage_title'> 
      How urgent is this request?
    </div>
    <div className='RequestUrgencyPage_msg_title'>
      Tenant Response
    </div>
    <div className='RequestUrgencyPage_msg'> 
     {state.tenant_urgency}
    </div>
    <FormControl
            variant="outlined"
            fullWidth
            
            
          >
            <InputLabel>Urgency</InputLabel>
            <Select
              label='Urgency'
    fullWidth
    variant='outlined'
    native
              value={state.investor_urgency}
              onChange={((r) => {
                setState((state) => ({
                  ...state, 
                  investor_urgency: r.target.value
                }))
              })}
              onBlur={((r) => {
                handleDocUpdate()
              })}
            >
              {/* map through all of this properties tradesmen */}
              <option aria-label="None" value="" />

              {options &&
                options.map((item) => (
                  <option value={item}>{item}</option>
                ))}
            </Select>
          </FormControl>
    
  </div>
  
  </>
  )
}

export default RequestUrgencyPage