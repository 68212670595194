import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import "./AddAssetDialogContent.css";
import { auth, storage } from "../../firebase";
import {
  AddAPhoto,
  ArrowForwardIos,
  PictureAsPdf,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

function AddAssetDialogContent({ space }) {
  const { currentProperty } = useParams();
  const [state, setState] = React.useState({
    display_name: "",
    product_code: "",
    manufactuerer: "",
    serial_number: "",
    asset_type: "",
    comments: "",
    errorOpen: false,
    errorMessage: "",
    successOpen: false,
    successMessage: "",
    other: false,
    open: true, // installation event details
    install_comments: '', 
    installer: '', 
    install_date: new Date(new Date().getTime()-(new Date().getTimezoneOffset() * 60000 )).toISOString().split("T")[0], 
    expense_amount: '', 
    expense_visibility: false,
    files: [
      
    ],
  });



  // function handle profile photo upload 
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function handleInput(item) {
    // handle uploading a profile image
    if (item) {
        const e = item.target.files[0]
        console.log(e)
      // add to image folder in firebase
      const ImageRef = ref(
        storage,
        `${auth.currentUser.uid}/files/${e.name}`
      );

      const uploadTask = uploadBytesResumable(ImageRef, e);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to upload image...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        },
        async () => {
          // complete function ....
          console.log(e)

          const url = await getDownloadURL(ImageRef)
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successMessage: "Successfully Uploaded File...",
              successOpen: true,
             files:[...state.files, {url, name: e.name, type: e.type}],
              loading: false,
            }));
        }
      );
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Please Select An Image File...",
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }

  function handleCheckBoxChange (type, ind) {
    if (type === 'warranty') {



      const files = [...state.files];
      files[ind].warranty = !files[ind].warranty
      files[ind].manual = files[ind].warranty  ? false : files[ind].manual
      setState((state) => ({
        ...state,
        files
      }))

    } else if (type === 'manual') {
    
      const files = [...state.files];
      files[ind].manual = !files[ind].manual 
      files[ind].warranty = files[ind].manual  ? false : files[ind].manual
      setState((state) => ({
        ...state,
        files
      }))


    } else if (type === 'visibility') {
      const files = [...state.files];
      files[ind].visible = !files[ind].visible 
      setState((state) => ({
        ...state,
        files
      }))

    } else {
      return; 
    }
  }



  // asset type select option

  const typeOptions = [
    { title: "Air Conditioner", key: "001" },
    { title: "Heating Equipment", key: "002" },
    { title: "Pool Equipment", key: "003" },
    { title: "Solar Equipment", key: "004" },
    { title: "Hot Water Service", key: "005" },
    { title: "Security Equipment", key: "006" },
    { title: "Oven", key: "007" },
    { title: "Cooktop", key: "008" },
    { title: "Fireplace", key: "009" },
    { title: "Lighting", key: "010" },
    { title: "Audio/Visual", key: "011" },
    { title: "Other...", key: "012" },
  ];




  async function handleAssetAdd () {
    setState((state) => ({
      ...state, 
      loading: true,
    }))

  
  
    try { 
      const reqObj = {
        space, 
        asset: {
          display_name: state.display_name ? state.display_name : '', 
          product_code:  state.product_code ? state.product_code : '', 
          manufactuerer: state.manufactuerer ? state.manufactuerer : '',
          serial_number: state.serial_number ? state.serial_number : '', 
          asset_type: state.asset_type ? state.asset_type : '', 
          comments: state.comments ? state.comments : '', 
  
        },
        addInstallEvent: state.open ? state.open : false,  
        event: {
          date: state.install_date ? state.install_date : '', 
          desc: state.install_comments ? state.install_comments : '', 
          tradesman: state.installer ? state.installer : '',
          expense: {
            amount: state.expense_amount ? state.expense_amount : '0.00', 
            visibility: state.expense_visibility ? state.expense_visibility : false,
          }, 
          files: state.files ? state.files : [], 
        },
        currentProperty, 
      }
  
      console.log(reqObj)


    const functions = getFunctions(); 
    const runFunction = await httpsCallable(functions, 'handleCreateNewAssetAndEvent')
    runFunction(reqObj).then((res) => {
      console.log(res.data)

      if (res.data.success) {
        setState((state) => ({
          ...state, 
          loading: false, 
          errorOpen: false, 
          errorMessage: '', 
          successMessage: 'Succesfully saved asset and event page will reload shortly...',
          successOpen: true,
        }))

        setTimeout(() => {
          window.location.reload()
          
        }, 1000);

      } else {
        setState((state) => ({
          ...state, 
          loading: false, 
          errorOpen: true, 
          errorMessage: res.data.errorMessage, 
          successMessage: '', 
          successOpen: false,
        }))

      }
    }).catch((err) => {
      console.log(err)
      setState((state) => ({
        ...state,
        loading: false, 
        errorOpen: true, 
        errorMessage: 'Failed to save Asset...', 
        successMessage: '', 
        successOpen: false, 
      }))
    })

    } catch (err) {
      console.log(err)
      setState((state) => ({
        ...state,
        loading: false, 
        errorOpen: true, 
        errorMessage: 'Failed to save Asset...', 
        successMessage: '', 
        successOpen: false, 
      }))
    }
    
  }
  
  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="AddAssetDialogContent" style={ state.open ? {overflow:'scroll'} : {overflow:'hidden'}}>

      {state.loading && 
      <div className="loading">

        <CircularProgress style={{color:'rgb(18, 100, 100)'}}/>
        
        </div>}
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="fullwidth">
        <TextField
          label="Display Name"
          placeholder="evaporative air conditioner..."
          fullWidth
          value={state.display_name}
          onChange={(e) => {
            setState((state) => ({ ...state, display_name: e.target.value }));
          }}
        />
      </div>

      <div className="halves">
        <div className="half">
          <TextField
            label="Product Code"
            fullWidth
            placeholder="Model Number of product..."
            value={state.product_code}
            onChange={(e) => {
              setState((state) => ({ ...state, product_code: e.target.value }));
            }}
          />
        </div>
        <div className="half">
          <TextField
            label="Manufactuer"
            fullWidth
            placeholder="who makes the product..."
            value={state.manufactuerer}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                manufactuerer: e.target.value,
              }));
            }}
          />
        </div>
      </div>
      <div className="halves">
        <div className="half">
          <TextField
            label="Serial Number"
            fullWidth
            placeholder="the unique code for your product... "
            value={state.serial_number}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                serial_number: e.target.value,
              }));
            }}
          />
        </div>
        <div className="half">
 
            <FormControl fullWidth>
              <InputLabel>Asset Type</InputLabel>
              <Select
                value={state.asset_type}
                label="Asset Type"
                placeholder="Air Conditioner..."
                onChange={(e) => {
                  console.log(e.target.value)
                 
                    setState((state) => ({
                      ...state,
                      asset_type: e.target.value,
                    }));
                  }
                }
              >
                {typeOptions.map((option) => (
                  <MenuItem value={option.title} key={option.key}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          
        </div>
      </div>

      <div className="fullwidth">
        <TextField
          label="Comments"
          multiline
          minRows={2}
          placeholder="recently installed strugles to cool the whole house..."
          fullWidth
          value={state.comments}
          onChange={(e) => {
            setState((state) => ({ ...state, comments: e.target.value }));
          }}
        />
      </div>

      <div className="addExtra">
        <div className="Extratitle">
          Do you want to add an installation event?
        </div>

        <div className="iconButton">
          <IconButton
            onClick={(e) => {
              setState((state) => ({ ...state, open: !state.open }));
            }}
          >
            {state.open ? (
              <ArrowForwardIos
                style={{
                  transform: "rotate(270deg)",
                  transition: "500ms linear",
                }}
              />
            ) : (
              <ArrowForwardIos
                style={{
                  transition: "500ms linear",
                  transform: "rotate(90deg)",
                }}
              />
            )}
          </IconButton>
        </div>
      </div>

      <div
        className="extraForm"
        style={{
          height: state.open ? "fit-content" : "0px",
          visibility: state.open ? "visible" : "hidden",
        }}
      >
        <div className="fullwidth">
          <TextField fullWidth label="Event Title" value={"Installation"}  disabled/>
        </div>

        <div className="fullwidth">
          <TextField
            fullWidth
            multiline
            minRows={2}
            maxRows={5}
            label="Comments"
            value={state.install_comments}
            onChange={((e) => {
              setState((state) => ({
                ...state, 
                install_comments: e.target.value, 
              }))
            })}
            placeholder="Unit was installed before we purchased house... unsure on exact install date however know it was prior to 2012... "
          />
        </div>
        <div className="fullwidth">
          <TextField
            label="Installer"
            placeholder="XYZ Plumbing & Gas..."
            fullWidth
            value={state.installer}
            onChange={((e) => {
              setState((state) => ({
                ...state, 
                installer: e.target.value, 
              }))
            })}
          />
        </div>

        <div className="halves">
          <div className="half">
            <TextField
              fullWidth
              value="Installation"
              disabled
              label={"Event Type"}
            />
          </div>
          <div className="half">
            <TextField
              fullWidth
              label={"Installation Date"}
              //value="2023-01-01"
              type="date"
              value={state.install_date}
              onChange={((e) => {
                setState((state) => ({
                  ...state, 
                  install_date: e.target.value, 
                }))
              })}
            />
          </div>
        </div>

        <div className="fullwidth">
          <TextField
            fullWidth
            label="Expense"
            value={state.expense_amount}
            onChange={((e) => {
              setState((state) => ({
                ...state, 
                expense_amount: e.target.value
              }))
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      setState((state) => ({
                        ...state,

                        expense_visibility: !state.expense_visibility,
                      }));
                    }}
                  >
                    {state.expense_visibility ? (
                      <Visibility style={{ color: "rgb(18, 100, 100)" }} />
                    ) : (
                      <VisibilityOff style={{ color: "rgb(18, 100, 100)" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="title">
          {" "}
          {`Photos & Attachments`}{" "}
          <div className="listTitles">
            <div>Warranty</div>
            <div>Manual</div>
            <div>Visibility</div>{" "}
          </div>
        </div>

        <div className="list">
          {state.files && state.files.map((file, ind) => (
            <ListItem
              onClick={(e) => {
                window.open(`${file.url}`);
              }}
              key={file.id}
              style={{ borderBottom: "solid 1px lightgray" }}
            >
              <ListItemAvatar>
                {file.type.includes("image") ? (
                  <Avatar src={file.url} />
                ) : (
                  <PictureAsPdf
                    style={{ color: "rgb(18, 100, 100)", margin: "10px" }}
                  />
                )}
              </ListItemAvatar>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                <div className="secondaryActions">
                  <Checkbox
                    checked={file.warranty}
                    onClick={(e) => {
                      handleCheckBoxChange ('warranty', ind)
                    }}
                    style={{ marginRight: "10px" }}
                    sx={{
                      color: "rgb(18, 100, 100)",
                      "&.Mui-checked": {
                        color: "rgb(18, 100, 100)",
                      },
                    }}
                  />
                  <Checkbox
                    checked={file.manual}
                    onClick={(e) => {
                      handleCheckBoxChange ('manual', ind)
                   
                    }}
                    style={{ marginRight: "10px" }}
                    sx={{
                      color: "rgb(18, 100, 100)",
                      "&.Mui-checked": {
                        color: "rgb(18, 100, 100)",
                      },
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                         handleCheckBoxChange ('visibility', ind)
                    }}
                  >
                    {file.visible ? (
                      <Visibility style={{ color: "rgb(18, 100, 100)" }} />
                    ) : (
                      <VisibilityOff style={{ color: "rgb(18, 100, 100)" }} />
                    )}
                  </IconButton>
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </div>

        <div className="AddPhotoIconButton">
        <input
               
               ref={hiddenFileInput}
               id="icon-button-file"
               type="file"
               onChange={handleInput}
               style={{ display: "none" }}
             />

          <IconButton onClick={((e) => {handleClick()})}  style={{color:'rgb(18, 100, 100)'}}>
            <AddAPhoto />
          </IconButton>
        </div>
      </div>

      <div className="buttonS">
        <Button
          onClick={(e) => {
            handleAssetAdd();
          }}
          style={{
            color: "white",
            fontWeight: "bold",
            backgroundColor: "rgb(18, 100, 100)",
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

export default AddAssetDialogContent;
