import { Publish } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React from "react";
import { db, storage } from "../../../firebase";
import { useAuth } from "../../Contexts/AuthContext";
import "./UserDetailsContent.css";

function UserDetailsContent() {
  // hooks
  const auth = useAuth();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    errorMessage: "",
    successMessage: "",
    errorOpen: false,
    successOpen: false,
    email: "",
    firstName: "",
    lastName: "",
    contactPhone: "",
    contactEmail: "",
    address: {
      line_one: "",
      line_two: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
    },
    profile_photo: "",
  });

  // fetch intial data
  React.useEffect(() => {
    async function handleDocumentFetch() {
      const docRef = doc(db, "Property_Investor", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setState((state) => ({
          ...state,
          firstName: docSnap.data().first_name ? docSnap.data().first_name : "",
          lastName: docSnap.data().last_name ? docSnap.data().last_name : "",
          contactEmail: docSnap.data().contact_email
            ? docSnap.data().contact_email
            : "",
          contactPhone: docSnap.data().contact_phone
            ? docSnap.data().contact_phone
            : "",
          profile_photo: docSnap.data().profile_photo ? docSnap.data().profile_photo : "",
          address: {
            line_one: docSnap.data().address_line_1
              ? docSnap.data().address_line_1
              : "",
            line_two: docSnap.data().address_line_2
              ? docSnap.data().address_line_2
              : "",
            city: docSnap.data().address_city
              ? docSnap.data().address_city
              : "",
            state: docSnap.data().address_state
              ? docSnap.data().address_state
              : "",
            postcode: docSnap.data().address_postcode
              ? docSnap.data().address_postcode
              : "",
            country: docSnap.data().address_country
              ? docSnap.data().address_country
              : "",
          },
        }));
        console.log(docSnap.data(), docSnap.id);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    handleDocumentFetch();
  }, [auth.currentUser]);

  // handle updating database data

  async function onBlurSave() {
    await setState((state) => ({
      ...state,
      loading: true,
    }));

    // execute API call
    const docRef = doc(db, "Property_Investor", auth.currentUser.uid); // define property investor doc
    await updateDoc(docRef, {
      first_name: state.firstName,
      last_name: state.lastName,
      contact_email: state.contactEmail,
      contact_phone: state.contactPhone,
      profile_photo: state.profile_photo,
      address_line_1: state.address.line_one,
      address_line_2: state.address.line_two,
      address_city: state.address.city,
      address_state: state.address.state,
      address_postcode: state.address.postcode,
      address_country: state.address.country,
    })
      .then((res) => {
        // handle success
        setState((state) => ({
          ...state,
          errorOpen: false,
          errorMessage: "",
          loading: false,
          successMessage: "Successfully saved user details...",
          successOpen: true,
        }));
      })
      .catch((err) => {
        // handle Error
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to save user details...",
          loading: false,
          successMessage: "",
          successOpen: false,
        }));
      });
  }



  // function handle profile photo upload 
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };



  function handleInput(item) {
    // handle uploading a profile image
    if (item) {
        const e = item.target.files[0]
        console.log(e)
      // add to image folder in firebase
      const ImageRef = ref(
        storage,
        `${auth.currentUser.uid}/images/${e.name}`
      );

      const uploadTask = uploadBytesResumable(ImageRef, e);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to upload image...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        },
        async () => {
          // complete function ....

          const url = await getDownloadURL(ImageRef)
          const docRef = doc(db, "Property_Investor", auth.currentUser.uid); // define property investor doc
          await updateDoc(docRef, {
         
            profile_photo: url,
            
          })
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successMessage: "Successfully Uploaded Image...",
              successOpen: true,
              profile_photo: url,
              loading: false,
            }));
        }
      );
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Please Select An Image File...",
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }





  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (<div className="userdetailscontent">
 {state.loading && (
        <div className="loading">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
      )}

    <div className="UserDetailsContent">
     
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="display-section">
        <div className="content">
          <div className="avatar">
            <Avatar sx={{ width: 75, height: 75 }} src={state.profile_photo && state.profile_photo} alt={`${state.firstName} ${state.lastName}`} />
            <div className="upload-button">
            <input
                    accept="image/*"
                    ref={hiddenFileInput}
                    id="icon-button-file"
                    type="file"
                    onChange={handleInput}
                    style={{ display: "none" }}
                  />

              <IconButton onClick={((e) => {handleClick()})}>
                <Publish style={{ color: "rgb(18, 100, 100)" }} />
              </IconButton>
            </div>
          </div>
          <div className="text-section">
            <div className="name">{`${state.firstName} ${state.lastName}`}</div>
            <div className="email">{state.contactEmail}</div>
          </div>
        </div>
      </div>
      <div className="subtitle">Contact Details</div>
      <div className="text-fields">
        <div className="halves">
          <div className="half">
            <TextField
              onBlur={(e) => {
                onBlurSave();
              }}
              label="First Name"
              placeholder="John"
              fullWidth
              value={state.firstName}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  firstName: e.target.value,
                }));
              }}
            />
          </div>
          <div className="half">
            <TextField
              onBlur={(e) => {
                onBlurSave();
              }}
              label="Last Name"
              placeholder="Smith"
              fullWidth
              value={state.lastName}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  lastName: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="fullwidth">
          <TextField
            onBlur={(e) => {
              onBlurSave();
            }}
            label="Email Address"
            placeholder="support@tenack.com"
            fullWidth
            value={state.contactEmail}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                contactEmail: e.target.value,
              }));
            }}
          />
        </div>
        <div className="fullwidth">
          <TextField
            onBlur={(e) => {
              onBlurSave();
            }}
            label="Contact Phone" // change to phone input element
            fullWidth
            value={state.contactPhone}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                contactPhone: e.target.value,
              }));
            }}
          />
        </div>
      </div>

      <div className="subtitle">Primary Mail Address</div>

      <div className="fields">
        <div className="fullwidth">
          <TextField
            onBlur={(e) => {
              onBlurSave();
            }}
            label="Address Line One"
            fullWidth
            value={state.address.line_one}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                address: { ...state.address, line_one: e.target.value },
              }));
            }}
          />
        </div>
        <div className="fullwidth">
          <TextField
            onBlur={(e) => {
              onBlurSave();
            }}
            label="Address Line Two"
            fullWidth
            value={state.address.line_two}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                address: { ...state.address, line_two: e.target.value },
              }));
            }}
          />
        </div>
        <div className="halves">
          <div className="half">
            <TextField
              onBlur={(e) => {
                onBlurSave();
              }}
              label="City"
              placeholder="Bendigo"
              fullWidth
              value={state.address.city}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  address: { ...state.address, city: e.target.value },
                }));
              }}
            />
          </div>
          <div className="half">
            <TextField
              onBlur={(e) => {
                onBlurSave();
              }}
              label="State"
              placeholder="Victoria"
              fullWidth
              value={state.address.state}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  address: { ...state.address, state: e.target.value },
                }));
              }}
            />
          </div>
        </div>
        <div className="halves">
          <div className="half">
            <TextField
              onBlur={(e) => {
                onBlurSave();
              }}
              label="Postcode"
              placeholder="3550"
              fullWidth
              value={state.address.postcode}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  address: { ...state.address, postcode: e.target.value },
                }));
              }}
            />
          </div>
          <div className="half">
            <TextField
              onBlur={(e) => {
                onBlurSave();
              }}
              label="Country"
              placeholder="Australia"
              fullWidth
              value={state.address.country}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  address: { ...state.address, country: e.target.value },
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
    </div>);
}

export default UserDetailsContent;
