import { ArrowBackIos, ArrowForwardIos, Check} from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  LinearProgress,

  Snackbar,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import React from "react";
import { useParams } from "react-router-dom";
import "./AddAsset.css";
import BasicDetails from "./Components/BasicDetails";
import EventDetails from "./Components/EventDetails";
import Expense from "./Components/Expense";
import Files from "./Components/Files";

function AddAsset() {

  const {currentProperty, currentAsset} = useParams();

  // state variables
  const [state, setState] = React.useState({
    index: 0,
    limit: 4,
  });

  function IntroSlide() {
    return (
      <div className="IntroSlide">
        <div className="title">Create New Asset Event.</div>
        <div className="description">
          Adding events to assets helps you keep track of what and when
          maintenace and repairs have occured, this saves time the next time the
          asset needs maintenace and repairs and keeps tradesman accountable for
          their work.
        </div>
      </div>
    );
  }





  function Success() {
    return (
      <div className="Success">

        <div className="circle"> 
        <Check sx={{ width: 80, height: 80 }} style={{color:'rgb(18, 100, 100)'}}/>
        </div> 
        <div className="title"> 
          Success
        </div>
        <div className="message"> 
        Please refresh page to see changes.
        </div>

      </div>
    );
  }










 async function handleSubmit () {
  setState((state) => ({
    ...state, 
    loading: true, 
  }))



  // call cloud function 

  const functions = getFunctions(); 
const cloudFunction = httpsCallable(functions, 'handleInvestorAssetEventAdd')

  cloudFunction({currentProperty, currentAsset}).then((res) => {
    console.log(res)

    const {successMessage, errorMessage} = res.data

    if (res.data.success) { // successful 
      setState((state) => ({
        ...state, 
        loading: false, 
        successMessage, 
        successOpen: true, 
        errorOpen: false,
        errorMessage: '', 
        index: state.index + 1
      }))

    } else { // error
      setState((state) => ({
        ...state, 
        loading: false, 
        successMessage: '', 
        successOpen: false, 
        errorOpen: true,
        errorMessage
      }))

    }
  }).catch((err) => {
    setState((state) => ({
      ...state, 
      loading: false, 
      errorMessage: 'Failed to create asset event...', 
      errorOpen: true, 
      successMessage: '',
      successOpen: false,
    }))
  })

  // handle success 

  // handle error


  }









  const progress = (100 / (state.limit + 1)) * state.index;


    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successOpen: false,
        successMessage: "",
        errorMessage: "",
        errorOpen: false,
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal] = React.useState("right");


  return (
    <div className="AddAsset">
       {state.loading && (
        <div className="loading">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
      )}

            <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="progress">
        <LinearProgress
          variant="determinate"
          value={progress}
          color={"inherit"}
        />
      </div>

      <div className="Content">
        {state.index === 0 && <IntroSlide />}
        {state.index === 1 && <BasicDetails />}
        {state.index === 2 && <EventDetails />}
        {state.index === 3 && <Expense />}
        {state.index === 4 && <Files />}
        {state.index === 5 && <Success />}
      </div>

      <div style={state.index === 5 ? {display:'none'} : {}} className="navigation">
        <Button
          disabled={state.index === 0}
          style={{ color: "rgb(18, 100, 100)", fontWeight: "bold" }}
          onClick={(e) => {
            if (state.index === 0) {
              return;
            } else {
              setState((state) => ({
                ...state,
                index: state.index - 1,
              }));
            }
          }}
        >
          <ArrowBackIos />
          Previous
        </Button>
        <Button
          style={{
            color: "white",
            backgroundColor: "rgb(18, 100, 100)",
            fontWeight: "bold",
          }}
          
          onClick={(e) => {
            if (state.index === state.limit) {
              // add event
              handleSubmit();
            } else {
              setState((state) => ({
                ...state,
                index: state.index + 1,
              }));
            }
          }}
        >
          {state.index === state.limit ? "Submit" : "Next"}
          <ArrowForwardIos />
        </Button>
      </div>
    </div>
  );
}

export default AddAsset;
