import { Add } from '@mui/icons-material'
import { Avatar, Card, Dialog, Fab, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import React from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../../firebase'
import TenantAddDialogContent from './TenantAddDialogContent'
import './TenantCard.css'

function TenantCard() {

  // hooks 
  const {currentProperty} = useParams();

  // state variables
  const [state, setState] = React.useState({
    open: false,
    name: '', 
    email:'',  
  })


  React.useEffect(() => {

    async function fetchTenant () {
 const docRef = doc( db, "properties", currentProperty);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().tenant) {

      setState((state) => ({
        ...state,
        name: docSnap.data().tenant_name, 
        email: docSnap.data().tenant_email, 
      }))
    } else {
      setState((state) => ({
        ...state,
        name: 'No Tenant Selected For This Property', 
      }))
    }

    }
    fetchTenant ()


   
  }, [currentProperty])
  

  function DialogWindow () {
    return (
      <Dialog open={state.open} fullWidth maxWidth='md' onClose={((e) => {setState((state) => ({...state, open: false,}))})}>
        <TenantAddDialogContent />
      </Dialog>
    )
  }
  return (
    <Card className='TenantCard'>
      <DialogWindow />
        <div className='title'>
          Tenant
        </div> 


        <div className='listSection'>

          <ListItem>
            <ListItemAvatar>
              <Avatar alt='placeholder'/>
            </ListItemAvatar>
            <ListItemText primary={state.name} secondary={state.email}/>

          </ListItem>


          <Fab onClick={((e) => {setState((state) => ({
            ...state, 
            open: true,
          }))})} className='Fab' size='small' style={{backgroundColor:'rgb(18, 100, 100)', color:'white'}}>
            <Add />
          </Fab>

        </div>
        

    </Card>
  )
}

export default TenantCard