import { TextField } from '@mui/material'
import React from 'react'
import './Styles/Description.css'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../../firebase';


function Title() {


  const [state, setState] = React.useState({
    title: '', 
  })


  React.useEffect(() => {
    async function handleFetch () {
      const docRef = doc(db, 'Property_Investor', auth.currentUser.uid, 'holding', 'newJob'); 
      const docData = await getDoc(docRef); 

      if (docData.exists) {
        const {title} = docData.data(); 
        setState((state) => ({
          ...state, 
          title: title ? title : '', 
        }))

      } else {
        return;
      }

    }

    handleFetch()
   
  }, [])


  function handleSave () {
    const docPath = doc(db, 'Property_Investor',auth.currentUser.uid, 'holding', 'newJob'); 
    setDoc(docPath, {
      title: state.title, 
    }, {merge: true}).catch((err) => {
      console.log(err)
    })

  }

  

  return (
    <div className='Description'>

        <div className='caption'>
            Finally let's give this job a title...
        </div>

        <div className='textField'>
            <TextField onBlur={((e) => {handleSave()})} value={state.title} onChange={((e) => {setState((state) => ({...state, title: e.target.value}))})} label='Title' placeholder='Leaking tap in bathroom...' fullWidth/>
        </div>
        

    </div>
  )
}

export default Title