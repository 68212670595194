import { AttachFile } from "@mui/icons-material";
import { Alert, CircularProgress, IconButton, Snackbar } from "@mui/material";
import React from "react";
import "./FloorPlanEdit.css";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from "firebase/storage";
import { db, storage } from "../../../firebase";
import { useAuth } from "../../Contexts/AuthContext";
import { useParams } from "react-router-dom";



function FloorPlanEdit() {
  // hooks
  const auth = useAuth();
  const { currentProperty } = useParams();

  // state variables
  const [state, setState] = React.useState({
    floor_plan: "",
    loading: false,
    errorOpen: false,
    successOpen: false,
    errorMessage: "",
    successMessage: "",
  });

  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(db, "properties", currentProperty);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setState((state) => ({
          ...state,
          floor_plan: docSnap.data().floor_plan
            ? docSnap.data().floor_plan
            : "",
        }));

      } else {
        console.log("no file exists");
      }
    }

    handleFetch();
  }, [auth.currentUser, currentProperty]);

  const PDFJS = require("pdfjs-dist/webpack");

  const readFileData = (file) => {

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
   return reader.readAsDataURL(file);  // convert to base64
  });
};
  const convertPdfToImages = async (file) => {
    const data = await readFileData(file);
    const pdf = await PDFJS.getDocument(data).promise;
    const canvas = document.createElement("canvas");

    let img = null; 
    
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
    img = canvas.toDataURL()
    canvas.remove();
return img; 
  }

  async function handleInput(item) {
    if (item) {
      const e = item.target.files[0];
      const ImageRef = ref(storage, `${auth.currentUser.uid}/images/${currentProperty}/floorplans/FloorPlan_${Math.random()}`);
     
      if (e.type === "application/pdf") {
        setState((state) => ({
          ...state,
          loading: true,
        }));
      console.log('you got a PDF file boss...')
       const img =  await convertPdfToImages(e)


 uploadString(ImageRef, img, 'data_url', {contentType:"image/png"}).then( async(res) => {

          await getDownloadURL(ImageRef).then( async (response) => {

            const docRef = doc(db, "properties", currentProperty); // define property investor doc

            await updateDoc(docRef, {
              floor_plan: response,
            });
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successMessage: "Successfully Uploaded floor plan...",
              successOpen: true,
              url: response,
              loading: false,
            }));

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        );


         
        }).catch((err) => {
          console.log(err)
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to upload floor plan...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        })
      

       
      } else if (e.type === "image/png" || e.type === "image/img") {

        const uploadTask = uploadBytesResumable(ImageRef, e);


        // image file format run standard image upload and set
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            setState((state) => ({
              ...state,
              loading: true,
            }));
          },
          (error) => {
            // error function ....
            console.log(error);
            setState((state) => ({
              ...state,
              errorOpen: true,
              errorMessage: "Failed to upload floor plan...",
              successMessage: "",
              successOpen: false,
              loading: false,
            }));
          },
          async () => {
            // complete function ....

            const url = await getDownloadURL(ImageRef);
            const docRef = doc(db, "properties", currentProperty); // define property investor doc

            await updateDoc(docRef, {
              floor_plan: url,
            });
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successMessage: "Successfully Uploaded floor plan...",
              successOpen: true,
              url: url,
              loading: false,
            }));

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        );
      } else {
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Oops... please select a PDF or image file...",
          successMessage: "",
          successOpen: false,
          loading: false,
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Oops... please select a file...",
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }


  // function handle profile photo upload
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="FloorPlanEdit">
      {state.loading && (
        <div className="Loading">
          <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
        </div>
      )}

      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <input
        ref={hiddenFileInput}
        id="icon-button-file"
        type="file"
        onChange={handleInput}
        style={{ display: "none" }}
      />

      <div className="document">
        <div
          className="page"
          onClick={(e) => {
            window.open(state.url);
          }}
        >
          {state.loading ? (
            ""
          ) : (
           <img className="floorPlanImg" src={state.floor_plan} alt=''/>
          )}
        </div>

        <IconButton
          onClick={(e) => {
            handleClick();
          }}
          className="iconButton"
          style={{ color: "rgb(18, 100, 100)", backgroundColor: "white" }}
        >
          <AttachFile />
        </IconButton>
      </div>
    </div>
  );
}

export default FloorPlanEdit;
