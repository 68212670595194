import { FormControl, InputLabel, Select } from '@mui/material'
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { auth, db } from '../../../firebase';

function RequestSelectTradesman() {
    // hooks
    const { request_id } = useParams();
    // state
    const [state, setState] = React.useState({
      tenant_suggestion: "",
      investor_tradesman: "",
      tradesman_option: [],
    });
 
    // update call

  function handleDocUpdate(value) {
    const docRef = doc(
      db,
      "Property_Investor",
      auth.currentUser.uid,
      "tenant_maintenace_requests",
      request_id,
      "updates",
      request_id
    );
    setDoc(docRef, {
      investor_tradesman: state.investor_tradesman,
    }, {merge: true, });
  }

  
    // fetch call
    React.useEffect(() => {
      async function handleDocFetch() {
        const docRef = doc(
          db,
          "Property_Investor",
          auth.currentUser.uid,
          "tenant_maintenace_requests",
          request_id,
          "updates",
          request_id
        );
  
        const { investor_tradesman } = await (await getDoc(docRef)).data();
  
        if (investor_tradesman) {
          setState((state) => ({
            ...state,
            investor_tradesman: investor_tradesman,
          }));
        } else {
          return;
        }
      }
  
      handleDocFetch();
    }, [ request_id]);
  

  
    React.useEffect(() => {

      async function handleFetchTradesman () {

        const docRef = doc(db, 'Property_Investor', auth.currentUser.uid, 'tenant_maintenace_requests', request_id )

        const {tradesman_type, property_id  } = (await getDoc(docRef)).data(); 

        const propertyRef = doc(db, 'properties', property_id); 
        const {tradesman} = (await getDoc(propertyRef)).data();


        console.log({tradesman, propertyRef})

        if (tradesman) {
          const arr = [...tradesman]; 

            const map = await Promise.all(arr.map( async (tradie) => {
            const tradieRef = doc(db, 'Tradesman', tradie);
            const data = (await getDoc(tradieRef)).data(); 
            console.log(data)
            return({
              ...data, id: tradie, 
            })
          }))

          console.log(map)

          setState((state) => ({
            ...state, 
            tradesman_option: map, 
            tenant_suggestion: tradesman_type ? tradesman_type : '',


          }))
        } else {
          return; 
        }

      }

      handleFetchTradesman(); 

    }, [request_id]);
  
  return (
    <>
    <div className='RequestTypePage_content'>
    <div className='RequestTypePage_title'> 
     What tradesman would you like to use?
    </div>
    <div className='RequestTypePage_message_title'> 
      Tenant Response
    </div>
    <div className='RequestTypePage_message'> 
      {`The tenant believes a ${state.tenant_suggestion} is required...`}
    </div>
    <FormControl
            variant="outlined"
            fullWidth
            
            
          >
            <InputLabel>Selected Tradesman</InputLabel>
            <Select
              label='Selected Tradesman'
    fullWidth
    native
    variant='outlined'
              value={state.investor_tradesman}
              onChange={((r) => {
                setState((state) => ({
                  ...state, 
                  investor_tradesman: r.target.value
                }))
              })}
              onBlur={((r) => {
                handleDocUpdate();
              })}
            >
              {/* map through all of this properties tradesmen */}
              <option value={''}>{''}</option>
               {state.tradesman_option &&
                state.tradesman_option.map((item) => (
                  <option value={item.id}>{item.public_name}</option>
                ))} 
            </Select>
          </FormControl>

  
    </div>
    </>

  )
}

export default RequestSelectTradesman