import { Alert, Snackbar, TextField } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import { useAuth } from "../../Contexts/AuthContext";
import "./AddressEdit.css";

function AddressEdit() {
  // hooks
  const auth = useAuth();
  const { currentProperty } = useParams();

  // state variables
  const [state, setState] = React.useState({
    display_name: '',
    errorMessage: '', 
    errorOpen: false, 
    successMessage: '', 
    successOpen: false,
    address: {
      line_one: "",
      line_two: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
    },
  });

  React.useEffect(() => {
    console.log(currentProperty);
    async function handleFetch() {
      const docRef = doc(db, "properties", currentProperty);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setState((state) => ({
          ...state,
          address: {
            line_one: docSnap.data().address_line_1
              ? docSnap.data().address_line_1
              : "",
            line_two: docSnap.data().address_line_2
              ? docSnap.data().address_line_2
              : "",
            city: docSnap.data().address_city
              ? docSnap.data().address_city
              : "",
            state: docSnap.data().address_state
              ? docSnap.data().address_state
              : "",
            postcode: docSnap.data().address_postcode
              ? docSnap.data().address_postcode
              : "",
            country: docSnap.data().address_country
              ? docSnap.data().address_country
              : "",
          },
          display_name: docSnap.data().display_name
              ? docSnap.data().display_name
              : "",
        }));

        console.log(docSnap.data());
      } else {
        console.log("no file exists");
      }
    }

    handleFetch();
  }, [auth.currentUser, currentProperty]);



  async function onBlurSave() {
    await setState((state) => ({
      ...state,
      loading: true,
    }));

    // execute API call
    const docRef = doc(db, "properties", currentProperty); // define property investor doc
    await updateDoc(docRef, {
        address_line_1: state.address.line_one,
        address_line_2: state.address.line_two,
        address_city: state.address.city,
        address_state: state.address.state,
        address_postcode: state.address.postcode,
        address_country: state.address.country,
        display_name: state.display_name
    })
      .then((res) => {
        // handle success
        setState((state) => ({
          ...state,
          errorOpen: false,
          errorMessage: "",
          loading: false,
          successMessage: "Successfully saved address details...",
          successOpen: true,
        }));
      })
      .catch((err) => {
        // handle Error
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to save address details...",
          loading: false,
          successMessage: "",
          successOpen: false,
        }));
      });
  }



  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");



  return (
    <div className="AddressEdit">

<Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>


         <div className="fullwidth">
        <TextField
          fullWidth
          label="Display Name"
          value={state.display_name}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              display_name: e.target.value
            }));
          }}
          onBlur={((e) => {
            onBlurSave()
          })}
        />
      </div>

      <div className="fullwidth">
        <TextField
          fullWidth
          label="Address Line One"
          value={state.address.line_one}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              address: { ...state.address, line_one: e.target.value },
            }));
          }}
          onBlur={((e) => {
            onBlurSave()
          })}
        />
      </div>
      <div className="fullwidth">
        <TextField
          fullWidth
          label="Address Line Two"
          value={state.address.line_two}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              address: { ...state.address, line_two: e.target.value },
            }));
          }}
          onBlur={((e) => {
            onBlurSave()
          })}
        />
      </div>

      <div className="halves">
        <div className="half">
          <TextField
            fullWidth
            label="City"
            value={state.address.city}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                address: { ...state.address, city: e.target.value },
              }));
            }}
            onBlur={((e) => {
                onBlurSave()
            })}
          />
        </div>
        <div className="half">
          <TextField
            fullWidth
            label="State"
            value={state.address.state}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                address: { ...state.address, state: e.target.value },
              }));
            }}
            onBlur={((e) => {
                onBlurSave()
            })}
          />
        </div>
      </div>

      <div className="halves">
        <div className="half">
          <TextField
            fullWidth
            label="Postcode"
            value={state.address.postcode}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                address: { ...state.address, postcode: e.target.value },
              }));
            }}
            onBlur={((e) => {
                onBlurSave()
            })}
          />
        </div>
        <div className="half">
          <TextField
            fullWidth
            label="Country"
            value={state.address.country}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                address: { ...state.address, country: e.target.value },
              }));
            }}
            onBlur={((e) => {
                onBlurSave()
            })}
          />
        </div>
      </div>
    </div>
  );
}

export default AddressEdit;
