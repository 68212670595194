import { TextField } from '@mui/material'
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React from 'react'
import { useParams } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import "./Styles/RequestTitlePage.css"

function RequestTitlePage() {
   // hooks
   const { request_id } = useParams();
   // state
   const [state, setState] = React.useState({
     tenant_title: "",
     investor_title: "",
   });

   // update call

  
  function handleDocUpdate(value) {
    const docRef = doc(
      db,
      "Property_Investor",
      auth.currentUser.uid,
      "tenant_maintenace_requests",
      request_id,
      "updates",
      request_id
    );
    updateDoc(docRef, {
      investor_title: state.investor_title,
    });
  }
 

   // fetch call

  React.useEffect(() => {
    async function handleDocFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
        "updates",
        request_id
      );

      const { investor_title } = await (await getDoc(docRef)).data();

      if (investor_title) {
        setState((state) => ({
          ...state,
          investor_title: investor_title,
        }));
      } else {
        return;
      }
    }

    handleDocFetch();
  }, [ request_id]);

  React.useEffect(() => {
    async function handleDocFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
      );

      const { title } = await (await getDoc(docRef)).data();

      if (title) {
        setState((state) => ({
          ...state,
          tenant_title: title,
        }));
      } else {
        return;
      }
    }

    handleDocFetch();
  }, [ request_id]);



   
  return (<>
  <div className='RequestTitlePage'> 
    <div className='RequestTitlePage_title'> 
      In As Few Words As Possible Describe The Required Work
    </div>
    <div className='RequestTitlePage_msg_title'> 
      Tenant Response
    </div>
    <div className='RequestTitlePage_msg'> 
     
     {state.tenant_title}
    </div>
    <TextField
    label='Request Title'
    fullWidth
    variant='outlined'
    value={state.investor_title}
    onChange={((r) => {
      setState((state) => ({
        ...state, 
        investor_title: r.target.value
      }))
    })}
    onBlur={((r) => {
      handleDocUpdate()
    })}
    />
  </div>
  
  </>
    
  )
}

export default RequestTitlePage