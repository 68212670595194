import { Clear } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
} from "@mui/material";
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React from "react";
import { useParams } from "react-router-dom";
import { auth, db, storage } from "../../../firebase";
import "./SpaceImagesEdit.css";
import { FileUploader } from "react-drag-drop-files";

function SpaceImagesEdit() {
  // hooks
  const { currentProperty, currentSpace } = useParams();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    images: [],
    dragging: false
  });

  // use Effect
  React.useEffect(() => {
    async function handleDataFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "spaces",
        currentSpace
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        setState((state) => ({
          ...state,
          images: docSnap.data().space_images
            ? docSnap.data().space_images
            : "",
        }));
      } else {
        return;
      }
    }
    handleDataFetch();
  }, [currentProperty, currentSpace]);

  function handleImageRemove(image) {
    if (window.confirm("Are you sure you want to delete this image?")) {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "spaces",
        currentSpace
      );

      updateDoc(docRef, {
        space_images: arrayRemove(image),
      })
        .then((res) => {
          setState((state) => ({
            ...state,
            loading: true,
          }));
          console.log("success");
          const imgs = [...state.images];
          const ind = imgs.findIndex((item) => item === image);
          imgs.splice(ind, 1);
          setState((state) => ({
            ...state,
            errorOpen: false,
            errorMessage: "",
            successMessage: "Successfully Removed Image...",
            images: imgs,
            successOpen: true,
            loading: false,
          }));
        })
        .catch((err) => {
          console.log(err);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to remove image...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        });

      console.log(image);
    } else {
      return;
    }
  }

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  const handleInput = (e) => {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    for (let i = 0; i < e.length; i++) {
      const newImage = e[i];
      newImage["id"] = Math.random();
      if (newImage) {
        const fileType = newImage["type"];
        const validImageTypes = [
          "image/gif",
          "image/jpeg",
          "image/png",
          "image/HEIC",
        ];
        if (validImageTypes.includes(fileType)) {
          console.log(newImage);
          // add to image folder in firebase
          const ImageRef = ref(
            storage,
            `${auth.currentUser.uid}/images/${newImage.name}`
          );

          const uploadTask = uploadBytesResumable(ImageRef, newImage);

          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              setState((state) => ({
                ...state,
                loading: true,
              }));
            },
            (error) => {
              // error function ....
              console.log(error);
              setState((state) => ({
                ...state,
                errorOpen: true,
                errorMessage: "Failed to upload image...",
                successMessage: "",
                successOpen: false,
                loading: false,
              }));
            },
            async () => {
              // complete function ....

                console.log(newImage.name);
                const url = await getDownloadURL(ImageRef);
                const docRef = doc(
                    db,
                    "properties",
                    currentProperty,
                    "spaces",
                    currentSpace
                  );
            
                  await updateDoc(docRef, {
                    space_images: arrayUnion({title: newImage.name, url}),
                  }).then((res) => {
   setState((state) => ({
                  ...state,
                  images: [...state.images, { url: url, title: newImage.name }],
                  successMessage: "Successfully uploaded images",
                  successOpen: true,
                  errorMessage: "",
                  errorOpen: false,
                  loading: false,
                }));

                  })



             

            }
          );
        } else {
          console.log("error");
          setState((state) => ({
            ...state,
            loading: false,
            successMessage: "",
            successOpen: false,
            error_open: true,
            error_message:
              "Error: Please upload an image file, .gif, .jpeg, .png",
          }));
        }
      }
    }
  };


  return (
    <div className="SpaceImagesEdit">
      {state.loading && (
        <div className="loading">
          <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
        </div>
      )}
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="title">Edit Space Images</div>    
        <div className="ImageList">
          {state.images &&
            state.images.map((image, ind) => (
                <ListItem key={ind} button style={{ marker: "none" }}>
                  <ListItemAvatar>
                    <Avatar src={image.url} />
                  </ListItemAvatar>
                  <ListItemText primary={image.title} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={(e) => {
                        handleImageRemove(image);
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
            ))}
            <FileUploader
        disabled={state.images.length > 25}
        types={["img", "png", "jpeg"]}
       
        multiple={true}
        handleChange={(e) => {
          handleInput(e);
          console.log(e);
        }}
        onDraggingStateChange={(e) => {
            setState((state) => ({
              ...state,
              dragging: !state.dragging,
            }));
          }}

      > <div  className={`dropSection ${state.dragging && 'dragging'}`}> 

      Drop Or Click To Upload Images. 


            </div></FileUploader>
           
        </div>
    </div>
  );
}

export default SpaceImagesEdit;
