import { Alert, Snackbar, TextField } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import "../AddAsset.css";
import {handleAssetEventSave, handleDefaultValueFetch} from "./HandleSaveFunction";

function BasicDetails() {
  const {currentProperty, currentAsset} = useParams();
  const [state, setState] = React.useState({
    title: "",
    desc: "",
  });


  React.useEffect(() => {
    handleDefaultValueFetch(['title', 'desc'], currentProperty, currentAsset).then((res) => {
     setState(res)
    })
  }, [currentProperty, currentAsset])
  

  async function onBlur() {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    handleAssetEventSave({ title: state.title, desc: state.desc }, currentProperty, currentAsset).then((res) => {
      const newObj = Object.assign(state, res);
      setState(newObj);
    });
  }


      /* Handle Error and Success SnackBar */
      const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successOpen: false,
          successMessage: "",
          errorMessage: "",
          errorOpen: false,
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal] = React.useState("right");


  return (
    <div className="Title">
           <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>


      <div className="fullwidth">
        <TextField
          label="Event Title"
          fullWidth
          value={state.title}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              title: e.target.value,
            }));
          }}
          onBlur={((e) => {
            onBlur();
          })}
        />
      </div>
      <div className="fullwidth">
        <TextField
          label="Event Description"
          fullWidth
          value={state.desc}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              desc: e.target.value,
            }));
          }}
          onBlur={((e) => {
            onBlur();
          })}
          multiline
          minRows={3}
          maxRows={8}
        />
      </div>
    </div>
  );
}

export default BasicDetails;
