import React from "react";
import InvestorMessage from "./Components/InvestorMessage";
import JobAttachments from "./Components/JobAttachments";
import JobExtraDetails from "./Components/JobExtraDetails";
import JobPhotos from "./Components/JobPhotos";
import "./JobPage.css";

import {
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";

function JobPage() {
  // hooks
  const { currentJob } = useParams();

  // state variables
  const [state, setState] = React.useState({
    title: "",
    asset: "",
    type: "",
  });

  // useEffect to fetch data
  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(db, "job_requests", currentJob);
      const fetchDoc = await getDoc(docRef);
      const { job_asset, job_title, job_type, job_property } = fetchDoc.data();

     

      if (fetchDoc.exists) {
 if (job_asset.length > 3) {
  const assetRef = doc(db, "properties", job_property, 'assets', job_asset);
  const assetFetchDoc = await getDoc(assetRef);
  const { display_name } = assetFetchDoc.data();

  if (assetFetchDoc.exists) {
    setState((state) => ({
      ...state,
      asset: display_name ? display_name : "",
    }));

  } else {
    return; 
  }

      } 


      setState((state) => ({
        ...state,
        title: job_title ? job_title : "",
        type: job_type ? job_type : "",
      }));
      } else {
        return;
      }

    }

    handleFetch();
  }, [currentJob]);

  return (
    <div className="JobPage">
      <div className="basicDetails">
        <div className="title">{state.title}</div>
        <div className="subtitle">{`${state.asset.length > 2 ? `${state.asset} |` : ''} ${state.type}`}</div>
      </div>

      <div className="Content">
        <JobPhotos />
        <JobExtraDetails />
        <InvestorMessage />
        <JobAttachments />
      </div>
    </div>
  );
}

export default JobPage;
