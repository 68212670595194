import { ArrowForwardIos } from "@mui/icons-material";
import { Card } from "@mui/material";
import React from "react";
import "./JobPhotos.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useParams } from "react-router-dom";

function JobPhotos() {
  // hooks
  const { currentJob } = useParams();

  // state variables
  const [state, setState] = React.useState({
    photos: [],
    index: 0, 
  });

  // useEffect to fetch data
  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(db, "job_requests", currentJob);
      const fetchDoc = await getDoc(docRef);
      const { job_images } = fetchDoc.data();

      if (fetchDoc.exists) {
        setState((state) => ({
          ...state, 
          photos: job_images,
        }))
      } else {
        return;
      }
    }

    handleFetch();
  }, [currentJob]);

  return (
    <Card className="JobPhotos">
      <div className="title"> Photos</div>
      <div className="components">
        <div className="navigation" onClick={((e) => {if (state.index === 0) {return; } else {setState((state) => ({...state, index: state.index - 1}))}})}>
          <ArrowForwardIos />
        </div>

        <div className="imageParent">
          <img alt='' className="image" src={state.photos[state.index] && state.photos[state.index].url} />
        </div>

        <div className="navigation" onClick={((e) => {if (state.index === ( state.photos.length - 1)) {return; } else {setState((state) => ({...state, index: state.index + 1}))}})}>
          <ArrowForwardIos />
        </div>
      </div>
    </Card>
  );
}

export default JobPhotos;
