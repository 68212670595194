import { Edit } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import "./AssetQuickDetails.css";
import EditBasicDetails from "./EditBasicDetails";

function AssetQuickDetails() {
  // hooks
  const { currentProperty, currentAsset } = useParams();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    open: false,
    title: "",
    installed_date: "",
    warranty_measure: "",
    warranty_amount: "",
  });

  // useEffect

  React.useEffect(() => {
    async function handleFetch() {
      const docRef = doc(
        db,
        "properties",
        currentProperty,
        "assets",
        currentAsset
      );

      const docData = getDoc(docRef);
      const { display_name, warranty_amount, warranty_measure, installed } = (
        await docData
      ).data();

      console.log(display_name);

      setState((state) => ({
        ...state,
        loading: false,
        title: display_name ? display_name : "",
        installed: installed ? installed : "0/00/0000",
        warranty_measure: warranty_measure ? warranty_measure : "Years",
        warranty_amount: warranty_amount ? warranty_amount : "0",
      }));
    }

    handleFetch();
  }, [currentProperty, currentAsset]);

  function DialogWindow() {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={state.open}
        onClose={(e) => {
          setState((state) => ({ ...state, open: false }));
        }}
      >
        <EditBasicDetails />{" "}
      </Dialog>
    );
  }

  return (
    <div className="AssetQuickDetails">
      <DialogWindow />
      <div className="left">
        <div className="title">{state.title}</div>
        <div className="warranty">{`${state.warranty_amount} ${state.warranty_measure} Warranty`}</div>
        <div className="installed">{`Installed ${state.installed}`}</div>
      </div>

      <div className="right">
        <IconButton
          onClick={(e) => {
            setState((state) => ({
              ...state,
              open: true,
            }));
          }}
          size="small"
          style={{ color: "rgb(18, 100, 100)" }}
          className="icon"
        >
          <Edit />
        </IconButton>
      </div>
    </div>
  );
}

export default AssetQuickDetails;
