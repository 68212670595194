import { SwapVert } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "../../../firebase";
import "./Styles/RequestDescriptionPage.css";

function RequestDescriptionPage() {
  // hooks
  const { request_id } = useParams();
  // state
  const [state, setState] = React.useState({
    tenant_message: "",
    investor_message: "",
  });
  // update call


  function handleDocUpdate(value) {
    const docRef = doc(
      db,
      "Property_Investor",
      auth.currentUser.uid,
      "tenant_maintenace_requests",
      request_id,
      "updates",
      request_id
    );
    setDoc(docRef, {
      investor_message: value ? value : state.investor_message,
    }, {merge: true});
  }

  // fetch call
  React.useEffect(() => {
    async function handleDocFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
        "updates",
        request_id
      );

      const { investor_message } = await (await getDoc(docRef)).data();

      if (investor_message) {
        setState((state) => ({
          ...state,
          investor_message: investor_message,
        }));
      } else {
        return;
      }
    }

    handleDocFetch();
  }, [ request_id]);


  React.useEffect(() => {
    async function handleDocFetch() {
      const docRef = doc(
        db,
        "Property_Investor",
        auth.currentUser.uid,
        "tenant_maintenace_requests",
        request_id,
      );

      const { message } = await (await getDoc(docRef)).data();

      if (message) {
        setState((state) => ({
          ...state,
          tenant_message: message,
        }));
      } else {
        return;
      }
    }

    handleDocFetch();
  }, [ request_id]);



  async function handleCopyOver() {
    if (
      window.confirm("Are you sure you would like to copy the tenant message?")
    ) {
      setState((state) => ({
        ...state,
        investor_message: state.tenant_message,
      }));
      handleDocUpdate(state.tenant_message);
    } else {
      return;
    }
  }

  return (
    <>
      <div className="RequestDescriptionPage">
        <div className="RequestDescriptionPage_title">
          What work is required to be completed?
        </div>
        <div className="RequestDescriptionPage_message_title">
          Tenant Description
        </div>
        <div className="RequestDescriptionPage_message">
          {state.tenant_message}

          <IconButton
            className="icon-button"
            onClick={(e) => {
              handleCopyOver();
            }}
          >
            <SwapVert style={{ color: "rgb(18, 100, 100)" }} size="medium" />
          </IconButton>
        </div>
        <TextField
          label="Message To Tradesman"
          fullWidth
          variant="outlined"
          multiline
          rows={8}
          value={state.investor_message}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              investor_message: e.target.value,
            }));
          }}
          onBlur={(r) => {
            handleDocUpdate();
          }}
        />
      </div>
    </>
  );
}

export default RequestDescriptionPage;
