import { Button, TextField } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import "./TradesmanSearch.css";

function TradesmanSearch() {

    // hooks
    const auth = useAuth();
    const navigate = useNavigate(); 
    const {propertyId} = useParams(); 

    // state 
    const [state, setState] = React.useState({
        query: '', 
    })


  return (
    <div className="TradesmanSearch">
      <div className="title">Find Quality Tradies.</div>
      <div className="subtitle">
        Find high quality local tradesman to maintain your assets.
      </div>

      <div className="searchbar">
        <TextField
          label="Search"
          placeholder="Search..."
          fullWidth
          size="large"
          onChange={((e) => {
            setState((state) => ({
                ...state, 
                query: e.target.value
            }))
          })}
        />
      </div>
      <div className="buttonSection">
        <Button onClick={((e) => {
            if (state.query.length > 1) {
            navigate(`/${auth.currentUser.uid}/tradesman/search/results/${state.query}${propertyId ? `/${propertyId}` : null}`)

            } else {
                return;
            }
            
            
            })} style={{fontWeight:'bold', color:'white', backgroundColor:'rgb(18, 100, 100)'}}>
            Find.
        </Button>


      </div>
    </div>
  );
}

export default TradesmanSearch;
