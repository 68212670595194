import {
    Alert,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
} from "@mui/material";

import {
    arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import "./SelectPropertiesDialog.css";

function SelectPropertiesDialog() {
  // hooks
  const { currentUser, tradesmanId } = useParams();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    properties: [],
    selected: [],
  });

  // useEffect

  React.useEffect(() => {
    async function handleDocumentFetch() {
      const q = query(
        collection(db, "properties"),
        where("property_owner", "==", currentUser)
      );

      const querySnapshot = await getDocs(q);

      setState((state) => ({
        ...state,
        properties: querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })),
        selected: querySnapshot.docs.map((doc) => {
            console.log(doc.data())


            const tradesmanArr = doc.data().tradesman ? [...doc.data().tradesman] : []; 
            const contains = tradesmanArr.includes(tradesmanId); 

            return({
          id: doc.id,
          status: contains,
        })}),
      }));

      querySnapshot.docs.forEach((doc) => {});
    }
    handleDocumentFetch();
  }, [tradesmanId, currentUser]);

  function handleCheckBoxChange(id) {
    const arr = state.selected;
    arr.forEach(function (item, i) {
      if (item.id === id) arr[i].status = !item.status;
    });
    setState((state) => ({
      ...state,
      selected: arr,
    }));
  }

  function handleChekedCheck(id) {
    const index = state.selected.findIndex((item) => item.id === id);
    return state.selected[index].status;
  }

  function handleProcessing() {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    state.selected.forEach(async (property) => {
      if (property.status === true) {
        const docRef = doc(db, "properties", property.id); // define property investor doc
        await updateDoc(docRef, {
          tradesman: arrayUnion(tradesmanId),
        }).then((err) => {
            // handle Error
            setState((state) => ({
              ...state,
              loading: false,
            }));

            setTimeout(() => {
              window.location.reload()
            }, 500);
          }).catch((err) => {
          // handle Error
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to add tradesman to property...",
            loading: false,
            successMessage: "",
            successOpen: false,
          }));
        });
      } else {
        const docRef = doc(db, "properties", property.id); // define property investor doc
        await updateDoc(docRef, {
          tradesman: arrayRemove(tradesmanId),
        }).then((err) => {
            // handle Error
            setState((state) => ({
              ...state,
              loading: false,
            }));
          }).catch((err) => {
          // handle Error
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to remove tradesman from property...",
            loading: false,
            successMessage: "",
            successOpen: false,
          }));
        });
      }
    });
  }

    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setState((state) => ({
          ...state,
          successOpen: false,
          successMessage: "",
          errorMessage: "",
          errorOpen: false,
        }));
      };
    
      const [vertical] = React.useState("bottom");
      const [horizontal] = React.useState("right");

  return (
    <div className="SelectPropertiesDialog">
         <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

        {state.loading && <div className="Loading"><CircularProgress style={{color:'rgb(18, 100, 100)'}}/></div>}
      <div className="title">Update Tradesman Access To Properties</div>
      <div className="list">
        {state.properties.map((property) => (
          <ListItem dense button key={property.id}>
            <ListItemSecondaryAction>
              <Checkbox
                checked={handleChekedCheck(property.id)}
                onChange={(e) => {
                  handleCheckBoxChange(property.id);
                }}
              />
            </ListItemSecondaryAction>

            <ListItemAvatar>
              <Avatar src={property.display_image} />
            </ListItemAvatar>
            <ListItemText
              primary={property.display_name}
              secondary={`${property.address_line_1}, ${
                property.address_line_2.length > 1
                  ? `${property.address_line_2.length},`
                  : ""
              } ${property.address_city}, ${property.address_state}`}
            />
          </ListItem>
        ))}
      </div>
      <div className="buttonBar">
        <Button
          style={{
            fontWeight: "bold",
            color: "white",
            backgroundColor: "rgb(18, 100, 100)",
          }}
          onClick={(e) => {
            handleProcessing();
          }}
        >
          Update
        </Button>
      </div>
    </div>
  );
}

export default SelectPropertiesDialog;
