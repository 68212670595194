import React from "react";
import PropertyJobs from "./Components/Jobs/PropertyJobs";
import PropertiesCard from "./Components/Properties/PropertiesCard";
import TenantMaintenaceRequests from "./Components/Requests/TenantMaintenaceRequests";
import UserDetails from "./Components/UserDetails";
import "./Dashboard.css";

function Dashboard() {
  return (
    <div className="Dashboard">
      <div className="user-tag">
        <UserDetails />

      
      </div><div className="content"> 
      <PropertiesCard />
      <PropertyJobs />
      <TenantMaintenaceRequests />

      </div>
    </div>
  );
}

export default Dashboard;
