import React from 'react'
import tenacklogo from '../../Images/tenacklogo.png'
import './Styles/RequestStartPage.css'

function RequestStartPage() {
  return (<>
  <div className='RequestStartPage'> 
    <div className='RequestStartPage_img_div'> 
    <img src={tenacklogo} alt='Tenack Company Logo'  className='RequestStartPage_img'/>
  </div>
  <div className='RequestStartPage_title'> 
  Process Maintenace Request
  </div>
  <div className='RequestStartPage_subtitle_div'> 
      <div className='RequestStartPage_subtitle'> 
  The following form allows you, a property owner, 
  to manage and process maintenace requests from tenants. 
  and foward them on to the required tradesman, if you find
  the request is unreasonable at the end of this form you can 
  cancel the request. it's best to look through the form and then
  make changes as required.


  </div>
  </div>

  </div>
  

  
  </>
  )
}

export default RequestStartPage