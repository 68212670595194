import { Add, PictureAsPdf, Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Avatar, Checkbox, CircularProgress, Fab, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Snackbar } from '@mui/material';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React from 'react'
import { useParams } from 'react-router-dom';
import { auth, storage } from '../../../../../firebase';
import { handleAssetEventSave, handleDefaultValueFetch } from './HandleSaveFunction';

function Files() {

  const { currentProperty, currentAsset } = useParams();


    const [state, setState] = React.useState({
        files: [], 
    })

    React.useEffect(() => {
      handleDefaultValueFetch(['files'], currentProperty, currentAsset).then((res) => {
       setState((state) => ({...res, files: res.files ? res.files : [] }))
      })
    }, [currentProperty, currentAsset])




    async function onBlur() {
      setState((state) => ({
        ...state,
        loading: true,
      }));
      handleAssetEventSave(
        { files: state.files},
        currentProperty,
        currentAsset
      ).then((res) => {
        const newObj = Object.assign(state, res);
        setState(newObj);
      });
    }
  
    /* Handle Error and Success SnackBar */
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        successOpen: false,
        successMessage: "",
        errorMessage: "",
        errorOpen: false,
      }));
    };
  
    const [vertical] = React.useState("bottom");
    const [horizontal] = React.useState("right");



    function handleCheckBoxChange (type, ind) {
        if (type === 'warranty') {
    
    
    
          const files = [...state.files];
          files[ind].warranty = !files[ind].warranty
          files[ind].manual = files[ind].warranty  ? false : files[ind].manual
          setState((state) => ({
            ...state,
            files
          }))
    
        } else if (type === 'manual') {
        
          const files = [...state.files];
          files[ind].manual = !files[ind].manual 
          files[ind].warranty = files[ind].manual  ? false : files[ind].manual
          setState((state) => ({
            ...state,
            files
          }))
    
    
        } else if (type === 'visibility') {
          const files = [...state.files];
          files[ind].visible = !files[ind].visible 
          setState((state) => ({
            ...state,
            files
          }))
    
        } else {
          return; 
        }
      }




  // function handle profile photo upload 
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };


  function handleInput(item) {
    // handle uploading a profile image
    if (item) {
        const e = item.target.files[0]
        console.log(e)
      // add to image folder in firebase
      const ImageRef = ref(
        storage,
        `${auth.currentUser.uid}/files/${e.name}`
      );

      const uploadTask = uploadBytesResumable(ImageRef, e);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to upload image...",
            successMessage: "",
            successOpen: false,
            loading: false,
          }));
        },
        async () => {
          // complete function ....
          console.log(e)

          const url = await getDownloadURL(ImageRef)
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successMessage: "Successfully Uploaded File...",
              successOpen: true,
             files:[...state.files, {url, name: e.name, type: e.type}],
              loading: false,
            }));
        }
      );
    } else {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Please Select An Image File...",
        successMessage: "",
        successOpen: false,
        loading: false,
      }));
    }
  }


  return (
    <div className="Documents">
      {state.loading && <div className='loading'><CircularProgress style={{color:'rgb(18, 100, 100)'}}/></div> }
        <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
    <div className="Addtitle">{`Photos & Documents`}</div>

    <div className="titles">
      <div className="title">Warranty</div>
      <div className="title">Manual</div>
      <div className="title">Visibility</div>
    </div>

    <div className="list">

      {state.files.map((file, ind) => (

         <ListItem button onClick={((e) => {window.open(`${file.url}`)})} key={ind} style={{borderBottom:'solid 1px lightgray'}}>
        <ListItemAvatar>

          {file.type.includes('image') ? <Avatar src={file.url}/> : <PictureAsPdf style={{color:'rgb(18, 100, 100)', margin:'10px'}} />}


      


        </ListItemAvatar>
        <ListItemText primary={file.name} />
        <ListItemSecondaryAction>
          <div className="secondaryActions">
            <Checkbox
            checked={file.warranty}
            onClick={((e) => {handleCheckBoxChange('warranty', ind)})}
              style={{ marginRight: "10px" }}
              sx={{
                color: "rgb(18, 100, 100)",
                "&.Mui-checked": {
                  color: "rgb(18, 100, 100)",
                },
              }}
            />
            <Checkbox
            checked={file.manual}
            onClick={((e) => {handleCheckBoxChange('manual', ind)})}
              style={{ marginRight: "10px" }}
              sx={{
                color: "rgb(18, 100, 100)",
                "&.Mui-checked": {
                  color: "rgb(18, 100, 100)",
                },
              }}
            />
            <IconButton  onClick={((e) => {handleCheckBoxChange('visibility', ind)})}>
              {file.visible ? <Visibility style={{color:'rgb(18, 100, 100)'}} /> : <VisibilityOff style={{color:'rgb(18, 100, 100)'}} />}
            </IconButton>
          </div>
        </ListItemSecondaryAction>
      </ListItem> 
      ))}


<input
               
                ref={hiddenFileInput}
                id="icon-button-file"
                type="file"
                onChange={handleInput}
                style={{ display: "none" }}
              />

    
      <Fab  onBlur={((e) => { onBlur()})} size="small" onClick={((e) => {handleClick()})} className="fab" style={{color:'white', backgroundColor:'rgb(18, 100, 100)'}}>
        <Add />
      </Fab>
    </div>
  </div>
  )
}

export default Files