import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  CircularProgress,
  LinearProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import "./AddProperty.css";
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";

function AddProperty() {
  // hooks
  const navigate = useNavigate(); 

  // state varaibles
  const [state, setState] = React.useState({
    loading: false,
    errorOpen: false,
    erorMessage: "",
    successOpen: false,
    successMessage: "",
    index: 0,
    limit: 1,
    address: {
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
    },
    display_name: "",
    access_notes: "",
  });

  function IntroSlide() {
    return (
      <div className="IntroSlide">
        <div className="title">New Tenack Property.</div>
        <div className="subtitle">{`The first step to the future of property maintenace...`}</div>
        <div className="message">
          Adding new properties to your tenack account allows you, a property
          investor to manage and maintain accurate records for all your property
          assets, and also allows Tradesman to interact with your property like
          never before.
        </div>
      </div>
    );
  }

  async function handleAddingProperty() {
    setState((state) => ({ ...state, loading: true }));
    const collectionPath = collection(db, "properties");

    try {
      addDoc(collectionPath, {
        // Property Address
        address_line_1: state.address.line_1,
        address_line_2: state.address.line_2,
        address_city: state.address.city,
        address_state: state.address.state,
        address_postcode: state.address.postcode,
        address_country: state.address.country,
        address: state.address,
        // Property Details
        property_owner: auth.currentUser.uid,
        display_image:
          "https://firebasestorage.googleapis.com/v0/b/tenack-9d967.appspot.com/o/image_placeholder.jpg?alt=media&token=0c5bdacb-3fb1-4d04-99fc-c47afc315151",
        display_name: state.display_name,
        property_notes: state.access_notes,
      })
        .then((res) => {
          setState((state) => ({
            ...state,
            errorOpen: false,
            errorMessage: "",
            successOpen: true,
            successMessage:
              "Successfully created new property... page will refresh shortly...",
            loading: false,
          }));

          setTimeout(() => {
            navigate('/home')
            
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to create new property...",
            loading: false,
            successOpen: false,
            successMessage: "",
          }));
        });
    } catch {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Failed to create new property...",
        loading: false,
        successOpen: false,
        successMessage: "",
      }));
    }
  }

  // handle progress

  const progress = (100 / state.limit) * state.index - 10;

  /* Handle Error and Success SnackBar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="AddProperty">
      {state.loading && (
        <div className="loading">
          {" "}
          <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />{" "}
        </div>
      )}

      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <Card className="card">
        <div className="progress">
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={progress}
          />
        </div>

        <div className="slide">
          {state.index === 0 && <IntroSlide />}

          {state.index === 1 && (
            <>
              <div className="InputFieldsParent">
                <div className="inputFields">
                  <div className="title">Property Details</div>
                  <div className="subtitle">
                    All of these details can be edited later
                  </div>

                  <div className="fullwidth">
                    <TextField
                      label={"Address Line One"}
                      fullWidth
                      value={state.address.line_1}
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          address: { ...state.address, line_1: e.target.value },
                        }));
                      }}
                    />
                  </div>
                  <div className="fullwidth">
                    <TextField
                      label={"Address Line Two"}
                      fullWidth
                      value={state.address.line_2}
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          address: { ...state.address, line_2: e.target.value },
                        }));
                      }}
                    />
                  </div>

                  <div className="halves">
                    <div className="half">
                      <TextField
                        label={"City"}
                        fullWidth
                        value={state.address.city}
                        onChange={(e) => {
                          setState((state) => ({
                            ...state,
                            address: { ...state.address, city: e.target.value },
                          }));
                        }}
                      />
                    </div>
                    <div className="half">
                      <TextField
                        label={"State"}
                        fullWidth
                        value={state.address.state}
                        onChange={(e) => {
                          setState((state) => ({
                            ...state,
                            address: {
                              ...state.address,
                              state: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="halves">
                    <div className="half">
                      <TextField
                        label={"Postcode"}
                        fullWidth
                        value={state.address.postcode}
                        onChange={(e) => {
                          setState((state) => ({
                            ...state,
                            address: {
                              ...state.address,
                              postcode: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="half">
                      <TextField
                        label={"Country"}
                        fullWidth
                        value={state.address.country}
                        onChange={(e) => {
                          setState((state) => ({
                            ...state,
                            address: {
                              ...state.address,
                              country: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="fullwidth">
                    <TextField
                      label={"Display Name"}
                      fullWidth
                      value={state.display_name}
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          display_name: e.target.value,
                        }));
                      }}
                      placeholder="Bendigo Rental Property - 73 Purple Road"
                    />
                  </div>
                  <div className="fullwidth">
                    <TextField
                      label={"Access Notes"}
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={8}
                      value={state.access_notes}
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          access_notes: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="buttons">
          <Button
            disabled={state.index === 0}
            onClick={(e) => {
              if (state.index === 0) {
                return;
              } else {
                setState((state) => ({ ...state, index: state.index - 1 }));
              }
            }}
            style={{ color: "rgb(18, 100, 100)", fontWeight: "bold" }}
          >
            <ArrowBackIos />
            Previous
          </Button>

          <Button
            onClick={(e) => {
              if (state.index === state.limit) {
                handleAddingProperty(); // handle submit
              } else {
                setState((state) => ({
                  ...state,
                  index: state.index + 1,
                }));
              }
            }}
            style={{
              color: "white",
              backgroundColor: "rgb(18, 100, 100)",
              fontWeight: "bold",
            }}
          >
            {state.index === state.limit ? "Create Property" : "Next"}
            <ArrowForwardIos />
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default AddProperty;
