import { Alert, Button, Card, CircularProgress, Snackbar, TextField } from "@mui/material";
import React from "react";
import "./ResetPassword.css";
import tenacklogo from "../Images/tenacklogo.png";
import { useNavigate } from "react-router-dom";
import { sendPasswordReset } from "../../firebase";

function ResetPassword() {

  // hooks 
  const navigate = useNavigate();


  // state variables
  const [state, setState] = React.useState({
    loading: false,
    errorOpen: false,
    errorMessage: "",
    successOpen: false,
    successMessage: "",
    email: "",
    password: "",
    posistion: false,
  });


  // handle making button running away
  function handleRunAway() {
    if (state.password === "" || state.email === "") {
      setState((state) => ({
        ...state,
        posistion: state.posistion === true ? false : true,
      }));
    } else {
      return;
    }
  }


  // function handle password reset 

  async function passwordReset () {
    await setState((state) => ({
      ...state, 
      loading: true,
    }))
    await sendPasswordReset(state.email).then((res) => {
      if (res.success) {
        setState((state) => ({
          ...state,
          loading: false,
          errorMessage: '', 
          errorOpen: false, 
          successMessage: res.message, 
          successOpen: true
        }))

        setTimeout(() => {
          navigate('/login')
        }, 10000);
      } else {
        setState((state) => ({
          ...state,
          loading: false,
          errorMessage: res.message, 
          errorOpen: true, 
          successMessage: '', 
          successOpen: false
        }))

      }
    })

  }



   /* Handle Error and Success SnackBar */
   const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="Login">
      <Card>
        <>
        {state.loading && <div className="loading">
          <CircularProgress style={{color:'rgb(18, 100, 100)'}}/> 

        </div>}

        <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

          <div className="logo">
            <img src={tenacklogo} alt="Tenack Logo" className="image" />
          </div>
          <div className="title">Reset Password.</div>

          <div className="fields">
            <div className="field">
              <TextField
                value={state.email}
                label="Email Address"
                placeholder="support@tenack.com"
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    email: e.target.value,
                  }));
                }}
                fullWidth
              />
            </div>
            <div className="buttons">
              <Button
                className={state.posistion ? "button-left" : "button-right"}
                onMouseEnter={(e) => {
                  handleRunAway();
                }}
                onClick={((e) => {
                  passwordReset()
                })}
                style={{
                  color: "white",
                  backgroundColor: "rgb(18, 100, 100)",
                  fontWeight: "bold",
                }}
              >
                Send
              </Button>
            </div>
            <div className="links">
              <div className="link-section">
                <div>Need An Account? </div>
                <div
                  className="link"
                  onClick={(e) => {
                    navigate("/signup");
                  }}
                >
                  Sign Up
                </div>
              </div>
              <div className="link-section">
                Forgot Your Password?
                <div
                  className="link"
                  onClick={(e) => {
                    navigate("/reset-password");
                  }}
                >
                  Reset Password
                </div>
              </div>
            </div>
          </div>
        </>
      </Card>
    </div>
  );
}

export default ResetPassword;
